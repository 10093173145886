export const tooltipText = {
  bcAvatarBackgroundColor: 'Background color of the avatar.',
  // TODO: Change bcGreeterComponent text once content is ready.
  bcGreeterComponent:
    'Customize your greeter style: Select "Modern Style" for a sleek, minimalist design, ideal for contemporary websites with a dynamic interface. Or choose "Classic Style" for a traditional and timeless look, perfect for professional or classic-themed websites, offering an elegant and familiar interaction.',
  bcHeaderFillColor: 'Background for the chat window header.',
  bcHeaderFontColor: 'Font color for the chat window header.',
  // tslint:disable-next-line:max-line-length
  bcHeaderType: `Header styles that allow for more complex headers with images, or simple text based headers`,
  bcBotResponseFillColor: 'Background color of bot messages.',
  // tslint:disable
  bcBotResponseBorderColor:
    'Border color of bot messages. If you do not want a border, set use the same value as the Bot Response Fill Color.',
  // tslint:enable
  bcBotResponseFontColor: 'Font color your bot messages will appear in.',
  bcButtonFillColor: 'Background color for buttons and simple responses.',
  bcMenuButtonColor:
    'Font color of the Menu button if it is enabled in settings.',
  bcButtonFontColor: 'Font color for buttons and simple responses.',
  fontFamily: `Font family the bot and user messages appear in. Don't see your favorite font on the list? Let us know!`,
  cardButtonTextAlignment: `Align the text (left, center, right) on card buttons.`,
  // tslint:disable-next-line:max-line-length
  bcUserResponseColor: `Font color for your user's text responses. It must be legible with the 'User fillcolor'.`,
  // tslint:disable-next-line:max-line-length
  bcChatBackground: `Color of the panels shown on each side of the chatroom when the chat window is in fullscreen mode.`,
  bcChatroomFillColor: `Background color of the chatroom.`,
  bcTypingAnimationDots: `Dot color in the typing animation.`,
  bcTypingAnimationFill:
    'This changes the background color of the typing animation.',
  bcUserBoxColor: `Background color of your user's messages.`,
  bcHoverFillColor: `Background color buttons and elements on the window. Also affects the box shadow when users tab.`,
  bcHoverFontColor: `Font color on buttons and quick replies when a user hovers.`,
  bcFocusFillColor: `Color of the focus state on buttons.`,
  wcag: `Web Content Accessibility Guidelines are additional styling features for users with disabilities.`,
  // tslint:disable-next-line:max-line-length
  shape: `Controls the shape of the greeter that appears on your website.`,
  // tslint:disable-next-line:max-line-length
  greeter: `Choose a greeter or upload your own. Your greeter displays when the chat window is minimized. For the best experience, make sure this image is square.`,
  avatar: `Your avatar speaks to your user. It will appear as a 30x30px image next to your bot's messages.`,
  typingavatar: `The avatar displayed when your bot is typing. It will appear as a 30x30px image next to your bot's typing animation.`,
  headerImage: `Choose a header image or upload your own. For the best experience, make sure this image is square.`,
  headerSubtitle: `Subtitle can be 42 characters max.`,
  bcGreeterBackground: `Color of the greeter that appears in the corner of a user's screen.`,
  bcGreeterFontColor: `Font color in your prompts.`,
  bcGreeterBorder: `Color of the border around your prompts.`,
  bcGreeterBorderHoverColor:
    'Color of the prompt border when a user mouses over the prompt.',
  bcGreeterPromptBackground: `Background color displayed in your prompts.`,
  bcGreeterImageSize: `Controls the size of the image and the padding around the image in the greeter.`,
  bcGreeterHoverFillColor:
    'Replaces the prompt background when a user hovers their mouse over the prompt.',
  bcGreeterHoverFontColor: `Replaces the prompt font color when a users' mouse hovers over the prompt.`,
  bcGreeterAnimationName: `Animation when the greeter loads in.`,
  bcGreeterAnimationSpeed: `Duration of the animation.`,
  bcGreeterAnimationTimingFunction:
    'Specifies the speed curve of the animation.',
  bcUiByBotcopy: `Select a style for the UI by Botcopy icon. Appears above the input bar.`,
  bcInputBarStyle: `Applies a style on the input bar.`,
  bcInputBarBorderColor: `Color of input bar border`,
  bcInputBarContainerFillColor: `Background color of the input bar container.`,
  bcInputBarFillColor: `Background color of the input bar.`,
  bcInputBarFontColor: `Font color of user's input text.`,
  bcInputBarLabelColor: `Font color of the input bar's label.`,
  bcInputBarPlaceholderColor: `Font color of placeholder text.`,
  bcLiveChatEntrySound: 'Sound played when a live agent enters the chat.',
  bcPromptSuggestionFillColor:
    'Background color of suggestion chips that might appear under your bot prompt.',
  bcPromptSuggestionFontColor:
    'Font color of suggestion chips that might appear under your bot prompt.',
  bcPromptSuggestionHoverFillColor:
    'Background color of prompt suggestions when a user mouses over a chip.',
  bcPromptSuggestionHoverFontColor:
    'Font color of prompt suggestions when a user mouses over a chip.',
  bcZIndex:
    'Sets the zIndex of the chat window. Defaults to 2000000000. The value must be a number without commas.',
  bcSendActiveColor: 'Color of the send button when a user is typing.',
  bcSidebarMarginTop:
    'Sets the margin-top to create space for a website header. Include units (1px, 1em, 1vh).',
  bcSidebarBorderImageLinearGradient:
    'Set a linear-gradient for your border-image. Example: linear-gradient(to bottom, red, green) 0 100%',
  displayGreeter:
    'By default the greeter and prompts are turned on. If turned off, your greeter and prompts will no longer display. *Intended for users who only want the bot to display when a button with a window trigger is clicked*.',
  // Feedback box
  feedbackBox:
    'Collect feedback from users. Click here to learn how to trigger this component.',
  privacyPolicy:
    'Set your privacy settings. Click here to learn how to trigger this component.',
  bcFeedbackAddCommentBackground: 'Background color of the add comment button.',
  bcFeedbackAddCommentFontColor: 'Font color of the add comment button.',
  bcFeedbackSubmitBackground: 'Background color of the submit button.',
  bcFeedbackSubmitFontColor: 'Font color of the submit button.',
  bcPrivacyPolicyAcceptFontColor: 'Font color of the accept button.',
  bcPrivacyPolicyAcceptFillColor: 'Fill color of the accept button.',
  bcPrivacyPolicyAcceptBorderColor: 'Border color of the accept button.',
  bcPrivacyPolicyDeclineFontColor: 'Font color of the decline button.',
  bcPrivacyPolicyDeclineFillColor: 'Fill color of the decline button.',
  bcPrivacyPolicyDeclineBorderColor: 'Border color of the decline button.',
  bcPrivacyPolicyDescriptionFontColor: 'Font color of the description.',
}
