import React from 'react'
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Card,
  Text,
  SimpleGrid,
  Flex,
  Grid,
  FormControl as ChakraFormControl,
  FormLabel,
  Select as ChakraSelect,
  Tooltip,
  HStack,
} from '@chakra-ui/react'
import { MdInfoOutline as InfoIcon } from 'react-icons/md'
import { colors } from '../../../hocs/withTheme'
import { GreeterStyle } from '../../../models/bots'
import Tile from './Tile'
import {
  promptsTileConfigs,
  chatWindowTileConfigs,
  inputBarTileConfigs,
  feedbackTileConfigs,
  privacyPolicyTileConfigs,
} from '../data/tileConfigs'
import { IOrganization } from 'src/models/organizations'

interface ColorSettingsProps {
  org: IOrganization
  styles: any
  botThemeFormElements: any
  tooltipText: any
  handleDefaultGreeterStyleChange: (
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => void
  renderInput: (cssVal: string, tileHeader: string) => React.ReactNode
  renderPicker: (cssVal: string, currentHex: string) => void
}

const ColorSettings: React.FC<ColorSettingsProps> = ({
  botThemeFormElements,
  tooltipText,
  handleDefaultGreeterStyleChange,
  renderInput,
  renderPicker,
}) => {
  return (
    <SimpleGrid columns={1} w="100%" spacingY={6} mt={6}>
      <Text textStyle="h5" fontSize="28px">
        Colors
      </Text>
      <Accordion
        as={Card}
        bg={colors.lightGreyScale100}
        allowToggle={true}
        fullWidth={true}
        border="1px solid"
        borderColor={colors.lightGreyScale800}
      >
        <AccordionItem>
          <AccordionButton borderRadius={6} border="none" p={8} h="auto">
            <Box as="span" flex="1" textAlign="left">
              <Text textStyle="h5" fontSize="21px">
                Greeter and Prompts
              </Text>
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel p={8} pt={0}>
            {/* Header Fields */}
            <SimpleGrid columns={{ sm: 1, md: 3 }} spacing={6}>
              <ChakraFormControl mb={12} mt={4}>
                <FormLabel textTransform="none">
                  <Flex justify="space-between">
                    <Text textStyle="body2" mb={6}>
                      Default Greeter Style
                    </Text>
                    <Tooltip
                      label={tooltipText.bcGreeterComponent}
                      placement={'top'}
                      padding={5}
                    >
                      <div>
                        <InfoIcon
                          color={colors.lightGreyScale1100}
                          size="20px"
                          cursor="pointer"
                        />
                      </div>
                    </Tooltip>
                  </Flex>
                </FormLabel>
                <ChakraSelect
                  onChange={(e) => {
                    handleDefaultGreeterStyleChange(e)
                  }}
                  h="56px"
                  cursor="pointer"
                  value={botThemeFormElements.defaultGreeterStyle}
                >
                  <option value={GreeterStyle.CLASSIC}>Classic</option>
                  <option value={GreeterStyle.MODERN}>Modern</option>
                </ChakraSelect>
              </ChakraFormControl>

              {/* GREETER COLOR */}
              <Tile
                botThemeFormElements={botThemeFormElements}
                renderPicker={renderPicker}
                renderInput={renderInput}
                cssVal="bcGreeterBackground"
                tileHeader="Greeter Background Color"
              />
            </SimpleGrid>

            <div className="divider" />
            <Flex direction="column">
              <Text textStyle="body2" my={5}>
                Prompts
              </Text>
              <Grid
                templateColumns="repeat(auto-fill, minmax(250px, 1fr))"
                gap={6}
              >
                {promptsTileConfigs.map(({ cssVal, tileHeader }) => (
                  <Tile
                    key={cssVal}
                    botThemeFormElements={botThemeFormElements}
                    renderPicker={renderPicker}
                    renderInput={renderInput}
                    cssVal={cssVal}
                    tileHeader={tileHeader}
                  />
                ))}
              </Grid>
            </Flex>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>

      {/* Chat Window */}
      <Accordion
        as={Card}
        bg={colors.lightGreyScale100}
        allowToggle={true}
        border="1px solid"
        borderColor={colors.lightGreyScale800}
      >
        <AccordionItem>
          <AccordionButton borderRadius={6} border="none" p={8} h="auto">
            <Box as="span" flex="1" textAlign="left">
              <Text textStyle="h5" fontSize="21px">
                Chat Window
              </Text>
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel p={8} pt={0}>
            {/* Main Design Grid Item Container */}
            {/* Header Fields */}
            <Flex direction="column">
              <Text textStyle="body2" mt={2} mb={5}>
                Header
              </Text>

              <SimpleGrid columns={{ sm: 2, md: 3 }} spacing={6}>
                {/* HEADER FILL COLOR */}
                <Tile
                  botThemeFormElements={botThemeFormElements}
                  renderPicker={renderPicker}
                  renderInput={renderInput}
                  cssVal="bcHeaderFillColor"
                  tileHeader="Fill Color"
                />

                {/* HEADER FONT COLOR */}
                <Tile
                  botThemeFormElements={botThemeFormElements}
                  renderPicker={renderPicker}
                  renderInput={renderInput}
                  cssVal="bcHeaderFontColor"
                  tileHeader="Font Color"
                />
              </SimpleGrid>
              <div className="divider" />
              {/* Body Fields */}
              <Flex direction="column">
                <Text textStyle="body2" my={5}>
                  Body
                </Text>

                <Grid
                  templateColumns="repeat(auto-fill, minmax(250px, 1fr))"
                  gap={6}
                >
                  {chatWindowTileConfigs.map(({ cssVal, tileHeader }) => (
                    <Tile
                      key={cssVal}
                      botThemeFormElements={botThemeFormElements}
                      renderPicker={renderPicker}
                      renderInput={renderInput}
                      cssVal={cssVal}
                      tileHeader={tileHeader}
                    />
                  ))}
                </Grid>
              </Flex>
            </Flex>
            <div className="divider" />
            <Flex direction="column">
              <Text textStyle="body2" my={5}>
                Input Bar
              </Text>

              <Grid
                templateColumns="repeat(auto-fill, minmax(250px, 1fr))"
                gap={6}
              >
                {inputBarTileConfigs.map(({ cssVal, tileHeader }) => {
                  // Conditionally render the Input Border Color tile based on css.bcInputBarStyle
                  if (
                    cssVal === 'bcInputBarBorderColor' &&
                    botThemeFormElements.css.bcInputBarStyle !== 'classic'
                  ) {
                    return null
                  }

                  return (
                    <Tile
                      key={cssVal}
                      botThemeFormElements={botThemeFormElements}
                      renderPicker={renderPicker}
                      renderInput={renderInput}
                      cssVal={cssVal}
                      tileHeader={tileHeader}
                    />
                  )
                })}
              </Grid>
            </Flex>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>

      {/* Components */}
      <Accordion
        as={Card}
        bg={colors.lightGreyScale100}
        allowToggle={true}
        fullWidth={true}
        border="1px solid"
        borderColor={colors.lightGreyScale800}
      >
        <AccordionItem>
          <AccordionButton borderRadius={6} border="none" p={8} h="auto">
            <Box as="span" flex="1" textAlign="left">
              <Text textStyle="h5" fontSize="21px">
                Components
              </Text>
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel p={8} pt={0}>
            {/* Main Design Grid Item Container */}
            <Flex direction="column">
              <HStack mt={2} mb={5}>
                <Text textStyle="body2" mr="5px">
                  Feedback Box
                </Text>
                <Tooltip
                  label={tooltipText.feedbackBox}
                  placement={'top'}
                  padding={5}
                >
                  <div>
                    <a
                      href="https://docs.botcopy.com/#/basics/components?id=feedback"
                      target="_blank"
                    >
                      <InfoIcon
                        color={colors.lightGreyScale1100}
                        size="20px"
                        cursor="pointer"
                      />
                    </a>
                  </div>
                </Tooltip>
              </HStack>
              <Grid
                templateColumns="repeat(auto-fill, minmax(250px, 1fr))"
                gap={6}
              >
                {feedbackTileConfigs.map(({ cssVal, tileHeader }) => (
                  <Tile
                    key={cssVal}
                    botThemeFormElements={botThemeFormElements}
                    renderPicker={renderPicker}
                    renderInput={renderInput}
                    cssVal={cssVal}
                    tileHeader={tileHeader}
                  />
                ))}
              </Grid>
              <div className="divider" />
              <Flex direction="column">
                <HStack>
                  <Text textStyle="body2" my={5}>
                    Privacy Policy
                  </Text>
                  <Tooltip
                    label={tooltipText.privacyPolicy}
                    placement={'top'}
                    padding={5}
                  >
                    <div>
                      <a
                        href="https://docs.botcopy.com/#/basics/components?id=privacy-policy"
                        target="_blank"
                      >
                        <InfoIcon
                          color={colors.lightGreyScale1100}
                          size="20px"
                        />
                      </a>
                    </div>
                  </Tooltip>
                </HStack>
                <Grid
                  templateColumns="repeat(auto-fill, minmax(250px, 1fr))"
                  gap={6}
                >
                  {privacyPolicyTileConfigs.map(({ cssVal, tileHeader }) => (
                    <Tile
                      key={cssVal}
                      botThemeFormElements={botThemeFormElements}
                      renderPicker={renderPicker}
                      renderInput={renderInput}
                      cssVal={cssVal}
                      tileHeader={tileHeader}
                    />
                  ))}
                </Grid>
              </Flex>
            </Flex>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </SimpleGrid>
  )
}

export default ColorSettings
