import { map } from 'lodash-es'
import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { Navigate } from 'react-router-dom'
import BotcopyMonospace from 'src/components/BotcopyMonospace'
import TokenField from 'src/components/TokenField'
import { Roles } from 'src/models/users'
import { v4 } from 'uuid'

import { Box, Link, Text, Tooltip } from '@chakra-ui/react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Select,
  Snackbar,
  SnackbarContent,
  StyleRulesCallback,
  Switch,
  TextField,
  withStyles,
} from '@material-ui/core'
import { Close } from '@material-ui/icons'
import AddRoundedIcon from '@material-ui/icons/AddRounded'
import CheckCircle from '@material-ui/icons/CheckCircle'
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded'
import EditRoundedIcon from '@material-ui/icons/EditRounded'

import trackUserEvent from '../../components/trackEvents'
import { colors } from '../../hocs/withTheme'
import { RootStore } from '../../models/root-store/root.store'
import { BotPlatform, IBot } from '../../models/bots'
import { EventName, Events } from '../../utils/gtm'
import Logger from '../../utils/logger'
import { SaveDetectorConfirmation, useSaveDetector } from '@botcopy/ui-shared'
import { observer } from 'mobx-react'
import { toJS } from 'mobx'
import { useStore } from 'src/providers'
import { RouteProps, withRouter } from 'src/utils/withRouter'

const { log } = Logger('Integrations')

export const FALLBACK_BOT_PROJECT_ID = 'user-name-e88b2'

const divStyle = {
  addAContextField: {
    font: 'Open Sans, sans-serif',
    color: colors.offWhite,
    maxWidth: 300,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: 'Open Sans, sans-serif',
    fontSize: '2em',
    color: colors.darkGreyBlue,
    padding: 20,
  },
  loading: {
    margin: '1em 0',
    color: colors.darkGreyBlue,
    textAlign: 'center' as 'center',
  },
  subtitle: {
    margin: '0.5em',
    marginBottom: '2em',
    fontWeight: 550,
    fontSize: '1.25em',
    color: colors.darkGreyBlue,
  },
  input: {
    font: 'Open Sans, sans-serif',
    width: '100%',
    color: colors.offWhite,
  },
  inputIntegrations: {
    font: 'Open Sans, sans-serif',
    color: colors.offWhite,
    minWidth: 400,
  },
  inputLabel: {
    padding: '10px',
    color: colors.darkGreyBlue,
    fontSize: '1rem',
    fontWeight: 600,
  },
  // Snackbar
  snackbarGrid: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  snackbar: {
    backgroundColor: colors.green,
    color: colors.offWhite,
  },
  errorSnackbar: {
    backgroundColor: colors.errorRed,
    color: colors.offWhite,
  },
  snackbarIconCircle: { fontSize: 20, marginRight: 8 },
  webhookField: {
    font: 'Open Sans, sans-serif',
    color: colors.offWhite,
    // minWidth: 440,
    maxWidth: 500,
  },
}

const styles: StyleRulesCallback = (theme) => ({
  root: {
    color: colors.offWhite,
    flexGrow: 1,
    margin: '80px auto',
    padding: '50px',
  },

  formControl: {
    minWidth: 120,
    backgroundColor: colors.offWhite,
    width: '275px',
    borderRadius: 5,
    boxShadow: '0px 5px #238AE4',
    margin: '4px 0 10px',
  },
  paper: {
    padding: theme.spacing.unit * 4.5,
    color: colors.darkGreyBlue,
    margin: 18,
    flex: 0.5,
  },
  accordion: {
    color: colors.darkGreyBlue,
    width: '100%',
    margin: '0 18px',
  },
  accordionDetails: {
    flexDirection: 'column',
    padding: '0 30px 24px',
    borderBottom: `1px solid ${colors.lightGreyBlue}`,
  },
  title: {
    color: 'white',
  },
  stat: {
    color: 'white',
  },
  input: {
    font: 'Open Sans, sans-serif',
    width: '95%',
    color: colors.darkGreyBlue,
  },
  cssFocused: {},
  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      borderColor: colors.darkGreyBlue,
    },
  },
  notchedOutline: {
    borderColor: colors.darkGreyBlue,
  },
})

const tooltipText = {
  botAccessToken:
    'Used to authenticate Botcopy when Botcopy makes POST requests to your Live Chat Endpoint Webhook URL.',
  customHeaderName:
    'An additional custom header name to be sent with the webhook request. If you enter a custom header name, you must also enter a custom header value.',
  customHeaderValue: 'An additional value sent with the custom header name.',
  liveChatEndpoint: `Toggle live chat endpoint on or off`,
  liveChatEndpointFreePlan: `Upgrade to a paid plan to enable the Live Chat Endpoint.`,
  orgApiKey:
    'Botcopy API key is unique to your organization, and used to authenticate your requests to Botcopy.',
  storeChatHistory: `Store your bot's transcripts with Botcopy. Used in conjunction with live chat endpoint. Retrievable
    from Botcopy api.`,
  storeChatHistoryNoPlan: `Store your bot's transcripts with Botcopy. Available to Standard, Pro & Enterprise plans.`,
}

interface IIntegrationsProps extends RouteProps {
  classes: any
  store?: RootStore
}

export const Integrations: React.FunctionComponent<IIntegrationsProps> = ({
  params,
  classes,
}) => {
  const store = useStore()
  const [dashbotIntegrationDialog, setDashbotIntegrationDialog] =
    useState(false)
  const [
    dashbotIntegrationAPIKeyInputValue,
    setDashbotIntegrationAPIKeyInputValue,
  ] = useState('')
  const [successSnackbar, setSuccessSnackbar] = useState(false)
  const [errorSnackbar, setErrorSnackbar] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState('')
  const [confirmClearApiKeyDialog, setConfirmClearApiKeyDialog] =
    useState(false)
  const [confirmEditBotAccessTokenDialog, setConfirmEditBotAccessTokenDialog] =
    useState(false)
  const [confirmEditBotHeaderValueDialog, setConfirmEditBotHeaderValueDialog] =
    useState(false)
  const [confirmEditBotHeaderNameDialog, setConfirmEditBotHeaderNameDialog] =
    useState(false)
  const [
    confirmGenerateOrgAccessToken,
    setConfirmGenerateOrgAccessTokenDialog,
  ] = useState(false)
  const [orgAccessTokenInputValue, setOrgAccessTokenInputValue] = useState('')
  const cancelGenerateOrgAccessToken = useCallback(() => {
    setConfirmGenerateOrgAccessTokenDialog(false)
    setOrgAccessTokenInputValue('')
  }, [])
  const [handoverContextInputValue, setHandoverContextInputValue] = useState('')
  const [botAccessTokenInputValue, setBotAccessTokenInputValue] = useState('')
  const [botHeaderNameInputValue, setBotHeaderNameInputValue] = useState('')
  const [botHeaderValueInputValue, setBotHeaderValueInputValue] = useState('')

  const currentBot = store?.bots.currentBot as IBot

  const currentBotString = JSON.stringify({
    integrations: currentBot?.integrations,
    handoverIntegration: currentBot?.handoverIntegration,
    storeHistory: currentBot?.storeHistory,
  })
  const currentOrg = store?.organizations.current
  const currentOrgString = JSON.stringify({
    accessToken: currentOrg?.accessToken,
  })

  const [formElements, setFormElements] = useState<{
    handoverIntegration: IBot['handoverIntegration']
    integrations: IBot['integrations']
    orgAccessToken: string
    storeHistory: boolean
  }>()

  useEffect(() => {
    if (currentBot && currentOrg) {
      setFormElements({
        integrations: toJS(currentBot.integrations),
        handoverIntegration: toJS(currentBot.handoverIntegration),
        orgAccessToken: toJS(currentOrg.accessToken),
        storeHistory: toJS(currentBot.storeHistory),
      })
    }
  }, [currentBot, currentBotString, currentOrg, currentOrgString])

  const openDashbotIntegrationDialog = useCallback(() => {
    setDashbotIntegrationDialog(true)
    setDashbotIntegrationAPIKeyInputValue(
      formElements?.integrations.dashbotApiKey || '',
    )
    const dataLayer = {
      event: Events.analytics.dashbot.view.type,
      eventName: Events.analytics.dashbot.view.eventName,
      eventCode: Events.analytics.dashbot.view.eventCode,
    }
    trackUserEvent('Portal View', dataLayer)
  }, [formElements?.integrations.dashbotApiKey])

  const addDashbotIntegration = useCallback(async () => {
    const dataLayer = {
      event: Events.analytics.dashbot.success.type,
      eventName: Events.analytics.dashbot.success.eventName,
      eventCode: Events.analytics.dashbot.success.eventCode,
    }
    trackUserEvent(EventName.PortalAction, dataLayer)

    setFormElements((prev: any) => {
      if (!prev) return
      return {
        ...prev,
        integrations: {
          ...prev.integrations,
          dashbotApiKey: dashbotIntegrationAPIKeyInputValue,
        },
      }
    })

    setDashbotIntegrationAPIKeyInputValue('')
    setDashbotIntegrationDialog(false)
  }, [dashbotIntegrationAPIKeyInputValue])

  const _handleSnackbar = useCallback(async (type: string, message: string) => {
    await setSnackbarMessage(message)
    switch (type) {
      case 'success':
        setSuccessSnackbar(true)
        break

      case 'error':
        setErrorSnackbar(true)
        break
    }
  }, [])

  const _closeSnackbar = useCallback(() => {
    if (successSnackbar) {
      setSuccessSnackbar(false)
    }
    if (errorSnackbar) {
      setErrorSnackbar(false)
    }
  }, [errorSnackbar, successSnackbar])

  const _closeDialog = useCallback(() => {
    setDashbotIntegrationDialog(false)
  }, [])

  const addHandoverContext = useCallback(async () => {
    try {
      setFormElements((prev: any) => {
        if (!prev) return
        prev.handoverIntegration.triggerContexts.push(handoverContextInputValue)
        return { ...prev }
      })
      setHandoverContextInputValue('')
    } catch (error) {
      log({ error })
      _handleSnackbar('error', 'Please try again.')
    }
  }, [_handleSnackbar, handoverContextInputValue])

  const removeHandoverContext = useCallback(async (index: number) => {
    setFormElements((prev: any) => {
      if (!prev) return
      prev.handoverIntegration.triggerContexts.splice(index, 1)
      return { ...prev }
    })
  }, [])

  const _toggleLiveChatEndpoint = useCallback(() => {
    setFormElements((prev: any) => {
      if (!prev) return
      return {
        ...prev,
        handoverIntegration: {
          ...prev.handoverIntegration,
          active: !prev.handoverIntegration.active,
        },
      }
    })
  }, [])
  const handleHandoverIntegrationWebhookChange = useCallback((e: any) => {
    setFormElements((prev: any) => {
      if (!prev) return
      return {
        ...prev,
        handoverIntegration: {
          ...prev.handoverIntegration,
          webhook: e.target.value,
        },
      }
    })
  }, [])

  const updateIntegrationsValue = useCallback(
    (key: string) => (e: any) => {
      setFormElements((prev: any) => {
        if (!prev) return
        return {
          ...prev,
          integrations: {
            ...prev.integrations,
            [key]: e.target.value,
          },
        }
      })
    },
    [],
  )

  const handleJanisApiKeyChange = useMemo(
    () => updateIntegrationsValue('janisApiKey'),
    [updateIntegrationsValue],
  )
  const handleJanisClientKeyChange = useMemo(
    () => updateIntegrationsValue('janisClientKey'),
    [updateIntegrationsValue],
  )
  const handleTtsApiKeyChange = useMemo(
    () => updateIntegrationsValue('ttsApiKey'),
    [updateIntegrationsValue],
  )
  const handleTtsGenderChange = useMemo(
    () => updateIntegrationsValue('ttsGender'),
    [updateIntegrationsValue],
  )

  const _toggleStoreHistory = useCallback(() => {
    setFormElements((prev: any) => {
      if (!prev) return
      return {
        ...prev,
        storeHistory: !prev.storeHistory,
      }
    })
  }, [])

  const hasUnsavedChanges = useSaveDetector(
    {
      integrations: currentBot?.integrations,
      handoverIntegration: currentBot?.handoverIntegration,
      orgAccessToken: currentOrg?.accessToken,
      storeHistory: currentBot?.storeHistory,
    },
    formElements,
  )

  const handleSave = useCallback(async () => {
    if (!currentBot || !formElements) return

    try {
      if (
        JSON.stringify(currentBot.handoverIntegration) !==
        JSON.stringify(formElements.handoverIntegration)
      ) {
        await currentBot.patchHandoverIntegration(
          formElements.handoverIntegration,
        )
      }
      if (
        JSON.stringify(currentBot.integrations) !==
        JSON.stringify(formElements?.integrations)
      ) {
        await currentBot.patchIntegrations(formElements.integrations)
      }

      if (
        currentOrg &&
        currentOrg.accessToken !== formElements.orgAccessToken
      ) {
        await currentOrg.patchOrganizationAccessToken(
          formElements.orgAccessToken,
        )
      }

      if (currentBot.storeHistory !== formElements.storeHistory) {
        await currentBot.patchStoreHistory(formElements.storeHistory)
      }

      _handleSnackbar('success', 'Your changes were saved successfully')
    } catch (error: any) {
      _handleSnackbar('error', error?.response?.data?.message)
    }
  }, [_handleSnackbar, currentBot, currentOrg, formElements])

  if (store?.bots.currentBotId !== params.botId) {
    // if current bot isn't in sync with pathname, sync
    store?.bots.setCurrentBotId(params.botId)
    return null
  }

  const org = store?.organizations.current
  const me = store?.users.me

  if (currentBot && org && me && formElements) {
    return (
      <div className="connect-page-outer-grid">
        {org.notifications.overageFreeGrace >= 5 &&
        org.balancesEngagementsTotal < org.plan.conversationLimit ? (
          <div className="onhold-banner">
            <Grid direction="column">
              <h1 className="onhold-banner-title">
                Your free trial with Botcopy is up!
              </h1>
              <h3 className="onhold-banner-subtitle">
                Upgrade now to one of our cost saving plans.
              </h3>
            </Grid>
            <a
              className="onhold-plan-button"
              href={`${process.env.PUBLIC_URL}/account?showPlans=true`}
            >
              Upgrade Plan
            </a>
          </div>
        ) : null}
        <Grid
          container={true}
          direction="column"
          justify="center"
          alignItems="center"
          style={{
            padding: '20px',
            marginLeft: 'auto',
            marginRight: 'auto',
            maxWidth: '1105px',
            minHeight: '200',
          }}
        >
          <Grid container={true} className="container-grid-integrations">
            <Text textStyle="h5" fontSize="28px" m="18px">
              Integrations
            </Text>
            <ExpansionPanel
              defaultExpanded={false}
              className={classes.accordion}
            >
              <ExpansionPanelSummary>
                <div className="connect-accordion-title">
                  Dashbot Analytics{' '}
                </div>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails className={classes.accordionDetails}>
                <Grid item={true} xs={12} direction="column">
                  {!formElements.integrations.dashbotApiKey ? null : (
                    <div className="dashbot-analytics-data-grid">
                      <Grid
                        container={true}
                        alignItems="center"
                        className="dashbot-analytics-data-item-grid"
                      >
                        <span className="dashbot-analytics-data-title">
                          Platform:{' '}
                        </span>
                        <BotcopyMonospace
                          text={formElements.integrations.dashbotPlatform}
                        />
                      </Grid>
                      <Grid
                        container={true}
                        alignItems="center"
                        className="dashbot-analytics-data-item-grid"
                      >
                        <span className="dashbot-analytics-data-title">
                          API Key:{' '}
                        </span>
                        <BotcopyMonospace
                          text={formElements.integrations.dashbotApiKey}
                        />
                      </Grid>
                    </div>
                  )}

                  <button
                    className="connect-edit-button"
                    onClick={openDashbotIntegrationDialog}
                    disabled={me.roles.includes(Roles.MARKETER)}
                  >
                    {!formElements.integrations.dashbotApiKey ? (
                      <Grid
                        container={true}
                        alignItems="center"
                        justify="center"
                      >
                        API Key{' '}
                        <AddRoundedIcon className="add-integration-icon" />
                      </Grid>
                    ) : (
                      <Grid
                        container={true}
                        alignItems="center"
                        justify="center"
                      >
                        Edit{' '}
                        <EditRoundedIcon
                          fontSize="small"
                          className="add-integration-icon"
                        />
                      </Grid>
                    )}
                  </button>
                </Grid>
                <Grid
                  container={true}
                  alignItems="flex-end"
                  justify="space-between"
                >
                  <div className="dashbot-helper-text">
                    Dashbot now lists Botcopy as a platform.
                    <br /> All new API keys created will have a Botcopy platform
                    attached to them.{' '}
                  </div>
                  <div className="connect-docs-link">
                    <a
                      href="https://docs.botcopy.com/#/basics/connect?id=dashbot-analytics-integration"
                      target="_blank"
                    >
                      How do I set this up?
                    </a>
                  </div>
                </Grid>
              </ExpansionPanelDetails>
            </ExpansionPanel>

            {/*  Botcopy Live Chat Endpoints */}
            <ExpansionPanel
              defaultExpanded={false}
              className={classes.accordion}
            >
              <ExpansionPanelSummary>
                <Grid
                  container={true}
                  direction="row"
                  justify="space-between"
                  alignItems="center"
                  className="connect-accordion-title"
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ marginRight: '20px' }}>
                      Live Chat Endpoint
                    </div>
                  </div>
                  <div
                    style={
                      formElements.handoverIntegration.active
                        ? { color: colors.green }
                        : {}
                    }
                  >
                    {formElements.handoverIntegration.active ? 'ON' : 'OFF'}
                  </div>
                </Grid>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails className={classes.accordionDetails}>
                <Tooltip
                  label={
                    org.plan.planType === 'free' ? (
                      tooltipText.liveChatEndpointFreePlan
                    ) : !formElements.handoverIntegration.accessToken ||
                      !formElements.handoverIntegration.webhook ? (
                      <div>
                        <Text>
                          You must create the following to enable the Live Chat
                          Endpoint:
                        </Text>
                        <Text>
                          Webhook URL:{' '}
                          {formElements.handoverIntegration.webhook
                            ? '✔️'
                            : '❌'}
                        </Text>
                        <Text>
                          Access Token:{' '}
                          {formElements.handoverIntegration.accessToken
                            ? '✔️'
                            : '❌'}
                        </Text>
                      </div>
                    ) : (
                      tooltipText.liveChatEndpoint
                    )
                  }
                  placement={'top'}
                  padding={5}
                >
                  <Box maxW={60}>
                    <FormControlLabel
                      disabled={
                        !formElements.handoverIntegration.accessToken ||
                        !formElements.handoverIntegration.webhook ||
                        org.plan.planType === 'free'
                      }
                      control={
                        <Switch
                          checked={formElements.handoverIntegration.active}
                          onClick={_toggleLiveChatEndpoint}
                        />
                      }
                      label={'Turn On'}
                    />
                  </Box>
                </Tooltip>
                <Grid container={true} direction="column" alignContent="center">
                  {/* Fields  */}

                  <div className="connect-fields-grid-item">
                    <div className="connect-fields-grid">
                      <div className="connect-fields-grid-title">Webhook</div>
                      <div>
                        Your web service will receive a POST request from
                        Botcopy when a defined{' '}
                        {currentBot.platform === BotPlatform.DIALOGFLOW_ES
                          ? 'context'
                          : 'session parameter'}{' '}
                        is seen in the Dialogflow response.
                        <br />
                        <Link
                          href="https://docs.botcopy.com/#/livechat/handover"
                          target="_blank"
                          p={0}
                        >
                          Webhook requirements.
                        </Link>
                      </div>
                      <TextField
                        label="URL"
                        className={classes.textField}
                        contentEditable={false}
                        value={formElements.handoverIntegration.webhook}
                        placeholder={
                          'https://my-company.com/custom-livechat-webhook'
                        }
                        margin="normal"
                        variant="outlined"
                        style={divStyle.webhookField}
                        InputProps={{
                          classes: {
                            root: classes.cssOutlinedInput,
                            focused: classes.cssFocused,
                            notchedOutline: classes.notchedOutline,
                            input: classes.input,
                          },
                        }}
                        InputLabelProps={{
                          style: { color: colors.darkGreyBlue },
                          shrink: true,
                        }}
                        FormHelperTextProps={{
                          style: { color: colors.grey },
                        }}
                        onChange={handleHandoverIntegrationWebhookChange}
                      />
                    </div>

                    <div className="connect-fields-grid">
                      <span className="connect-fields-grid-title">
                        {currentBot.platform === BotPlatform.DIALOGFLOW_ES
                          ? 'Contexts'
                          : 'Session Parameters'}
                      </span>
                      <div
                        style={{
                          display: 'flex',
                        }}
                      >
                        {map(
                          formElements.handoverIntegration.triggerContexts,
                          (context: string, index: number) => {
                            return (
                              <div
                                key={index}
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  height: '30px',
                                  justifyContent: 'flex-start',
                                }}
                              >
                                <BotcopyMonospace text={context} />
                                <Close
                                  style={{
                                    cursor: 'pointer',
                                    marginRight: '15px',
                                  }}
                                  fontSize="small"
                                  onClick={() => removeHandoverContext(index)}
                                />
                              </div>
                            )
                          },
                        )}
                      </div>
                      <TextField
                        label={
                          currentBot.platform === BotPlatform.DIALOGFLOW_ES
                            ? 'Add a Context'
                            : 'Add a Session Parameter'
                        }
                        className={classes.textField}
                        contentEditable={false}
                        margin="normal"
                        variant="outlined"
                        style={divStyle.addAContextField}
                        InputProps={{
                          classes: {
                            root: classes.cssOutlinedInput,
                            focused: classes.cssFocused,
                            notchedOutline: classes.notchedOutline,
                            input: classes.input,
                          },
                          endAdornment: (
                            <button
                              className={
                                handoverContextInputValue
                                  ? 'add-context-button'
                                  : 'add-context-button-disabled'
                              }
                              disabled={!handoverContextInputValue}
                              onClick={addHandoverContext}
                            >
                              +
                            </button>
                          ),
                        }}
                        InputLabelProps={{
                          style: { color: colors.darkGreyBlue },
                          shrink: true,
                        }}
                        FormHelperTextProps={{
                          style: { color: colors.grey },
                        }}
                        onChange={(e) =>
                          setHandoverContextInputValue(e.target.value)
                        }
                        value={handoverContextInputValue}
                      />
                    </div>
                    {/* Tokens  */}
                    <Grid
                      container={true}
                      direction="column"
                      className="connect-fields-grid"
                    >
                      {/* Org API Key */}
                      {me.roles.includes(Roles.ADMIN) ||
                      me.roles.includes(Roles.DEV) ? (
                        !formElements.orgAccessToken ? (
                          <>
                            <span className="connect-fields-grid-title">
                              API Key & Authorization
                            </span>
                            <button
                              className="request-access-button"
                              style={{ width: '400px' }}
                              onClick={() => {
                                setOrgAccessTokenInputValue(v4())
                                setConfirmGenerateOrgAccessTokenDialog(true)
                              }}
                            >
                              Generate Botcopy API Key
                            </button>
                          </>
                        ) : (
                          <>
                            <span className="connect-fields-grid-title">
                              API Key & Authorization
                            </span>
                            <Grid
                              container={true}
                              direction="row"
                              alignItems="center"
                            >
                              <TokenField
                                label="API Key"
                                token={formElements.orgAccessToken.slice(-5)}
                                tooltipText={tooltipText.orgApiKey}
                              />
                              <button
                                className="edit-button"
                                onClick={() => {
                                  setConfirmClearApiKeyDialog(true)
                                }}
                              >
                                <DeleteRoundedIcon
                                  className="connect-secondary-button"
                                  fontSize="small"
                                />
                              </button>
                            </Grid>
                          </>
                        )
                      ) : null}
                      {/* Bot Access Token  */}
                      {me.roles.includes(Roles.ADMIN) ||
                      me.roles.includes(Roles.DEV) ? (
                        <Grid
                          container={true}
                          direction="row"
                          alignItems="center"
                        >
                          <TokenField
                            label="Access Token"
                            token={formElements.handoverIntegration.accessToken}
                            tooltipText={tooltipText.botAccessToken}
                          />
                          <button
                            className="edit-button"
                            onClick={() => {
                              setConfirmEditBotAccessTokenDialog(true)
                              setBotAccessTokenInputValue(
                                formElements.handoverIntegration.accessToken ||
                                  '',
                              )
                            }}
                          >
                            <EditRoundedIcon
                              fontSize="small"
                              className="connect-secondary-button"
                            />
                          </button>
                        </Grid>
                      ) : null}
                      {/* Custom Header Name  */}
                      {me.roles.includes(Roles.ADMIN) ||
                      me.roles.includes(Roles.DEV) ? (
                        <Grid
                          container={true}
                          direction="row"
                          alignItems="center"
                        >
                          <TokenField
                            label="Custom Header Name"
                            token={formElements.handoverIntegration?.headerName}
                            tooltipText={tooltipText.customHeaderName}
                          />
                          <button
                            className="edit-button"
                            onClick={() => {
                              setConfirmEditBotHeaderNameDialog(true)
                              setBotHeaderNameInputValue(
                                formElements.handoverIntegration.headerName ||
                                  '',
                              )
                            }}
                          >
                            <EditRoundedIcon
                              fontSize="small"
                              className="connect-secondary-button"
                            />
                          </button>
                        </Grid>
                      ) : null}
                      {/* Custom Header Value  */}
                      {me.roles.includes(Roles.ADMIN) ||
                      me.roles.includes(Roles.DEV) ? (
                        <Grid
                          container={true}
                          direction="row"
                          alignItems="center"
                        >
                          <TokenField
                            label="Custom Header Value"
                            token={
                              formElements.handoverIntegration?.headerValue
                            }
                            tooltipText={tooltipText.customHeaderValue}
                          />
                          <button
                            className="edit-button"
                            onClick={() => {
                              setConfirmEditBotHeaderValueDialog(true)
                              setBotHeaderValueInputValue(
                                formElements.handoverIntegration.headerValue ||
                                  '',
                              )
                            }}
                          >
                            <EditRoundedIcon
                              fontSize="small"
                              className="connect-secondary-button"
                            />
                          </button>
                        </Grid>
                      ) : null}
                    </Grid>
                  </div>

                  <div className="connect-divider" />

                  {/* Settings  */}
                  <Grid
                    container={true}
                    direction="column"
                    className="live-chat-settings-grid"
                  >
                    <div className="connect-accordion-subtitle">Settings</div>

                    <div>
                      <Tooltip
                        label={
                          org.plan.planType === 'free'
                            ? tooltipText.storeChatHistoryNoPlan
                            : tooltipText.storeChatHistory
                        }
                        placement={'top'}
                        padding={5}
                      >
                        <div>
                          <FormControlLabel
                            disabled={
                              !formElements.handoverIntegration.accessToken ||
                              org.plan.planType === 'free'
                            }
                            control={
                              <Switch
                                checked={formElements.storeHistory}
                                onClick={_toggleStoreHistory}
                              />
                            }
                            label={'Store Chat History'}
                          />
                          {formElements.storeHistory && (
                            <p>
                              To retrieve your chat transcripts, please follow{' '}
                              <a
                                href="https://docs.botcopy.com/#/livechat/handover?id=message-history"
                                target="_blank"
                              >
                                these docs.
                              </a>
                            </p>
                          )}
                        </div>
                      </Tooltip>
                    </div>
                  </Grid>
                </Grid>
                {/* </Grid> */}
              </ExpansionPanelDetails>
            </ExpansionPanel>
            {/* Janis Integration */}
            <ExpansionPanel className={classes.accordion}>
              <ExpansionPanelSummary>
                <div className="connect-accordion-title">Janis Livechat</div>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails className={classes.accordionDetails}>
                <Grid container={true} direction="column" justify="flex-end">
                  <Grid
                    item={true}
                    xs={12}
                    style={{
                      maxWidth: '400px',
                    }}
                  >
                    <TextField
                      label="Janis API Key"
                      className={classes.textField}
                      contentEditable={false}
                      value={formElements.integrations.janisApiKey}
                      margin="normal"
                      variant="outlined"
                      fullWidth={true}
                      style={divStyle.inputIntegrations}
                      InputProps={{
                        classes: {
                          root: classes.cssOutlinedInput,
                          focused: classes.cssFocused,
                          notchedOutline: classes.notchedOutline,
                          input: classes.input,
                        },
                      }}
                      InputLabelProps={{
                        style: { color: colors.darkGreyBlue },
                        shrink: true,
                      }}
                      FormHelperTextProps={{
                        style: { color: colors.grey },
                      }}
                      onChange={handleJanisApiKeyChange}
                    />
                    <TextField
                      label="Janis Client Key"
                      className={classes.textField}
                      contentEditable={false}
                      value={formElements.integrations.janisClientKey}
                      margin="normal"
                      variant="outlined"
                      fullWidth={true}
                      style={divStyle.inputIntegrations}
                      InputProps={{
                        classes: {
                          root: classes.cssOutlinedInput,
                          focused: classes.cssFocused,
                          notchedOutline: classes.notchedOutline,
                          input: classes.input,
                        },
                      }}
                      InputLabelProps={{
                        style: { color: colors.darkGreyBlue },
                        shrink: true,
                      }}
                      FormHelperTextProps={{
                        style: { color: colors.grey },
                      }}
                      onChange={handleJanisClientKeyChange}
                    />
                  </Grid>
                  <div className="connect-docs-link">
                    <a
                      href="https://docs.botcopy.com/#/basics/connect?id=janis-livechat-integration"
                      target="_blank"
                    >
                      Where do I find these?
                    </a>
                  </div>
                </Grid>
              </ExpansionPanelDetails>
            </ExpansionPanel>

            <ExpansionPanel className={classes.accordion}>
              <ExpansionPanelSummary>
                <div className="connect-accordion-title">
                  API Key and Voice Selection
                </div>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails className={classes.accordionDetails}>
                <Grid
                  container={true}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Grid
                    item={true}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      maxWidth: '400px',
                    }}
                  >
                    <TextField
                      label="Google TTS API Key"
                      className={classes.textField}
                      value={formElements.integrations.ttsApiKey}
                      contentEditable={false}
                      variant="outlined"
                      disabled={me.roles.includes(Roles.MARKETER)}
                      margin="normal"
                      style={divStyle.inputIntegrations}
                      InputProps={{
                        classes: {
                          root: classes.cssOutlinedInput,
                          focused: classes.cssFocused,
                          notchedOutline: classes.notchedOutline,
                          input: classes.input,
                        },
                      }}
                      InputLabelProps={{
                        style: { color: colors.darkGreyBlue },
                        shrink: true,
                      }}
                      FormHelperTextProps={{
                        style: { color: colors.grey },
                      }}
                      onChange={handleTtsApiKeyChange}
                    />
                    <FormControl
                      style={{
                        width: 100,
                        color: colors.darkGreyBlue,
                        marginTop: '8px',
                      }}
                    >
                      Voice Gender
                      <Select
                        value={formElements.integrations.ttsGender}
                        disabled={!me.roles.includes(Roles.ADMIN)}
                        onChange={handleTtsGenderChange}
                        SelectDisplayProps={{
                          style: {
                            fontSize: '14px',
                            color: colors.grey,
                          },
                        }}
                      >
                        <MenuItem value={undefined} />
                        <MenuItem value={'MALE'}>Male</MenuItem>
                        <MenuItem value={'FEMALE'}>Female</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  {/* // SELECT MENU FOR VOICES */}
                </Grid>
                <div className="connect-docs-link">
                  <a
                    href="https://docs.botcopy.com/#/basics/connect?id=google-text-to-speech-tts"
                    target="_blank"
                  >
                    How do I set up Text to Speech?
                  </a>
                </div>
              </ExpansionPanelDetails>
              {/* </Tab> */}
            </ExpansionPanel>
          </Grid>
        </Grid>
        {/* DIALOGS AND SNACKBARS */}

        {/* Edit Bot Access Token Dialog */}
        <Dialog
          open={confirmEditBotAccessTokenDialog}
          onClose={() => setConfirmEditBotAccessTokenDialog(false)}
          aria-labelledby="alert-dialog-title"
          maxWidth="sm"
        >
          <DialogTitle id="alert-dialog-title">
            Edit your Bot's Access Token
          </DialogTitle>
          <DialogContent>
            <span>
              {formElements.handoverIntegration.accessToken
                ? 'Warning: This is a potentially destructive action.'
                : 'Enter a unique access token so Botcopy can communicate with your web service.'}
            </span>
            <TextField
              label="AccessToken"
              className={classes.textField}
              contentEditable={false}
              value={botAccessTokenInputValue}
              placeholder={
                'An secure token that Botcopy includes when invoking your public webhook.'
              }
              margin="normal"
              variant="outlined"
              fullWidth={true}
              style={divStyle.inputIntegrations}
              InputProps={{
                classes: {
                  root: classes.cssOutlinedInput,
                  focused: classes.cssFocused,
                  notchedOutline: classes.notchedOutline,
                  input: classes.input,
                },
              }}
              InputLabelProps={{
                style: { color: colors.darkGreyBlue },
                shrink: true,
              }}
              FormHelperTextProps={{
                style: { color: colors.grey },
              }}
              onChange={(e) => setBotAccessTokenInputValue(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setConfirmEditBotAccessTokenDialog(false)}>
              Cancel
            </Button>
            <Button
              style={{
                backgroundColor: colors.darkGreyBlue,
                color: colors.offWhite,
              }}
              onClick={() => {
                setFormElements((prev: any) => {
                  if (!prev) return
                  return {
                    ...prev,
                    handoverIntegration: {
                      ...prev.handoverIntegration,
                      accessToken: botAccessTokenInputValue,
                    },
                  }
                })
                setConfirmEditBotAccessTokenDialog(false)
              }}
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>

        {/* Edit Bot Header Name Dialog */}
        <Dialog
          open={confirmEditBotHeaderNameDialog}
          onClose={() => setConfirmEditBotHeaderNameDialog(false)}
          aria-labelledby="alert-dialog-title"
          maxWidth="sm"
        >
          <DialogTitle id="alert-dialog-title">
            Edit your Bot's Header Name
          </DialogTitle>
          <DialogContent>
            <span>
              {formElements.handoverIntegration.headerName
                ? 'Warning: This is a potentially destructive action.'
                : '(Optional) Enter a unique header name that is passed in the header.'}
            </span>
            <TextField
              label="Bot Header Name"
              className={classes.textField}
              contentEditable={false}
              value={botHeaderNameInputValue}
              placeholder={
                'A custom header that Botcopy includes when invoking your public webhook.'
              }
              margin="normal"
              variant="outlined"
              fullWidth={true}
              style={divStyle.inputIntegrations}
              InputProps={{
                classes: {
                  root: classes.cssOutlinedInput,
                  focused: classes.cssFocused,
                  notchedOutline: classes.notchedOutline,
                  input: classes.input,
                },
              }}
              InputLabelProps={{
                style: { color: colors.darkGreyBlue },
                shrink: true,
              }}
              FormHelperTextProps={{
                style: { color: colors.grey },
              }}
              onChange={(e) => setBotHeaderNameInputValue(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setConfirmEditBotHeaderNameDialog(false)}>
              Cancel
            </Button>
            <Button
              style={{
                backgroundColor: colors.darkGreyBlue,
                color: colors.offWhite,
              }}
              onClick={() => {
                setFormElements((prev: any) => {
                  if (!prev) return
                  return {
                    ...prev,
                    handoverIntegration: {
                      ...prev.handoverIntegration,
                      headerName: botHeaderNameInputValue,
                    },
                  }
                })
                setConfirmEditBotHeaderNameDialog(false)
              }}
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>

        {/* Edit Bot Header Value Dialog */}
        <Dialog
          open={confirmEditBotHeaderValueDialog}
          onClose={() => setConfirmEditBotHeaderValueDialog(false)}
          aria-labelledby="alert-dialog-title"
          maxWidth="sm"
        >
          <DialogTitle id="alert-dialog-title">
            Edit your Bot's Header Value
          </DialogTitle>
          <DialogContent>
            <span>
              {formElements.handoverIntegration?.headerValue
                ? 'Warning: This is a potentially destructive action.'
                : 'Add a unique header value as an extra layer of security when Botcopy communicates with your web service.'}
            </span>
            <TextField
              label="Header Value"
              className={classes.textField}
              contentEditable={false}
              value={botHeaderValueInputValue}
              placeholder={
                'The value of your custom header that Botcopy includes when invoking your public webhook.'
              }
              fullWidth={true}
              margin="normal"
              variant="outlined"
              style={divStyle.inputIntegrations}
              InputProps={{
                classes: {
                  root: classes.cssOutlinedInput,
                  focused: classes.cssFocused,
                  notchedOutline: classes.notchedOutline,
                  input: classes.input,
                },
              }}
              InputLabelProps={{
                style: { color: colors.darkGreyBlue },
                shrink: true,
              }}
              FormHelperTextProps={{
                style: { color: colors.grey },
              }}
              onChange={(event) =>
                setBotHeaderValueInputValue(event.target.value)
              }
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setConfirmEditBotHeaderValueDialog(false)}>
              Cancel
            </Button>
            <Button
              style={{
                backgroundColor: colors.darkGreyBlue,
                color: colors.offWhite,
              }}
              onClick={() => {
                setFormElements((prev: any) => {
                  if (!prev) return
                  return {
                    ...prev,
                    handoverIntegration: {
                      ...prev.handoverIntegration,
                      headerValue: botHeaderValueInputValue,
                    },
                  }
                })
                setConfirmEditBotHeaderValueDialog(false)
              }}
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>

        {/* Clear API Key Dialog */}
        <Dialog
          open={confirmClearApiKeyDialog}
          onClose={() => setConfirmClearApiKeyDialog(false)}
          aria-labelledby="alert-dialog-title"
        >
          <DialogTitle id="alert-dialog-title">
            Confirm API Key Deletion
          </DialogTitle>
          <DialogContent>
            Warning: Deleting your organization's API key cannot be reversed.
            This will affect all bots using this API key.
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setConfirmClearApiKeyDialog(false)}>
              Cancel
            </Button>
            <Button
              style={{
                backgroundColor: colors.darkGreyBlue,
                color: colors.offWhite,
              }}
              onClick={() => {
                setFormElements((prev: any) => {
                  if (!prev) return
                  return {
                    ...prev,
                    orgAccessToken: '',
                  }
                })
                setConfirmClearApiKeyDialog(false)
              }}
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
        {/* Confirm Generate API Key Dialog */}
        <Dialog
          open={confirmGenerateOrgAccessToken}
          onClose={cancelGenerateOrgAccessToken}
          aria-labelledby="alert-dialog-title"
        >
          <DialogTitle id="alert-dialog-title">
            Confirm API Key Creation
          </DialogTitle>
          <DialogContent>
            Here's your private API key:
            <br />
            <br />
            <BotcopyMonospace text={orgAccessTokenInputValue} />
            <br />
            <br />
            You won't be able to view this API key after it has been generated.
            If you lose it, you'll need to generate a new one.
          </DialogContent>
          <DialogActions>
            <Button onClick={cancelGenerateOrgAccessToken}>Cancel</Button>
            <Button
              style={{
                backgroundColor: colors.darkGreyBlue,
                color: colors.offWhite,
              }}
              onClick={() => {
                setFormElements((prev: any) => {
                  if (!prev) return
                  return {
                    ...prev,
                    orgAccessToken: orgAccessTokenInputValue,
                  }
                })
                setConfirmGenerateOrgAccessTokenDialog(false)
              }}
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>

        {/* Dashbot Integration Dialog */}
        <Dialog open={dashbotIntegrationDialog} onClose={_closeDialog}>
          <DialogTitle>
            {!currentBot.integrations.dashbotApiKey
              ? 'Add Dashbot API Key'
              : 'Edit Dashbot API Key'}
          </DialogTitle>
          <DialogContent>
            <div>
              <span className="dashbot-analytics-data-title">Platform: </span>
              <BotcopyMonospace text="botcopy" />
            </div>
            <TextField
              label="Dashbot API Key"
              className={classes.textField}
              variant="outlined"
              margin="normal"
              style={divStyle.inputIntegrations}
              value={dashbotIntegrationAPIKeyInputValue}
              InputProps={{
                classes: {
                  root: classes.cssOutlinedInput,
                  focused: classes.cssFocused,
                  notchedOutline: classes.notchedOutline,
                  input: classes.input,
                },
              }}
              InputLabelProps={{
                style: { color: colors.darkGreyBlue },
                shrink: true,
              }}
              FormHelperTextProps={{
                style: { color: colors.grey },
              }}
              onChange={(e) => {
                setDashbotIntegrationAPIKeyInputValue(e.target.value)
              }}
            />
          </DialogContent>
          <DialogActions disableActionSpacing={true}>
            <Button onClick={_closeDialog}>Close</Button>
            <Button
              onClick={addDashbotIntegration}
              style={{
                backgroundColor: '#0a2740',
                color: '#dbdbdb',
                padding: '6px 16px',
                margin: '10px 20px 10px 10px',
                cursor: 'pointer',
                borderRadius: '5px',
              }}
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
        {/* SNACKBARS */}
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={successSnackbar}
          autoHideDuration={2000}
          onClose={_closeSnackbar}
        >
          <SnackbarContent
            style={divStyle.snackbar}
            message={
              <Grid style={divStyle.snackbarGrid}>
                <CheckCircle style={divStyle.snackbarIconCircle} />
                {snackbarMessage}
              </Grid>
            }
          />
        </Snackbar>
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={errorSnackbar}
          autoHideDuration={2000}
          onClose={_closeSnackbar}
        >
          <SnackbarContent
            style={divStyle.errorSnackbar}
            message={snackbarMessage}
          />
        </Snackbar>
        <SaveDetectorConfirmation
          hasUnsavedChanges={hasUnsavedChanges}
          onSave={handleSave}
        />
      </div>
    )
  } else if (params.botId) {
    return (
      <div style={{ padding: 50, color: colors.darkGreyBlue }}>Loading...</div>
    ) // TODO show a better loading screen
  }

  return <Navigate to="/" />
}

export default withRouter(withStyles(styles)(observer(Integrations)))
