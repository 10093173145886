import './feedback.css'

import { map } from 'lodash-es'
import { DateTime } from 'luxon'
import { inject, observer } from 'mobx-react'
import * as React from 'react'
import { MdOutlineFeedback } from 'react-icons/md'

import { FormControl, FormLabel, Input, Text, Tooltip } from '@chakra-ui/react'
import {
  FormControlLabel,
  Grid,
  Paper,
  Snackbar,
  SnackbarContent,
  StyleRulesCallback,
  Switch,
  withStyles,
} from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import InfoIcon from '@material-ui/icons/InfoOutlined'
import ThumbDown from '@material-ui/icons/ThumbDownRounded'
import ThumbUp from '@material-ui/icons/ThumbUpRounded'

import { getFeedbackRecords } from '../../api/feedback'
import SaveDetector, {
  ISaveDetectorFormElements,
  noop,
  SAVE_DETECTOR_BAR_HEIGHT,
} from '../../components/saveDetector'
import { colors } from '../../hocs/withTheme'
import { RootStore } from '../../models/root-store/root.store'
import { IBot } from '../../models/bots'
import CreditCardCapture from '../onboarding/CreditCardCapture'
// import TagManager from 'react-gtm-module'
import { IFeedback } from './interfaces'
import { RouteProps, withRouter } from 'src/utils/withRouter'

// import Logger from '../../utils/logger'
// const { log } = Logger('feedback/index')

const styles: StyleRulesCallback = (theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: 40,
    minWidth: 300,
    width: '100%',
  },
  formControl: {
    width: '100%',
  },
  dialog: {
    // backgroundColor: colors.darkGreyBlue
  },
  textField: {
    root: {
      color: theme.palette.common.white,
    },
    color: 'inherit',
    width: '100%',
    primaryTextColor: theme.palette.common.white,
    text: {
      primary: theme.palette.common.white,
    },
  },
  textFieldPageUrl: {
    root: {
      color: theme.palette.common.white,
    },
    color: 'inherit',
    width: '100%',
    marginTop: 0,
    primaryTextColor: theme.palette.common.white,
    text: {
      primary: theme.palette.common.white,
    },
  },
  input: {
    font: 'Open Sans, sans-serif',
    width: '95%',
    color: colors.purple,
  },
  infoButton: {
    color: colors.medGreyBlue,
    fontSize: '1em',
    fontWeight: 550,
    cursor: 'pointer',
    opacity: 1,
    marginLeft: '1em',
  },
})

const divStyle = {
  // Snackbar
  snackbarGrid: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  snackbar: {
    backgroundColor: colors.green,
    color: colors.offWhite,
  },
  errorSnackbar: {
    backgroundColor: colors.errorRed,
    color: colors.offWhite,
  },
  snackbarIconCircle: { fontSize: 20, marginRight: 8 },
}

const tooltipText = {
  askFeedbackToggle:
    'When enabled, a feedback box will appear after responses marked as the end of a conversation in Dialogflow, or when a context named "botcopy-feedback" is attached to a Dialogflow intent or through a window trigger.',
  customerFeedback:
    'All positive and negative feedback is collected and displayed below with the Dialogflow Session ID and a comment if applicable.',
  sessionId: 'Dialogflow Session ID',
}

interface IFeedbackProps extends RouteProps {
  classes: any
  store?: RootStore
}

interface IFeedbackState {
  feedbackTitleSnackbar: boolean
  feedbackThankYouSnackbar: boolean
  errorSnackbar: boolean
  successSnackbar: boolean
  snackbarMessage: string
  feedbackArray: IFeedback[]
  thumbsUpCount: number
  thumbsDownCount: number
  showPositiveFeedback: boolean
  showNegativeFeedback: boolean
}

@inject('store')
@observer
class Feedback extends React.Component<IFeedbackProps, IFeedbackState> {
  private formElements: ISaveDetectorFormElements = {
    bcFeedbackTitle: {
      ref: React.createRef(),
      inputRef: React.createRef(),
      onKeyPress: async (e) => {
        const currentBot = this.props.store?.bots.currentBot
        if (
          e.key === 'Enter' &&
          currentBot &&
          currentBot.theme.feedback &&
          currentBot.theme.feedback.bcFeedbackTitle !== e.target.value
        ) {
          try {
            await currentBot.theme.feedback.changeFeedbackTitle(e.target.value)
            await currentBot.patchTheme(currentBot.theme)
            this.setState({
              feedbackTitleSnackbar: true,
            })
          } catch (e) {
            this.setState({
              errorSnackbar: true,
            })
            return false
          }
        }
        return true
      },
      onChange: noop,
    },
    bcFeedbackThankYou: {
      ref: React.createRef(),
      inputRef: React.createRef(),
      onKeyPress: async (e) => {
        const currentBot = this.props.store?.bots.currentBot
        if (
          e.key === 'Enter' &&
          currentBot &&
          currentBot.theme.feedback &&
          currentBot.theme.feedback.bcFeedbackThankYou !== e.target.value
        ) {
          try {
            await currentBot.theme.feedback.changeFeedbackThankYou(
              e.target.value,
            )
            await currentBot.patchTheme(currentBot.theme)
            this.setState({
              feedbackThankYouSnackbar: true,
            })
          } catch (e) {
            this.setState({
              errorSnackbar: true,
            })
            return false
          }
        }
        return true
      },
      onChange: noop,
    },
  }

  constructor(props: IFeedbackProps) {
    super(props)
    this.state = {
      feedbackTitleSnackbar: false,
      feedbackThankYouSnackbar: false,
      errorSnackbar: false,
      successSnackbar: false,
      snackbarMessage: '',
      feedbackArray: [],
      thumbsUpCount: 0,
      thumbsDownCount: 0,
      showPositiveFeedback: true,
      showNegativeFeedback: true,
    }
  }

  public async componentDidMount() {
    const {
      params: { botId },
    } = this.props

    if (botId) {
      const feedbackArray: IFeedback[] = await getFeedbackRecords(botId)
      const thumbsUpCount: number = feedbackArray.filter(
        (item: IFeedback) => item.feedback,
      ).length
      const thumbsDownCount: number = feedbackArray.filter(
        (item: IFeedback) => !item.feedback,
      ).length
      this.setState({
        feedbackArray,
        thumbsUpCount,
        thumbsDownCount,
      })
    }
  }

  public render() {
    const {
      store,
      params: { botId },
    } = this.props
    const currentBot = this.props.store?.bots.currentBot
    const org = this.props.store?.organizations.current
    const me = store?.users.me
    if (this.props.store?.bots.currentBotId !== botId) {
      this.props.store?.bots.setCurrentBotId(botId)
      return null
    }
    if (currentBot?.theme.css && org && me) {
      return this._renderFeedback(currentBot)
    }
    return (
      <div style={{ padding: 50, color: colors.darkGreyBlue }}>Loading...</div>
    ) // TODO show a better loading screen
  }

  private _renderFeedback(bot: IBot) {
    const { classes, store } = this.props
    const me = store?.users.me
    const org = store?.organizations.current
    if (!me || !org || !bot.theme.feedback) {
      return
    }

    return (
      <Grid container={true} justify="center">
        <CreditCardCapture classes={{}} store={store} />
        <Grid container={true}>
          <Grid
            container={true}
            className="section-grid"
            direction="column"
            justify="center"
            alignItems="center"
          >
            <Grid container={true} justify="center">
              <Paper className="settings-paper">
                <Grid
                  container={true}
                  direction="column"
                  justify="space-between"
                  alignItems="stretch"
                >
                  <Grid
                    container={true}
                    alignItems="center"
                    className="paper-title-grid"
                  >
                    <Grid
                      container={true}
                      justify="space-between"
                      alignItems="center"
                    >
                      <div className="feedback-title-grid">
                        <MdOutlineFeedback size="24px" />
                        <div className="paper-title">Feedback</div>
                        <Tooltip
                          label={tooltipText.askFeedbackToggle}
                          placement={'bottom'}
                          padding={5}
                        >
                          <a
                            href="https://docs.botcopy.com/#/basics/components?id=feedback"
                            target="_blank"
                          >
                            <InfoIcon
                              style={{ margin: '0 0 5px 5px' }}
                              className={classes.infoButton}
                            />
                          </a>
                        </Tooltip>
                      </div>
                      <Switch
                        checked={bot.theme.feedback.bcAskFeedback}
                        onChange={this._toggleAskFeedback}
                        className="feedback-toggle-switch"
                      />
                    </Grid>
                    <div className="feedback-paper-subtitle">
                      By default, the Feedback Component is translated to all
                      languages that Dialogflow supports.
                      <br />
                      <br />
                      Any changes made here will display the Feedback component
                      in the given language.
                    </div>
                  </Grid>
                  <div className="default-items-grid">
                    <FormControl mb={4}>
                      <FormLabel
                        as={Text}
                        textStyle="overline"
                        casing="uppercase"
                      >
                        Feedback Title
                      </FormLabel>
                      <Input
                        placeholder="How would you rate your experience today?"
                        defaultValue={bot.theme.feedback.bcFeedbackTitle}
                        ref={this.formElements.bcFeedbackTitle.inputRef}
                        onKeyPress={
                          this.formElements.bcFeedbackTitle.onKeyPress
                        }
                        onChange={this.formElements.bcFeedbackTitle.onChange}
                        h="56px"
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel
                        as={Text}
                        textStyle="overline"
                        casing="uppercase"
                      >
                        Thank you message
                      </FormLabel>
                      <Input
                        placeholder="Thank you!"
                        defaultValue={bot.theme.feedback.bcFeedbackThankYou}
                        ref={this.formElements.bcFeedbackThankYou.inputRef}
                        onKeyPress={
                          this.formElements.bcFeedbackThankYou.onKeyPress
                        }
                        onChange={this.formElements.bcFeedbackThankYou.onChange}
                        h="56px"
                      />
                    </FormControl>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={bot.theme.feedback.bcShowCommentButton}
                          onChange={this._toggleCollectComments}
                        />
                      }
                      label={'Display Add Comment button'}
                      labelPlacement="start"
                    />
                  </div>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
          <Grid
            container={true}
            className="feedback-main-container"
            direction="column"
            justify="center"
            alignItems="center"
            style={{
              paddingBottom: SAVE_DETECTOR_BAR_HEIGHT,
            }}
          >
            <Grid
              container={true}
              className="feedback-section"
              justify="space-around"
            >
              <Grid container={true} className="feedback-title">
                <div className="feedback-title-tooltip-container">
                  <div>User Feedback</div>
                  <Tooltip
                    label={tooltipText.customerFeedback}
                    placement={'top'}
                    padding={5}
                  >
                    <a
                      href="https://docs.botcopy.com/#/basics/components?id=feedback"
                      target="_blank"
                    >
                      <InfoIcon
                        style={{ margin: '0 0 5px 5px' }}
                        className={classes.infoButton}
                      />
                    </a>
                  </Tooltip>
                </div>
                <div className="count-paper">
                  <div
                    className={
                      this.state.showPositiveFeedback
                        ? 'positive-tile-active'
                        : 'positive-tile'
                    }
                    onClick={() => this._sortPositive()}
                  >
                    <div className="count">{this.state.thumbsUpCount}</div>
                    <ThumbUp className="positive-tile-icon" fontSize="small" />
                  </div>
                  <div
                    className={
                      this.state.showNegativeFeedback
                        ? 'negative-tile-active'
                        : 'negative-tile'
                    }
                    onClick={() => this._sortNegative()}
                  >
                    <div className="count">{this.state.thumbsDownCount}</div>
                    <ThumbDown
                      className="negative-tile-icon"
                      fontSize="small"
                    />
                  </div>
                </div>
              </Grid>
              <Grid container={true}>
                {this.state.showPositiveFeedback &&
                this.state.showNegativeFeedback ? (
                  <Grid container={true}>
                    {map(
                      this.state.feedbackArray,
                      (item: IFeedback, index: number) => {
                        const date = DateTime.fromISO(item.createdAt, {
                          zone: DateTime.local().zoneName,
                        })
                        return (
                          <div
                            key={index}
                            className={
                              item.feedback
                                ? 'feedback-record-container-positive'
                                : 'feedback-record-container-negative'
                            }
                          >
                            <div className="thumb-container">
                              {item.feedback ? (
                                <ThumbUp
                                  fontSize="small"
                                  style={{ color: colors.green }}
                                />
                              ) : (
                                <ThumbDown
                                  fontSize="small"
                                  style={{ color: colors.darkRed }}
                                />
                              )}
                            </div>
                            <Grid
                              container={true}
                              className="feedback-record-detail-container"
                              direction="column"
                              alignItems="flex-start"
                              justify="space-between"
                            >
                              <div className="feedback-record-comment">
                                {item.comment}
                              </div>
                              <div className="feedback-record-session-time-container">
                                <div className="feedback-record-timestamp">
                                  {date.toLocaleString(DateTime.DATETIME_SHORT)}
                                </div>
                                <Tooltip
                                  label={
                                    <div>
                                      Dialogflow Intent Display Name:
                                      <br />
                                      {item.feedbackIntentTrigger}
                                    </div>
                                  }
                                  placement={'right'}
                                  padding={5}
                                >
                                  <div className="feedback-record-intent-trigger">
                                    {item.feedbackIntentTrigger.length > 36
                                      ? item.feedbackIntentTrigger.substring(
                                          0,
                                          33,
                                        ) + '...'
                                      : item.feedbackIntentTrigger}
                                  </div>
                                </Tooltip>
                                <Tooltip
                                  label={tooltipText.sessionId}
                                  placement={'right'}
                                  padding={5}
                                >
                                  <div className="feedback-record-session-id">
                                    {item.dialogflowSessionId}
                                  </div>
                                </Tooltip>
                              </div>
                            </Grid>
                          </div>
                        )
                      },
                    )}
                  </Grid>
                ) : this.state.showPositiveFeedback &&
                  !this.state.showNegativeFeedback ? (
                  <Grid container={true}>
                    {map(
                      this.state.feedbackArray,
                      (item: IFeedback, index: number) => {
                        const date = DateTime.fromISO(item.createdAt, {
                          zone: DateTime.local().zoneName,
                        })
                        if (
                          item.feedback &&
                          this.state.showPositiveFeedback &&
                          !this.state.showNegativeFeedback
                        ) {
                          return (
                            <div
                              key={index}
                              className="feedback-record-container-positive"
                            >
                              <div className="thumb-container">
                                <ThumbUp
                                  fontSize="small"
                                  style={{ color: 'green' }}
                                />
                              </div>
                              <Grid
                                container={true}
                                className="feedback-record-detail-container"
                                direction="column"
                                alignItems="flex-start"
                                justify="space-between"
                              >
                                <div className="feedback-record-comment">
                                  {item.comment}
                                </div>
                                <Grid container={true} justify="flex-end">
                                  <div className="feedback-record-timestamp">
                                    {date.toLocaleString(
                                      DateTime.DATETIME_SHORT,
                                    )}
                                  </div>
                                  <Tooltip
                                    label={tooltipText.sessionId}
                                    placement={'right'}
                                    padding={5}
                                  >
                                    <div className="feedback-record-session-id">
                                      {item.dialogflowSessionId}
                                    </div>
                                  </Tooltip>
                                </Grid>
                              </Grid>
                            </div>
                          )
                        } else return null
                      },
                    )}
                  </Grid>
                ) : this.state.showNegativeFeedback &&
                  !this.state.showPositiveFeedback ? (
                  <Grid container={true}>
                    {map(
                      this.state.feedbackArray,
                      (item: IFeedback, index: number) => {
                        const date = DateTime.fromISO(item.createdAt, {
                          zone: DateTime.local().zoneName,
                        })
                        if (
                          !item.feedback &&
                          this.state.showNegativeFeedback &&
                          !this.state.showPositiveFeedback
                        ) {
                          return (
                            <div
                              key={index}
                              className="feedback-record-container-negative"
                            >
                              <div className="thumb-container">
                                <ThumbDown
                                  fontSize="small"
                                  style={{ color: colors.darkRed }}
                                />
                              </div>
                              <Grid
                                container={true}
                                className="feedback-record-detail-container"
                                direction="column"
                                alignItems="flex-start"
                                justify="space-between"
                              >
                                <div className="feedback-record-comment">
                                  {item.comment}
                                </div>
                                <Grid container={true} justify="flex-end">
                                  <div className="feedback-record-timestamp">
                                    {date.toLocaleString(
                                      DateTime.DATETIME_SHORT,
                                    )}
                                  </div>
                                  <Tooltip
                                    label={tooltipText.sessionId}
                                    placement={'right'}
                                    padding={5}
                                  >
                                    <div className="feedback-record-session-id">
                                      {item.dialogflowSessionId}
                                    </div>
                                  </Tooltip>
                                </Grid>
                              </Grid>
                            </div>
                          )
                        } else return
                      },
                    )}
                  </Grid>
                ) : null}
              </Grid>
              {/* <div className="feedback-grid"></div> */}
            </Grid>
          </Grid>
        </Grid>

        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={this.state.feedbackTitleSnackbar}
          autoHideDuration={2000}
          onClose={() => this._closeSnackbar('feedbackTitle')}
        >
          <SnackbarContent
            style={divStyle.snackbar}
            message={
              <Grid style={divStyle.snackbarGrid}>
                <CheckCircleIcon style={divStyle.snackbarIconCircle} />
                {'Updated feedback title'}
              </Grid>
            }
          />
        </Snackbar>

        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={this.state.feedbackThankYouSnackbar}
          autoHideDuration={2000}
          onClose={() => this._closeSnackbar('feedbackThankYou')}
        >
          <SnackbarContent
            style={divStyle.snackbar}
            message={
              <Grid style={divStyle.snackbarGrid}>
                <CheckCircleIcon style={divStyle.snackbarIconCircle} />
                {'Updated thank you message'}
              </Grid>
            }
          />
        </Snackbar>

        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={this.state.successSnackbar}
          autoHideDuration={2500}
          onClose={(e) => this._closeSnackbar('success')}
        >
          <SnackbarContent
            style={divStyle.snackbar}
            message={
              <Grid style={divStyle.snackbarGrid}>
                <CheckCircleIcon style={divStyle.snackbarIconCircle} />
                {this.state.snackbarMessage}
              </Grid>
            }
          />
        </Snackbar>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={this.state.errorSnackbar}
          autoHideDuration={2000}
          onClose={(e) => this._closeSnackbar('error')}
        >
          <SnackbarContent
            style={divStyle.errorSnackbar}
            message={'There was an error saving your change. Please try again.'}
          />
        </Snackbar>

        <SaveDetector
          formElements={this.formElements}
          length={Object.keys(this.formElements).length}
        />
      </Grid>
    )
  }

  private _closeSnackbar = (type: string) => {
    if (type === 'feedbackTitle') {
      this.setState((prev) => ({ ...prev, feedbackTitleSnackbar: false }))
    }
    if (type === 'feedbackThankYou') {
      this.setState((prev) => ({ ...prev, feedbackTitleSnackbar: false }))
    }
    if (type === 'success') {
      this.setState((prev) => ({ ...prev, successSnackbar: false }))
    }
    if (type === 'error') {
      this.setState((prev) => ({ ...prev, errorSnackbar: false }))
    }
  }

  private _handleSnackbar = async (type: string, snackbarMessage: string) => {
    this.setState({ snackbarMessage })
    switch (type) {
      case 'success':
        this.setState({ successSnackbar: true })
        break

      case 'error':
        this.setState({ errorSnackbar: true })
        break
    }
  }

  private _toggleAskFeedback = async () => {
    const currentBot = this.props.store?.bots.currentBot
    if (currentBot && currentBot.theme.feedback) {
      try {
        const askFeedback = !currentBot.theme.feedback.bcAskFeedback
        currentBot.theme.feedback.setAskFeedback(askFeedback)
        await currentBot.patchTheme(currentBot.theme)
        this._handleSnackbar(
          'success',
          askFeedback ? 'Enabled Feedback Box' : 'Disabled Feedback Box',
        )
      } catch (e) {
        this._handleSnackbar('error', 'Please try again')
      }
    }
  }

  private _toggleCollectComments = async () => {
    const currentBot = this.props.store?.bots.currentBot
    if (currentBot && currentBot.theme.feedback) {
      try {
        const showCommentButton = !currentBot.theme.feedback.bcShowCommentButton
        currentBot.theme.feedback.setShowCommentButton(showCommentButton)
        await currentBot.patchTheme(currentBot.theme)
        this._handleSnackbar(
          'success',
          showCommentButton
            ? 'Enabled Comment Button'
            : 'Disabled Comment Button',
        )
      } catch (e) {
        this._handleSnackbar('error', 'Please try again')
      }
    }
  }

  private _sortPositive = () => {
    const currentBot = this.props.store?.bots.currentBot
    if (currentBot && this.state.showPositiveFeedback) {
      this.setState({ showPositiveFeedback: false })
    } else this.setState({ showPositiveFeedback: true })
  }

  private _sortNegative = () => {
    const currentBot = this.props.store?.bots.currentBot
    if (currentBot && this.state.showNegativeFeedback) {
      this.setState({ showNegativeFeedback: false })
    } else this.setState({ showNegativeFeedback: true })
  }
}

export default withRouter(withStyles(styles)(Feedback))
