import './index.css'

import { debounce, isUndefined, map } from 'lodash-es'
import { inject, observer } from 'mobx-react'
import * as React from 'react'
import { MdClose, MdLabelOutline, MdOutlineAccountCircle } from 'react-icons/md'
// import { Navigate } from 'react-router-dom'
import trackUserEvent from 'src/components/trackEvents'

import {
  Badge,
  Box,
  Button,
  ButtonGroup,
  Flex,
  Image,
  Text,
  Tooltip,
} from '@chakra-ui/react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid as MuiGrid,
  InputAdornment,
  MenuItem,
  Paper,
  StyleRulesCallback,
  TextField,
  withStyles,
} from '@material-ui/core'

import { colors } from '../../hocs/withTheme'
import { RootStore } from '../../models/root-store/root.store'
import { BotPlatform, IBot } from '../../models/bots'
import BotsGrid from './Components/BotsGrid'
import BotsTable from './Components/BotsTable'
import {
  cxDataRegions,
  esDataRegions,
  IDataRegion,
} from '../../utils/dataRegions'
import { EventName, Events } from '../../utils/gtm'
import { FALLBACK_BOT_PROJECT_ID } from '../connect'
import CreditCardCapture from '../onboarding/CreditCardCapture'
import { PlanType } from '../stripe/interfaces'
import OnboardingForm from './Components/OnboardingForm'
import { RouteProps, withRouter } from 'src/utils/withRouter'

const styles: StyleRulesCallback = (theme) => ({
  root: {
    backgroundColor: colors.lightGreyScale100,
    display: 'flex',
    flexWrap: 'wrap',
    padding: 40,
    boxShadow: 'none',
    minWidth: 300,
    width: '100%',
    borderRadius: 0,
  },
  focusVisible: {},
  // To style outlined mui text field
  cssFocused: { color: colors.darkGreyBlue },
  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      borderColor: colors.darkGreyBlue,
    },
  },
  notchedOutline: {
    borderColor: colors.darkGreyBlue,
  },
  input: {
    color: colors.darkGreyBlue,
    font: 'Open Sans, sans-serif',
    fontSize: '14px',
  },
  inputLabel: {
    color: colors.darkGreyBlue,
    font: 'Open Sans, sans-serif',
    fontWeight: 550,
  },
  imageButton: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
  },
  imageSrc: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center 40%',
  },
  imageBackdrop: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.2,
    transition: theme.transitions.create('opacity'),
  },
  imageMarked: {
    height: 3,
    width: 18,
    backgroundColor: theme.palette.common.white,
    position: 'absolute',
    bottom: -2,
    left: 'calc(50% - 9px)',
    transition: theme.transitions.create('opacity'),
  },
  welcomeDiv: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
    fontFamily: 'Open Sans, sans-serif',
    fontSize: '2em',
    backgroundColor: colors.purple,
    color: colors.offWhite,
    height: '135px',
  },
  welcomeSubtitle: {
    justifyContent: 'center',
    fontFamily: 'Open Sans, sans-serif',
    fontSize: '2em',
    color: colors.darkGreyBlue,
    backgroundColor: colors.offWhite,
  },
  upgradeButton: {
    marginRight: 55,
    color: colors.offWhite,
    border: `1px solid ${colors.offWhite}`,
  },
})

const divs = {
  welcomeDiv: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
    fontFamily: 'Open Sans, sans-serif',
    fontSize: '2em',
    backgroundColor: colors.purple,
    color: colors.offWhite,
    height: '135px',
  },
  sevenFiveFree: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontFamily: 'Open Sans, sans-serif',
    fontSize: '2em',
    backgroundColor: '#e87c17',
    color: colors.offWhite,
    height: '135px',
  },
  overage: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontFamily: 'Open Sans, sans-serif',
    fontSize: '2em',
    backgroundColor: '#B33A3A',
    color: colors.offWhite,
    height: '135px',
  },
  atCap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontFamily: 'Open Sans, sans-serif',
    fontSize: '2em',
    backgroundColor: '#e87c17',
    color: colors.offWhite,
    height: '135px',
  },
}

const tooltipText = {
  botName: `Naming your bot will change the header label in the chat window`,
  dashboardLabel: `Add a label to categorize your bot on the dashboard`,
}

interface IDashboardProps extends RouteProps {
  classes: any
  store?: RootStore
  searchQuery: string
  viewMode: 'grid' | 'table'
  toggleViewMode: (mode: 'grid' | 'table') => void
}

interface IDashboardState {
  botLimitDialog: boolean
  onHoldDialog: boolean
  connectBotDialog: boolean
  preferredEmail: string
  businessName: string
  reasonsForSignup: any[]
  website: string
  noContactEmailSnackbar: boolean
  creatingBot: boolean
  createBotDialog: boolean
  createBotStepOne: boolean
  createBotPlatform: string
  createBotDataRegion: string
  createBotName: string
  createBotLabel: string
  filteredBots: IBot[]
}

@inject('store')
@observer
class Dashboard extends React.Component<IDashboardProps, IDashboardState> {
  constructor(props: IDashboardProps) {
    super(props)
    this.state = {
      botLimitDialog: false,
      onHoldDialog: false,
      connectBotDialog: false,
      preferredEmail: '',
      businessName: '',
      reasonsForSignup: [],
      website: '',
      noContactEmailSnackbar: false,
      creatingBot: false,
      createBotDialog: false,
      createBotStepOne: true,
      createBotPlatform: '',
      createBotDataRegion: 'global',
      createBotName: '',
      createBotLabel: '',
      filteredBots: this.props.store?.bots.entities,
    }
    this._createBot = debounce(this._createBot.bind(this), 250) as any
    this._gotoBot = this._gotoBot.bind(this)
  }

  public async componentDidMount() {
    this.props.store?.session?.setNavDrawerOpen(false)

    this.filterBots() // Call this after ensuring bots are fetched
  }

  componentDidUpdate(prevProps: IDashboardProps) {
    // Check if searchQuery has changed
    if (prevProps.searchQuery !== this.props.searchQuery) {
      this.filterBots()
    }
  }

  filterBots = () => {
    const { searchQuery } = this.props
    const bots = this.props.store?.bots.entities

    let filteredBots = bots
    if (searchQuery) {
      filteredBots = bots.filter((bot: IBot) =>
        bot.name.toLowerCase().includes(searchQuery.toLowerCase()),
      )
    }
    this.setState({ filteredBots })
  }

  ChoosePlatformModalContent = () => {
    return (
      <ButtonGroup display="grid" gridTemplateColumns="repeat(2, 1fr)" gap={4}>
        <Button
          onClick={this.createBotPlatformES}
          variant="outline"
          height="auto"
          leftIcon={
            <img
              src={`${process.env.PUBLIC_URL}/images/dialogflow/esLogo.png`}
              className="platform-type-img"
              alt="Dialogflow ES logo"
            />
          }
        >
          Dialogflow ES
        </Button>
        <Button
          onClick={this.createBotPlatformCX}
          variant="outline"
          height="auto"
          leftIcon={
            <img
              src={`${process.env.PUBLIC_URL}/images/dialogflow/cxLogo.png`}
              className="platform-type-img"
              alt="Dialogflow CX logo"
            />
          }
        >
          Dialogflow CX
        </Button>

        <Button
          isDisabled={true}
          variant="outline"
          height="5rem"
          leftIcon={
            <Image
              src={`${process.env.PUBLIC_URL}/images/logos/open-ai.svg`}
              maxHeight="40px"
            />
          }
        >
          <Flex direction="column">
            OpenAI Assistant <Badge>Coming Soon</Badge>
          </Flex>
        </Button>

        <Button
          isDisabled={true}
          variant="outline"
          height="5rem"
          size="lg"
          leftIcon={
            <Image
              src={`${process.env.PUBLIC_URL}/images/logos/1000x1000purple.png`}
              maxHeight="40px"
            />
          }
        >
          <Flex direction="column">
            Custom Webhook <Badge>Coming Soon</Badge>
          </Flex>
        </Button>
      </ButtonGroup>
    )
  }

  public render() {
    const { classes, store } = this.props
    const bots = store?.bots.entities
    const newestBot = bots.length > 0 ? bots[bots.length - 1] : false
    const org = store?.organizations.current
    if (!org) {
      // return <Navigate to="/" />
      return null
    }

    return (
      <>
        <CreditCardCapture classes={{}} store={store} />
        <div
          style={{
            width: '100%',
            minWidth: '100%',
          }}
        >
          <Paper
            style={{
              width: '100%',
              minWidth: '100%',
            }}
          >
            {this._renderBanner()}
          </Paper>
          <Box
            flex={1}
            flexDirection="row"
            width="80%"
            maxWidth="80rem"
            mx="auto"
            py={16}
          >
            <Text mb={3} textStyle="h3">
              Portal Dashboard
            </Text>
            <Text textStyle="h5" color={colors.lightGreyScale1100} mb={5}>
              Who are we working with today?
            </Text>
            {this.props.viewMode === 'grid' && (
              <BotsGrid
                filteredBots={this.state.filteredBots}
                creatingBot={this.state.creatingBot}
                onboardingStep={2}
                createBotPlatformDialog={this.createBotPlatformDialog}
                gotoBot={this._gotoBot}
              />
            )}
            {this.props.viewMode === 'table' && (
              <BotsTable
                bots={this.state.filteredBots}
                createBotPlatformDialog={this.createBotPlatformDialog}
                gotoBot={this._gotoBot}
              />
            )}
          </Box>
        </div>

        {/* DIALOGS */}

        {/* ADD BOT ERROR */}
        <Dialog
          open={this.state.botLimitDialog}
          onClose={() => this._closeDialog('addError')}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <MuiGrid
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <DialogTitle style={{ fontWeight: 650 }} id="alert-dialog-title">
              The maximum limit for the free trial is one bot.
            </DialogTitle>
            <MdClose
              style={{
                display: 'flex',
                justifyContent: 'center',
                margin: '5px 5px 0 0',
                cursor: 'pointer',
              }}
              onClick={(e: any) => this._closeDialog('addError')}
            />
          </MuiGrid>
          <DialogContent style={{ paddingBottom: '15px' }}>
            <DialogContentText id="alert-dialog-description">
              For unlimited bots, subscribe to one of our plans.
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ padding: '0px 3px 10px 0px' }}>
            <Button
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100px',
              }}
              onClick={this._goToPlans}
            >
              Choose Plan
            </Button>
            <Button variant="light" onClick={this._setUpMeeting}>
              Set up a call
            </Button>
          </DialogActions>
        </Dialog>

        {/* ON HOLD DIALOG */}

        <Dialog
          open={this.state.onHoldDialog}
          onClose={(e) => this._closeDialog('onHold')}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <MuiGrid
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <DialogTitle style={{ fontWeight: 650 }} id="alert-dialog-title">
              Please settle your outstanding balance before adding a new bot.
            </DialogTitle>
            <MdClose
              style={{
                display: 'flex',
                justifyContent: 'center',
                margin: '5px 5px 0 0',
                cursor: 'pointer',
              }}
              onClick={(e: any) => this._closeDialog('onHold')}
            />
          </MuiGrid>
          <DialogContent>
            <DialogContentText
              style={{ fontWeight: 600 }}
              id="alert-dialog-description"
            >
              Click the button below, on the dashboard banner, or in your
              account page.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this._settleOutstandingBalance}>
              Settle Invoice
            </Button>
            <Button variant="light" onClick={() => this._closeDialog('onHold')}>
              Close
            </Button>
          </DialogActions>
        </Dialog>

        {/* NEW ORG STEP ONE - ONBOARDING FORM */}

        {org && org.onboarding.step === 1 && (
          <OnboardingForm classes={classes} store={this.props.store} />
        )}

        {/* Create Bot Platform Dialog */}

        <Dialog
          open={this.state.createBotDialog}
          onClose={(e) => this._closeDialog('createBot')}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="select-platform-dialog"
        >
          <DialogTitle
            className="select-platform-dialog-title"
            id="alert-dialog-title"
          >
            {this.state.createBotStepOne ? 'Select a Platform' : 'Bot Details'}
          </DialogTitle>
          <DialogContent
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {this.state.createBotStepOne ? (
              this.ChoosePlatformModalContent()
            ) : (
              <MuiGrid
                container={true}
                justify="space-between"
                alignItems="center"
                className="platform-grid"
                direction="column"
              >
                <TextField
                  label={<div className={classes.inputLabel}>Bot Name</div>}
                  variant="outlined"
                  fullWidth={true}
                  margin="dense"
                  placeholder={`Bot ${bots.length + 1}`}
                  InputProps={{
                    classes: {
                      root: classes.cssOutlinedInput,
                      focused: classes.cssFocused,
                      notchedOutline: classes.notchedOutline,
                      input: classes.input,
                    },
                    startAdornment: (
                      <InputAdornment position="start">
                        <Tooltip
                          label={tooltipText.botName}
                          placement={'bottom'}
                          padding={5}
                        >
                          <span>
                            <MdOutlineAccountCircle />
                          </span>
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{ maxLength: 25 }} // notice lowercase i
                  FormHelperTextProps={{
                    style: {
                      color: colors.grey,
                    },
                  }}
                  onChange={(e) => {
                    this.setState({ createBotName: e.target.value })
                  }}
                />
                <TextField
                  label={
                    <div className={classes.inputLabel}>
                      Dashboard Label (Optional)
                    </div>
                  }
                  variant="outlined"
                  fullWidth={true}
                  margin="dense"
                  placeholder="Support Bot"
                  InputProps={{
                    classes: {
                      root: classes.cssOutlinedInput,
                      focused: classes.cssFocused,
                      notchedOutline: classes.notchedOutline,
                      input: classes.input,
                    },
                    startAdornment: (
                      <InputAdornment position="start">
                        <Tooltip
                          label={tooltipText.dashboardLabel}
                          placement={'bottom'}
                          padding={5}
                        >
                          <span>
                            <MdLabelOutline />
                          </span>
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{ maxLength: 25 }} // notice lowercase i
                  InputLabelProps={{
                    className: classes.inputLabel,
                    shrink: true,
                  }}
                  FormHelperTextProps={{
                    style: {
                      color: colors.grey,
                    },
                  }}
                  onChange={(e) => {
                    this.setState({ createBotLabel: e.target.value })
                  }}
                />
                {this.state.createBotPlatform === BotPlatform.DIALOGFLOW_ES ? (
                  <TextField
                    select={true}
                    variant="outlined"
                    margin="dense"
                    value={this.state.createBotDataRegion}
                    label={
                      <div className={classes.inputLabel}>Data Region</div>
                    }
                    fullWidth={true}
                    className="data-region-label"
                    SelectProps={{
                      autoWidth: true,
                    }}
                    InputLabelProps={{
                      className: classes.inputLabel,
                      shrink: true,
                    }}
                    InputProps={{
                      classes: {
                        root: classes.cssOutlinedInput,
                        focused: classes.cssFocused,
                        notchedOutline: classes.notchedOutline,
                        input: classes.input,
                      },
                    }}
                    onChange={(e) => {
                      this.setState({ createBotDataRegion: e.target.value })
                    }}
                  >
                    {map(
                      esDataRegions,
                      (region: IDataRegion, index: number) => {
                        return (
                          <MenuItem value={region.value} key={index}>
                            {region.title}
                          </MenuItem>
                        )
                      },
                    )}
                  </TextField>
                ) : (
                  <TextField
                    select={true}
                    variant="outlined"
                    margin="dense"
                    value={this.state.createBotDataRegion}
                    label="Data Region"
                    fullWidth={true}
                    className="data-region-label"
                    SelectProps={{
                      autoWidth: true,
                    }}
                    InputLabelProps={{
                      className: classes.inputLabel,
                      shrink: true,
                    }}
                    InputProps={{
                      classes: {
                        root: classes.cssOutlinedInput,
                        focused: classes.cssFocused,
                        notchedOutline: classes.notchedOutline,
                        input: classes.input,
                      },
                    }}
                    onChange={(e) => {
                      this.setState({ createBotDataRegion: e.target.value })
                    }}
                  >
                    {map(
                      cxDataRegions,
                      (region: IDataRegion, index: number) => {
                        return (
                          <MenuItem value={region.value} key={index}>
                            {region.title}
                          </MenuItem>
                        )
                      },
                    )}
                  </TextField>
                )}
                <Button
                  style={{
                    marginTop: '10px',
                    marginBottom: '-5px',
                    padding: '8px 120px',
                  }}
                  onClick={this._createBot}
                >
                  Create Bot
                </Button>
              </MuiGrid>
            )}
          </DialogContent>
          <DialogActions className="select-platform-dialog-actions">
            {this.state.createBotStepOne ? null : (
              <Button
                variant="light"
                onClick={() => this.setState({ createBotStepOne: true })}
              >
                Back to platforms
              </Button>
            )}
            <Button
              variant="light"
              onClick={() => this._closeDialog('createBot')}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>

        {/* CONNECT BOT DIALOG */}

        {newestBot ? (
          <Dialog
            open={this.state.connectBotDialog}
            onClose={(e) => this._closeDialog('connectBot')}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle style={{ fontWeight: 650 }} id="alert-dialog-title">
              Unused Bot
            </DialogTitle>
            <DialogContent>
              <DialogContentText
                style={{ fontWeight: 600 }}
                id="alert-dialog-description"
              >
                It looks like your existing bot named {newestBot.name} has not
                been connected to a Dialogflow agent. Please connect it to an
                agent to add another bot.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={(e) => this._gotoBot(newestBot)}>
                Go to {newestBot.name}
                's Connect page
              </Button>
              <Button
                variant="light"
                onClick={(e) => this._closeDialog('connectBot')}
              >
                Ok
              </Button>
            </DialogActions>
          </Dialog>
        ) : null}
      </>
    )
  }

  private _closeDialog = (dialog: string) => {
    switch (dialog) {
      case 'addError':
        this.setState((prevState) => ({ ...prevState, botLimitDialog: false }))
        break

      case 'connectBot':
        this.setState((prevState) => ({
          ...prevState,
          connectBotDialog: false,
        }))
        break

      case 'onHold':
        this.setState((prevState) => ({ ...prevState, onHoldDialog: false }))

      case 'createBot':
        this.setState({ createBotDialog: false })
    }
  }

  private _setUpMeeting = () => {
    const { store } = this.props
    const user = store?.users.me
    if (user) {
      this.setState({ botLimitDialog: false })
      const url = `https://calendly.com/botcopy?name=${user.name}&email=${user.email}`
      window.open(url, '_blank')
    }
  }

  private _renderBanner = () => {
    const { store } = this.props
    const org = store?.organizations.current
    if (!org) {
      return (
        <div style={divs.welcomeDiv}>
          <div style={{ marginLeft: 55 }}>Loading...</div>
        </div>
      )
    }

    const n = org.notifications

    // Free plan banner
    if (
      org.plan.planType === 'free' &&
      org.balancesEngagementsTotal < org.plan.conversationLimit
    ) {
      if (n.seventyFiveNoticeFree) {
        return (
          <div style={divs.sevenFiveFree}>
            <div style={{ marginLeft: 55 }}>
              You've nearly reached the engagement limit for free accounts!
              <br />
              <div style={{ fontSize: 14 }}>
                When you surpass {org.plan.conversationLimit} engagements, you
                have three days to subscribe to a plan. After the grace period,
                your bot is deactivated.
              </div>
            </div>
            {!org.onboarding.creditCardCaptured ? (
              <Button onClick={this._openCreditCardCaptureDialog} mr={'55px'}>
                Upgrade
              </Button>
            ) : (
              <Button onClick={this._goToPlans} mr={'55px'}>
                Choose Plan
              </Button>
            )}
          </div>
        )
      }

      if (n.overageFree) {
        return (
          <div style={divs.overage}>
            <div style={{ marginLeft: 55 }}>
              Congratulations! You have surpassed the engagement limit for free
              accounts.
              <br />
              <div style={{ fontSize: 16 }}>
                Please upgrade within three days to keep your bot active.
              </div>
            </div>
            <Button onClick={this._goToPlans} mr={'55px'}>
              Upgrade Plan
            </Button>
          </div>
        )
      }

      if (n.overageFreeGrace === 1) {
        return (
          <div style={divs.overage}>
            <div style={{ marginLeft: 55 }}>
              You have surpassed the engagement limit for free accounts.
              <br />
              <div style={{ fontSize: 16 }}>
                There are three days remaining in your grace period.
              </div>
            </div>
            <Button onClick={this._goToPlans} mr={'55px'}>
              Upgrade Plan
            </Button>
          </div>
        )
      }

      if (n.overageFreeGrace === 2) {
        return (
          <div style={divs.overage}>
            <div style={{ marginLeft: 55 }}>
              You have surpassed the engagement limit for free accounts.
              <br />
              <div style={{ fontSize: 16 }}>
                There are two days remaining in your grace period.
              </div>
            </div>
            <Button onClick={this._goToPlans} mr={'55px'}>
              Upgrade Plan
            </Button>
          </div>
        )
      }

      if (n.overageFreeGrace === 3) {
        return (
          <div style={divs.overage}>
            <div style={{ marginLeft: 55 }}>
              You've surpassed the engagement limit included in your free plan.
              <br />
              <div style={{ fontSize: 16 }}>
                Please upgrade to keep your bot active, or it will be
                deactivated.
              </div>
            </div>
            <Button onClick={this._goToPlans} mr={'55px'}>
              Upgrade Plan
            </Button>
          </div>
        )
      }

      // Free plan and at end of trial banner
      if (
        org.notifications.overageFreeGrace >= 5 &&
        org.balancesEngagementsTotal < org.plan.conversationLimit
      ) {
        return (
          <div style={divs.overage}>
            <MuiGrid direction="column" style={{ marginLeft: 55 }}>
              <div className="onhold-banner-title">
                Your free trial with Botcopy is up!
              </div>
              <div className="onhold-banner-subtitle">
                Upgrade now to one of our cost saving plans.
              </div>
            </MuiGrid>
            <Button onClick={this._goToPlans} mr={'55px'}>
              Upgrade Plan
            </Button>
          </div>
        )
      }

      // On hold banner
      if (org.plan.onHold) {
        return (
          <div style={divs.overage}>
            <MuiGrid direction="column" style={{ marginLeft: 55 }}>
              <div className="onhold-banner-title">
                Your account is on hold.
              </div>
              <div className="onhold-banner-subtitle">
                Please resolve your payment issue to reactivate your account.
              </div>
            </MuiGrid>
            <Button onClick={this._goToPlans} mr={'55px'}>
              Upgrade Plan
            </Button>
          </div>
        )
      }
    }

    if (org.plan.planType !== PlanType.FREE.toString()) {
      if (org.plan.onHold) {
        return (
          <div style={divs.overage}>
            <div style={{ marginLeft: 55 }}>
              Your account is on hold due to failed payment on your recent
              invoice.
              <br />
              <div style={{ fontSize: 14 }}>
                Click the button or navigate to your account page to settle the
                amount, or your bots will be deactivated seven days after the
                date of the invoice.
              </div>
            </div>
            <Button onClick={this._settleOutstandingBalance} mr={'55px'}>
              Settle Invoice
            </Button>
          </div>
        )
      }

      if (org.plan.atEventCap) {
        return (
          <div style={divs.atCap}>
            <div style={{ marginLeft: 55 }}>
              Your bots are inactive due to reaching your monthly engagement
              cap.
              <br />
              <div style={{ fontSize: 14 }}>
                To disable the cap and reactivate your bots, go to your Account
                page.
              </div>
            </div>
          </div>
        )
      }
    }
    return
  }

  private async _createBot() {
    await this.setState({ creatingBot: true })
    const { store } = this.props
    const {
      createBotName,
      createBotLabel,
      createBotPlatform,
      createBotDataRegion,
    } = this.state
    const user = store?.users.me
    const bots = store?.bots.entities
    const newestBot = bots.length > 0 ? bots[bots.length - 1] : false
    const count = bots.length + 1
    const org = store?.organizations.current
    if (!user || !org) return
    let name = createBotName || `Bot ${count}`
    // tslint:disable
    for (let i = 0; i < bots.length; i++) {
      if (bots[i].name === name) {
        name = `New Bot ${count}`
        break
      }
    }
    // tslint:enable

    // show dialog if free account is at their bot limit
    if (
      org.plan.planType === 'free' &&
      org.balancesEngagementsTotal < org.plan.conversationLimit &&
      bots.length >= org.plan.botLimit
    ) {
      return this.setState({
        botLimitDialog: true,
        creatingBot: false,
        createBotDialog: false,
      })
    }

    // show dialog if most recent bot has not been connected to dialogflow
    if (
      newestBot &&
      (newestBot.projectId === FALLBACK_BOT_PROJECT_ID || !newestBot.projectId)
    ) {
      return this.setState((prevState) => ({
        ...prevState,
        connectBotDialog: true,
        creatingBot: false,
        createBotDialog: false,
      }))
    }

    if (org.plan.onHold) {
      const dataLayer = {
        event: Events.subscription.onHold.view.type,
        eventName: Events.subscription.onHold.view.eventName,
        eventCode: Events.subscription.onHold.view.eventCode,
      }
      trackUserEvent('Portal View', dataLayer)
      return this.setState({
        onHoldDialog: true,
        creatingBot: false,
        createBotDialog: false,
      })
    }

    await store?.bots.create(
      name,
      createBotPlatform,
      createBotLabel,
      createBotDataRegion,
    )
    await this.setState({
      creatingBot: false,
      createBotDialog: false,
    })

    // if (org.onboarding.step === 2) {
    //   await org.incProductTourStep()
    //   const dataLayer = {
    //     event: Events.onboarding.type,
    //     eventName: Events.onboarding.eventName,
    //     eventCode: Events.onboarding.eventCode,
    //     label: Events.onboarding.firstBot.label,
    //   }
    //   trackUserEvent('Onboarding Step', dataLayer)
    // }

    // reset createBot states to defaults
    this.setState({
      createBotStepOne: true,
      createBotName: '',
      createBotLabel: '',
      createBotPlatform: '',
      createBotDataRegion: 'global',
    })
  }

  private async _gotoBot(bot: IBot) {
    const store = this.props.store
    const org = this.props.store?.organizations.current
    if (!org) return

    if (this.state.connectBotDialog) {
      this.setState((prevState) => ({ ...prevState, connectBotDialog: false }))
    }

    store?.session.setNavDrawerOpen(true)

    if (
      bot.newBot ||
      isUndefined(bot.newBot) ||
      !bot.projectId ||
      bot.projectId === FALLBACK_BOT_PROJECT_ID
    ) {
      this.props.navigate(`/bot/${bot._id}/connect`)
    } else {
      this.props.navigate(`/bot/${bot._id}/branding`)
    }
  }

  private _settleOutstandingBalance = async () => {
    const org = this.props.store?.organizations.current
    if (org && org.plan.stripe && org.plan.stripe.failedInvoiceLink) {
      window.open(org.plan.stripe.failedInvoiceLink, '_blank')
    }
  }

  private _openCreditCardCaptureDialog = () => {
    window.location.href = window.location.href + '?showPlans=true'
  }

  private _goToPlans = () => {
    const dataLayer = {
      event: Events.subscription.changePlan.viewPlans.type,
      eventName: Events.subscription.changePlan.viewPlans.eventName,
      eventCode: Events.subscription.changePlan.viewPlans.eventCode,
    }
    trackUserEvent('Portal View', dataLayer)
    window.open(`${process.env.PUBLIC_URL}/account?showPlans=true`, '_blank')
  }

  private createBotPlatformES = () => {
    this.setState({
      createBotPlatform: BotPlatform.DIALOGFLOW_ES,
      createBotStepOne: false,
    })
    const dataLayer = {
      event: Events.botCreation.createESBot.success.type,
      eventName: Events.botCreation.createESBot.success.eventName,
      eventCode: Events.botCreation.createESBot.success.eventCode,
    }
    trackUserEvent(EventName.PortalAction, dataLayer)
  }

  private createBotPlatformCX = () => {
    this.setState({
      createBotPlatform: BotPlatform.DIALOGFLOW_CX,
      createBotStepOne: false,
    })
    const dataLayer = {
      event: Events.botCreation.createCXBot.success.type,
      eventName: Events.botCreation.createCXBot.success.eventName,
      eventCode: Events.botCreation.createCXBot.success.eventCode,
    }
    trackUserEvent(EventName.PortalAction, dataLayer)
  }

  private createBotPlatformDialog = () => {
    this.setState({
      createBotDialog: true,
    })
    const dataLayer = {
      event: Events.botCreation.viewPlatform.type,
      eventName: Events.botCreation.viewPlatform.eventName,
      evenCode: Events.botCreation.viewPlatform.eventCode,
    }
    trackUserEvent('Portal View', dataLayer)
  }
}

export default withRouter(withStyles(styles)(Dashboard))
