import mixpanel from 'mixpanel-browser'
import { inject, observer } from 'mobx-react'
import { withAuth0, WithAuth0Props } from '@auth0/auth0-react'
import React from 'react'
import { MdLogout } from 'react-icons/md'

import {
  NavLinks,
  PersistentDrawer,
  PrimaryNavigationLinks,
} from '@botcopy/ui-shared'
import {
  Avatar,
  Box,
  Button,
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react'

import { RootStore } from '../../models/root-store/root.store'
import DrawerContent from './drawer'
import { FeatureFlags } from '../../utils/featureFlags'
import { RouteProps, withRouter } from 'src/utils/withRouter'

interface IMainNavigationProps extends WithAuth0Props, RouteProps {
  store?: RootStore
  isPersistentDrawerMaximized: boolean
  toggleDrawer: () => void
  searchQuery: string
  onSearchChange: (query: string) => void
  toggleViewMode: (mode: 'grid' | 'table') => void
  viewMode: 'grid' | 'table'
}

interface IMainNavigationState {
  isLoading: boolean
  isPersistentDrawerMaximized: boolean
}

@inject('store')
@observer
class MainNavigation extends React.Component<
  IMainNavigationProps,
  IMainNavigationState
> {
  constructor(props: IMainNavigationProps) {
    super(props)
    this.state = {
      isLoading: true,
      isPersistentDrawerMaximized:
        this.props.store?.session.isNavDrawerOpen ?? false,
    }
  }

  public async componentDidMount() {
    const { store } = this.props
    if (this.props.isPersistentDrawerMaximized) {
      store?.session.setNavDrawerOpen(this.props.isPersistentDrawerMaximized)
    }
    // Simulate loading authentication state
    setTimeout(() => {
      this.setState({ isLoading: false })
    }, 1000)
  }

  public render() {
    const { store, searchQuery, onSearchChange } = this.props
    const { isLoading } = this.state

    if (isLoading) {
      return (
        <Box
          width="100vw"
          borderBottomWidth="1px"
          borderBottomStyle="solid"
          borderBottomColor="#ccc"
        />
      )
    }

    const isVisitingLoginPage = this.props.location.pathname === '/login'
    const isAgentOnePage = this.props.location.pathname.includes('/agent-one')

    const navLinks: NavLinks[] = [
      {
        linkName: 'Portal',
        url: '/dashboard',
        onClick: (e) => {
          e.preventDefault()
          this.props.navigate('/dashboard')
        },
        isCurrentPage: !isAgentOnePage,
      },
    ]

    if (
      store?.organizations?.current?.featuresEnabled([FeatureFlags.AGENT_ONE])
    ) {
      navLinks.push({
        linkName: 'Agent One (Beta)',
        onClick: (e) => {
          e.preventDefault()
          this.props.navigate('/agent-one')
        },
        url: '/agent-one',
        isCurrentPage: isAgentOnePage,
      })
    }

    return (
      <>
        <Box>
          <Box
            borderBottom="1px solid #ccc"
            width="100%"
            position="fixed"
            top={0}
            zIndex={100}
            background="#fff"
          >
            <Box
              display="flex"
              maxWidth="100%"
              justifyContent="space-between"
              alignItems="center"
              px="3rem"
              mx="auto"
              position="relative"
              height="85px"
            >
              {this.props.auth0.isAuthenticated && (
                <>
                  <PrimaryNavigationLinks
                    hasUnderlineSVG={false}
                    navLinks={navLinks}
                    logoUrl="/"
                    logoProps={{ variant: 'blueHorizontal', size: 'xs' }}
                  />
                  <Menu>
                    <MenuButton as={Button} variant="ghost" h="fit-content">
                      <Flex alignItems="center">
                        <Box textAlign="right" className="body 2" fontSize="md">
                          <Text mr={3} mb={1}>
                            {this.props.auth0.user?.givenName}
                          </Text>
                          <Text mr={3}>
                            {this.props.auth0.user?.familyName}
                          </Text>
                        </Box>
                        <Avatar
                          name={this.props.auth0.user?.name}
                          src={this.props.auth0.user?.picture}
                          variant="square"
                          h={14}
                          w={14}
                        />
                      </Flex>
                    </MenuButton>
                    <MenuList>
                      <MenuItem onClick={this._handleLogout}>
                        <Icon as={MdLogout} mr={3} h={5} w={5} />
                        <Flex direction="column">
                          <Text fontSize="md" mb={1.5}>
                            Log Out
                          </Text>
                          <Text color="gray.500">{store?.users.me?.email}</Text>
                        </Flex>
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </>
              )}
              {!this.props.auth0.isAuthenticated && (
                <PrimaryNavigationLinks
                  hasUnderlineSVG={false}
                  navLinks={[
                    {
                      linkName: 'Docs',
                      url: '//docs.botcopy.com/',
                      isExternal: true,
                    },
                    {
                      linkName: 'Create Account',
                      url: '/signup/step1',
                      isCurrentPage: !isVisitingLoginPage,
                    },
                    {
                      linkName: 'Login',
                      url: '/login',
                      isCurrentPage: isVisitingLoginPage,
                    },
                  ]}
                  logoUrl="/"
                />
              )}
            </Box>
          </Box>
        </Box>
        {this.props.auth0.isAuthenticated && !isAgentOnePage && (
          <Box
            position="fixed"
            height="calc(100vh - 80px)"
            background="#fff"
            zIndex={9}
          >
            <PersistentDrawer
              isMaximized={this.props.isPersistentDrawerMaximized}
              setIsMaximized={this.props.toggleDrawer}
            >
              <DrawerContent
                store={store}
                searchQuery={searchQuery}
                onSearchChange={onSearchChange}
                isMaximized={this.props.isPersistentDrawerMaximized}
                toggleViewMode={this.props.toggleViewMode}
                viewMode={this.props.viewMode}
              />
            </PersistentDrawer>
          </Box>
        )}
      </>
    )
  }

  private _handleLogout = () => {
    this.props.auth0.logout({
      logoutParams: { returnTo: `${window.location.origin}/login` },
    })
    mixpanel.reset() // mixpanel stitches user journey across logged in and out
  }
}

export default withRouter(withAuth0(MainNavigation))
