import './checkout.css'

import { inject, observer } from 'mobx-react'
import * as React from 'react'

import { Button } from '@chakra-ui/react'
import {
  CircularProgress,
  Snackbar,
  SnackbarContent,
  StyleRulesCallback,
  TextField,
  withStyles,
} from '@material-ui/core'
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline'
import { CardElement } from '@stripe/react-stripe-js'
import Stripe from '@stripe/stripe-js'

import { checkout, updatePayment } from '../../api/stripe'
import { colors } from '../../hocs/withTheme'
import { RootStore } from '../../models/root-store/root.store'
import { PlanType } from './interfaces'

// import Logger from '../../utils/logger'
// const log = Logger('Checkout Form').log

const styles: StyleRulesCallback = (theme) => ({
  // Fields
  cssFocused: {},
  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      borderColor: colors.darkGreyBlue,
    },
    fontWeight: 400,
  },
  notchedOutline: {
    borderColor: colors.darkGreyBlue,
  },
  input: {
    font: 'Open Sans, sans-serif',
    fontSize: '14px',
    minWidth: '380px',
  },
})

const divStyles = {
  billingButtonIncomplete: {
    marginTop: 5,
    color: colors.darkGreyBlue,
  },
  billingButtonLoading: {
    marginTop: 5,
    backgroundColor: colors.buttonBlue,
    color: colors.offWhite,
  },
  billingButtonSubmit: {
    marginTop: 5,
    backgroundColor: colors.buttonBlue,
    color: colors.offWhite,
  },
  billingButtonSuccess: {
    marginTop: 5,
    color: colors.offWhite,
    backgroundColor: colors.green,
    fontWeight: 550,
  },
  buttonTextContainer: {
    display: 'flex',
    flexDirection: 'row' as 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonText: {
    marginRight: 10,
  },
  muiIconStyle: {
    fontSize: 25,
  },
  // Snackbar
  errorSnackbar: {
    backgroundColor: colors.errorRed,
    color: colors.offWhite,
  },
}

// Card Information Styles
const createOptions = () => {
  return {
    style: {
      base: {
        iconColor: colors.darkGreyBlue,
        fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
        fontSize: '15px',
        '::placeholder': {
          color: colors.grey,
        },
        complete: { color: colors.green, fontSize: '15px' },
        empty: {
          color: colors.grey,
          fontSize: '15px',
        },
        invalid: {
          color: colors.orange,
          fontSize: '15px',
        },
      },
    },
  }
}

interface ICheckoutProps {
  classes: any
  elements: Stripe.StripeElements | null
  stripe: Stripe.Stripe | null
  store?: RootStore
  checkoutSuccess: any
  plan?: PlanType
  onboarding?: boolean
  update?: boolean
}

interface ICheckoutState {
  billingInfoSnackbar: boolean
  chargeErrorSnackbar: boolean
  chargeErrorSnackbarText: string
  success: boolean
  loading: boolean
  name: string
  address_line1: string
  address_line2: string

  address_zip: string
  chargeErrorMessage: string | undefined
}

@inject('store')
@observer
class Checkout extends React.Component<ICheckoutProps, ICheckoutState> {
  constructor(props: ICheckoutProps) {
    super(props)
    this.state = {
      billingInfoSnackbar: false,
      chargeErrorSnackbar: false,
      chargeErrorSnackbarText: '',
      success: false,
      loading: false,
      name: '',
      address_line1: '',
      address_line2: '',
      address_zip: '',
      chargeErrorMessage: '',
    }
  }

  public render() {
    const { classes } = this.props
    const user = this.props.store?.users.me
    const org = this.props.store?.organizations.current
    if (!user || !org) {
      return false
    }

    return (
      <div className="right-panel">
        <div className="billing-container">
          <div className="stripe-checkout-title-grid">
            <div className="stripe-checkout-title-billing">Billing Details</div>
            <div className="secured-by-stripe-grid">
              <img
                src={`${process.env.PUBLIC_URL}/images/logos/cclogos.png`}
                height="20px"
              />
            </div>
          </div>
          <div className="stripe-checkout-billing-grid">
            <TextField
              label={<div className="stripe-field-label">Name on card *</div>}
              autoFocus={true}
              onChange={(e) => this.setState({ name: e.target.value })}
              variant="outlined"
              margin="dense"
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                classes: {
                  root: classes.cssOutlinedInput,
                  focused: classes.cssFocused,
                  notchedOutline: classes.notchedOutline,
                  input: classes.input,
                },
              }}
            />
            <TextField
              label={
                <div className="stripe-field-label">Billing Address *</div>
              }
              onChange={(e) => this.setState({ address_line1: e.target.value })}
              variant="outlined"
              margin="dense"
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                classes: {
                  root: classes.cssOutlinedInput,
                  focused: classes.cssFocused,
                  notchedOutline: classes.notchedOutline,
                  input: classes.input,
                },
              }}
            />
            <TextField
              label={
                <div className="stripe-field-label">
                  Suite / Apartment Number
                </div>
              }
              onChange={(e) => this.setState({ address_line2: e.target.value })}
              variant="outlined"
              margin="dense"
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                classes: {
                  root: classes.cssOutlinedInput,
                  focused: classes.cssFocused,
                  notchedOutline: classes.notchedOutline,
                  input: classes.input,
                },
              }}
            />
          </div>
        </div>

        <div
          className="stripe-checkout-title-billing"
          style={{ marginTop: '8px' }}
        >
          Card Information
        </div>

        {/* Container DOM Styles for Stripe */}

        <CardElement
          options={{
            ...createOptions(),
            hidePostalCode: true,
            classes: {
              base: 'stripe-checkout',
            },
            iconStyle: 'solid',
          }}
        />
        <TextField
          label={<div className="stripe-field-label">Postal/Zip Code *</div>}
          onChange={(e) => this.setState({ address_zip: e.target.value })}
          variant="outlined"
          margin="dense"
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            classes: {
              root: classes.cssOutlinedInput,
              focused: classes.cssFocused,
              notchedOutline: classes.notchedOutline,
              input: classes.input,
            },
          }}
        />
        <Button
          variant={
            this.state.success
              ? 'base'
              : this.state.loading
                ? 'light'
                : this.state.name !== '' &&
                    this.state.address_line1 !== '' &&
                    this.state.address_zip !== ''
                  ? 'dark'
                  : 'base'
          }
          disabled={
            this.state.name !== '' &&
            this.state.address_line1 !== '' &&
            this.state.address_zip !== ''
              ? false
              : true
          }
          onClick={(event) => this._submit(event)}
        >
          {this.state.success ? (
            <div style={divStyles.buttonTextContainer}>
              <div style={divStyles.buttonText}>Success!</div>{' '}
              <CheckCircleOutline style={{ width: '15px' }} />
            </div>
          ) : this.state.loading ? (
            <div style={divStyles.buttonTextContainer}>
              <div style={divStyles.buttonText}>Sending to Stripe</div>
              <CircularProgress size={'15px'} />
            </div>
          ) : this.state.name !== '' &&
            this.state.address_line1 !== '' &&
            this.state.address_zip !== '' ? (
            'Submit'
          ) : (
            'Submit'
          )}
        </Button>
        {this.state.chargeErrorMessage ? (
          <div className="stripe-declined-grid">
            <div>Stripe declined your card with the code:</div>
            <div
              style={{
                color: colors.offWhite,
                fontFamily: 'monospace',
                margin: '10px',
                padding: '10px',
                backgroundColor: colors.darkGreyBlue,
                borderRadius: '5px',
              }}
            >
              <strong>{this.state.chargeErrorMessage}</strong>
            </div>
            <div style={{ textAlign: 'center' }}>
              Please enter a different card, or follow{' '}
              <a
                href={'https://stripe.com/docs/declines/codes'}
                target="_blank"
              >
                this link
              </a>{' '}
              to review Stripe's documentation for this code.
            </div>
          </div>
        ) : null}

        {/* SNACKBARS */}

        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={this.state.billingInfoSnackbar}
          autoHideDuration={5000}
          onClose={this._closeSnackbar}
        >
          <SnackbarContent
            message={'Complete billing information to continue'}
          />
        </Snackbar>

        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={this.state.chargeErrorSnackbar}
          autoHideDuration={5000}
          onClose={this._closeSnackbar}
        >
          <SnackbarContent
            style={divStyles.errorSnackbar}
            message={`There was an error verifying your payment info: ${this.state.chargeErrorSnackbarText}`}
          />
        </Snackbar>
      </div>
    )
  }

  public _submit = async (event: any) => {
    event.preventDefault()
    const { store, checkoutSuccess, plan, update } = this.props
    const user = store?.users.me
    const org = store?.organizations.current
    const cardElement = this.props.elements?.getElement(CardElement)

    if (!org || !user || !cardElement) return

    if (!this.state.loading && !this.state.success) {
      this.setState({ loading: true, chargeErrorMessage: '' })
      // @ts-ignore
      const { token, error } = await this.props.stripe.createToken(
        cardElement,
        {
          name: this.state.name,
          address_line1: this.state.address_line1,
          address_line2: this.state.address_line2,
          address_zip: this.state.address_zip,
        },
      )
      if (!token) {
        this.setState({
          loading: false,
          chargeErrorSnackbar: true,
          chargeErrorSnackbarText:
            (error && error.message) || 'token could not be generated',
        })
        return
      }

      if (update) {
        try {
          const res = await updatePayment(
            token.id,
            org.plan && org.plan.stripe && org.plan.stripe.customer.id,
            org._id,
          )
          if (res === 'success') {
            this.setState({
              success: true,
              loading: false,
            })
            setTimeout(() => {
              checkoutSuccess()
            }, 2000)
          } else {
            throw new Error('unknown api error')
          }
        } catch (e: any) {
          this.setState({
            loading: false,
            chargeErrorSnackbar: true,
            chargeErrorSnackbarText:
              (e.response && e.response.data && e.response.data.message) ||
              e.message ||
              'unknown api error',
          })
        }
      }

      if (plan) {
        try {
          const checkoutResponse = await checkout(token.id, org._id, plan)

          if (!checkoutResponse) return

          if (checkoutResponse.success) {
            this.setState({
              success: true,
              loading: false,
              chargeErrorMessage: '',
            })
            setTimeout(() => {
              checkoutSuccess()
            }, 2000)
          }
        } catch (e: any) {
          this.setState({
            loading: false,
            chargeErrorMessage:
              (e.response && e.response.data && e.response.data.message) ||
              e.message,
          })
        }
      }
    }
  }

  private _closeSnackbar = () => {
    if (this.state.billingInfoSnackbar) {
      this.setState({
        billingInfoSnackbar: false,
      })
    }

    if (this.state.chargeErrorSnackbar) {
      this.setState({
        chargeErrorSnackbar: false,
        chargeErrorSnackbarText: '',
      })
    }
  }
}

export default withStyles(styles)(Checkout)
