import './step1.css'

import { get } from 'lodash-es'
import { inject, observer } from 'mobx-react'
import * as React from 'react'
import { Link, Navigate } from 'react-router-dom'

import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Grid,
  Paper,
  Typography,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Info from '@material-ui/icons/Info'

import { fetchOrgByInviteCode } from '../../api'
import { colors } from '../../hocs/withTheme'
import Logger from '../../utils/logger'
import { IOnboardingProps } from './'
import { Button } from '@chakra-ui/react'
import { withAuth0 } from '@auth0/auth0-react'

const { log } = Logger('OnboardingInvite')

const styles = {
  errorMessage: {
    fontSize: '2.5em',
    color: colors.darkGreyBlue,
    maxWidth: '250px',
    marginRight: 25,
    fontWeight: 600,
  },
  errorMessageDetails: {
    fontSize: '1.75em',
    color: colors.darkGreyBlue,
  },
  expansionPanel: {
    minWidth: 300,
    width: '100%',
    marginBottom: '20px',
    borderRadius: 5,
    boxShadow: '0px 5px #5C5DA6',
  },
  expansionPanelSummary: {
    backgroundColor: colors.darkGreyBlue,
    color: colors.offWhite,
    borderRadius: 5,
  },
  subtitle: {
    fontSize: '1.25em',
    fontWeight: 400,
    cursor: 'default',
    color: colors.lightGreyBlue,
    paddingLeft: '5px',
    fontFamily: 'Roboto',
  },
  stepHeader: {
    fontWeight: 650,
    fontSize: '1.5em',
    fontType: 'Open Sans, sans-serif',
  },
  stepDetails: {
    fontSize: '1.25em',
    fontType: 'Open Sans, sans-serif',
  },
  mergeButton: {
    color: '#fff',
    background: '#4285F4',
    fontFamily: 'Roboto',
    fontSize: '14px',
    cursor: 'pointer',
    padding: '8px 10px',
  },
}

interface IInviteState {
  failure: boolean
  loading: boolean
  noAgents: boolean
  maxKeys: boolean
  maxKeysDialog: boolean
  checkPermissions: boolean
  checkPermissionsDialog: boolean
  errorAgent: string
  inviteCode: string
  inviteEmail: string
  inviteRole: string
  existing: boolean
  orgName: string
  noCode: boolean
  loadingOrg: boolean
  expiredCode: boolean
  invalidEmail: boolean
}

@inject('store')
@observer
class Invite extends React.Component<IOnboardingProps, IInviteState> {
  constructor(props: IOnboardingProps) {
    super(props)
    this._onChange = this._onChange.bind(this)

    const search = get(window, 'location.search', '')
    const params = new URLSearchParams(search)
    const codeParam: any = params.get('code')
    const inviteCode = codeParam ? codeParam : ''

    this.state = {
      loading: false,
      failure: false,
      noAgents: false,
      maxKeys: false,
      maxKeysDialog: false,
      checkPermissions: false,
      checkPermissionsDialog: false,
      errorAgent: '',
      inviteCode,
      inviteEmail: '',
      inviteRole: '',
      existing: false,
      orgName: '',
      noCode: false,
      loadingOrg: false,
      expiredCode: false,
      invalidEmail: false,
    }
  }

  public componentDidMount = async () => {
    // check if logged in
    if (this.props.auth0.isAuthenticated) {
      await this.props.store?.session.logout()
    }

    this.setState((prevState) => ({ ...prevState, loadingOrg: true }))
    try {
      const org = await fetchOrgByInviteCode(this.state.inviteCode)
      this.setState((prevState) => ({ ...prevState, loadingOrg: false }))

      if (org === 'Invalid Code') {
        return this.setState((prevState) => ({ ...prevState, noCode: true }))
      }

      if (org === 'Expired Invite') {
        return this.setState((prevState) => ({
          ...prevState,
          expiredCode: true,
        }))
      }

      if (org.businessName && org.email && org.role) {
        return this.setState((prevState) => ({
          ...prevState,
          inviteEmail: org.email,
          orgName: org.businessName,
          inviteRole: org.role,
          existing: org.existing,
        }))
      }

      if (!org.businessName && org.email && org.role) {
        return this.setState((prevState) => ({
          ...prevState,
          inviteEmail: org.email,
          inviteRole: org.role,
          existing: org.existing,
        }))
      }
    } catch (e) {
      this.setState((prevState) => ({ ...prevState, loadingOrg: false }))
      log(e)
    }
  }

  public render() {
    const { loading, failure } = this.state
    if (failure) {
      return this._renderFailure()
    } else if (loading) {
      return this._renderLoading()
    }
    if (!this.state.inviteCode) {
      return <Navigate to="/" />
    }
    return (
      <Grid
        container={true}
        className="bannerGrid"
        justify="center"
        alignItems="center"
      >
        {/* Sign in Banner */}
        <Paper className="banner" square={true}>
          {/* Banner Grid Items */}
          <Grid
            className="bannerItems"
            container={true}
            justify="center"
            alignContent="center"
          >
            <Grid item={true} xs={12} className="banner-title">
              {this.state.noCode ? (
                'Sorry, this code is no longer valid.'
              ) : this.state.expiredCode ? (
                'Sorry, this code has expired.'
              ) : this.state.loadingOrg ? null : this.state.orgName ? (
                this.state.existing ? (
                  <Grid
                    container={true}
                    direction="column"
                    justify="center"
                    alignItems="center"
                  >
                    <div>You accepted an invitation!</div>
                    <div className="banner-subtitle">
                      Click below to merge your existing Botcopy account with{' '}
                      {this.state.orgName}
                    </div>
                  </Grid>
                ) : (
                  <Grid
                    container={true}
                    direction="column"
                    justify="center"
                    alignItems="center"
                  >
                    <div>You accepted an invitation!</div>
                    <div className="banner-subtitle">
                      Sign up for a Botcopy account and you'll be added to{' '}
                      {this.state.orgName} automatically.
                    </div>
                  </Grid>
                )
              ) : this.state.existing ? (
                <Grid
                  container={true}
                  direction="column"
                  justify="center"
                  alignItems="center"
                >
                  <div>You accepted an invitation!</div>
                  <div className="banner-subtitle">
                    Click below to merge your existing Botcopy account
                  </div>
                </Grid>
              ) : (
                <Grid
                  container={true}
                  direction="column"
                  justify="center"
                  alignItems="center"
                >
                  <div>You accepted an invitation!</div>
                  <div className="banner-subtitle">
                    Sign up for a Botcopy account and you'll be added to the
                    organization automatically.
                  </div>
                </Grid>
              )}
            </Grid>
            <Grid container={true} justify="center" alignContent="center">
              {this.state.loadingOrg ||
              this.state.expiredCode ||
              this.state.noCode ? null : this.state.existing ? (
                <Button
                  onClick={() => this.props.auth0.loginWithRedirect()}
                  style={styles.mergeButton}
                >
                  Merge Organizations
                </Button>
              ) : (
                <Button onClick={() => this.props.auth0.loginWithRedirect()}>
                  Sign up with Google
                </Button>
              )}
            </Grid>
            {this.state.existing ? (
              <div className="banner-warning-text">
                Your individual account and existing bots will be merged with
                the inviter's organization
              </div>
            ) : null}
          </Grid>
        </Paper>
      </Grid>
    )
  }

  private _onChange(event: any) {
    const text = event.target.value as string
    this.props.store?.session?.onboarding.setBusinessEmail(text)
  }

  private _renderLoading() {
    return (
      <div
        style={{
          alignContent: 'center',
          alignItems: 'center',
          flex: 1,
          flexWrap: 'wrap',
          justifyContent: 'center',
          margin: '80px auto',
          maxWidth: '500px',
        }}
      >
        <Grid
          container={true}
          spacing={24}
          style={{
            alignItems: 'center',
            alignContent: 'center',
            flex: 1,
            justifyContent: 'center',
          }}
        >
          <CircularProgress color="secondary" />
          <Grid
            item={true}
            xs={12}
            style={{
              fontWeight: 'bold',
              textAlign: 'center',
              fontSize: 30,
              color: colors.darkGreyBlue,
            }}
          >
            Setting up your Botcopy account...
          </Grid>
        </Grid>
      </div>
    )
  }

  private _renderFailure() {
    return (
      <div>
        <Grid
          container={true}
          style={{
            alignContent: 'center',
            alignItems: 'center',
            flex: 1,
            flexGrow: 1,
            justifyContent: 'center',
            margin: '80px auto',
            marginBottom: 0,
            maxWidth: '950px',
          }}
        >
          <Grid
            item={true}
            xs={12}
            style={{
              padding: '20px',
            }}
          >
            <Grid
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {this.state.noAgents ? (
                <div />
              ) : this.state.maxKeys && this.state.errorAgent ? (
                <div style={styles.errorMessage}>
                  Uh oh. <br />
                  Too many keys!
                </div>
              ) : this.state.checkPermissions ? (
                <div style={styles.errorMessage}>
                  Oh no. <br />
                  No access!
                </div>
              ) : null}
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginBottom: '25px',
                }}
              >
                <img src="/images/icons/errorbotcopy.png" width={200} />
              </div>
            </Grid>

            {this.state.noAgents ? (
              <div
                style={{
                  fontWeight: 'bold',
                  textAlign: 'center',
                  fontSize: '2.5em',
                  color: colors.darkGreyBlue,
                }}
              >
                Sorry, we couldn't find any Dialogflow agents.
              </div>
            ) : this.state.maxKeys && this.state.errorAgent ? (
              <div style={{ textAlign: 'center' }}>
                <div style={styles.errorMessageDetails}>
                  Please delete some keys on the service account for{' '}
                  {this.state.errorAgent}.
                </div>
              </div>
            ) : this.state.checkPermissions ? (
              <Grid style={{ textAlign: 'center' }}>
                <div style={styles.errorMessageDetails}>
                  Please check that you have owner or developer permissions for
                  a Dialogflow agent.
                  <br />
                  If the problem persists, create a fresh agent and sign up
                  again.
                  <Info
                    style={{
                      cursor: 'pointer',
                      color: colors.lightestGreyBlue,
                      margin: '0 0 10px 5px',
                    }}
                    onClick={(e) =>
                      this.setState({ checkPermissionsDialog: true })
                    }
                  />
                </div>
              </Grid>
            ) : null}
            <br />
          </Grid>
          {this.state.noAgents ? (
            <Button
              style={{
                backgroundColor: colors.purple,
                marginBottom: '20px',
              }}
            >
              <a
                style={{ color: colors.pureWhite }}
                href="https://dialogflow.com/"
                target="_blank"
              >
                Create Agent
              </a>
            </Button>
          ) : null}

          {this.state.maxKeys && this.state.errorAgent ? (
            <ExpansionPanel
              style={styles.expansionPanel}
              defaultExpanded={true}
            >
              <ExpansionPanelSummary
                style={styles.expansionPanelSummary}
                expandIcon={<ExpandMoreIcon style={{ color: '#F0F3F6' }} />}
              >
                <Typography
                  style={styles.subtitle}
                  onClick={(e) => this.setState({ maxKeysDialog: true })}
                >
                  How do I fix it?
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Grid
                  item={true}
                  xs={12}
                  style={{
                    textAlign: 'left',
                    fontWeight: 500,
                    fontSize: '1em',
                    color: colors.darkGreyBlue,
                    display: 'flex',

                    flexDirection: 'column',

                    padding: '10px',
                  }}
                >
                  <div style={{ padding: '25px' }}>
                    <div style={styles.stepHeader}> Step 1:</div>
                    <div style={styles.stepDetails}>
                      Go to your settings section within the desired agent.
                      Click the service account link to your Google Cloud.
                    </div>
                  </div>
                  <div>
                    <img
                      src={`${process.env.PUBLIC_URL}/images/errorSignup/step1.png`}
                      width="100%"
                    />
                  </div>
                  <div style={{ padding: '25px' }}>
                    <div style={styles.stepHeader}> Step 2:</div>
                    <div style={styles.stepDetails}>
                      Click 'edit' in the stacked dots icon to the right of the
                      desired service account.
                    </div>
                  </div>
                  <div>
                    <img
                      src={`${process.env.PUBLIC_URL}/images/errorSignup/step2.png`}
                      width="100%"
                    />
                  </div>
                  <div style={{ padding: '25px' }}>
                    <div style={styles.stepHeader}> Step 3:</div>
                    <div style={styles.stepDetails}>
                      Delete a key. (An unused key was likely generated from
                      Botcopy from your first signup)
                    </div>
                  </div>
                  <div>
                    <img
                      src={`${process.env.PUBLIC_URL}/images/errorSignup/step3.png`}
                      width="100%"
                    />
                  </div>
                  <div style={{ padding: '25px' }}>
                    <div style={styles.stepHeader}> Step 4:</div>
                    <div style={styles.stepDetails}>
                      Come on back and sign up again.
                    </div>
                  </div>
                </Grid>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          ) : this.state.checkPermissions ? (
            <ExpansionPanel
              style={styles.expansionPanel}
              defaultExpanded={true}
            >
              <ExpansionPanelSummary
                style={styles.expansionPanelSummary}
                expandIcon={<ExpandMoreIcon style={{ color: '#F0F3F6' }} />}
              >
                <Typography style={styles.subtitle}>
                  What does this mean?
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Grid
                  item={true}
                  xs={12}
                  style={{
                    textAlign: 'left',
                    fontWeight: 500,
                    fontSize: '1em',
                    color: colors.darkGreyBlue,
                    display: 'flex',

                    flexDirection: 'column',

                    padding: '10px',
                  }}
                >
                  <div style={{ padding: '20px' }}>
                    <div style={styles.stepHeader}>Add Developer Role:</div>
                    <div style={styles.stepDetails}>
                      Have the admin of your existing agents add you as a
                      developer in the share settings, or create a fresh agent
                      on your own.
                    </div>
                  </div>
                  <div>
                    <img
                      src={`${process.env.PUBLIC_URL}/images/errorSignup/DeveloperAccess.png`}
                      width="100%"
                    />
                  </div>
                </Grid>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          ) : null}
          {/* </Paper> */}
        </Grid>
        <Grid
          container={true}
          style={{
            alignContent: 'center',
            alignItems: 'center',
            flex: 1,
            justifyContent: 'center',
            textAlign: 'center',
          }}
        >
          <div style={{ marginTop: 10 }}>
            Have an account already?
            <Button>
              <Link to="/login" type="camelCase">
                Login
              </Link>
            </Button>
          </div>
        </Grid>

        {/* DIALOGS */}
        <Dialog
          open={this.state.checkPermissionsDialog}
          onClose={(e) => this._closeDialog()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <Grid
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <DialogTitle style={{ fontWeight: 650 }} id="alert-dialog-title">
              Dialogflow Permissions
            </DialogTitle>
          </Grid>
          <DialogContent>
            <DialogContentText
              style={{ fontWeight: 600 }}
              id="alert-dialog-description"
            >
              In order to display and link your Dialogflow agents, we use
              Google's oAuth2Client system to give us permissions to view your
              projects. We then try to create a Service Account on a project
              which you have write permissions on.
              <br />
              <br />
              This signup error most commonly occurs when the user has
              insufficient permissions on a Dialogflow agent in their console -
              for example, if the agent was shared with you and your role is a
              Reviewer.
              <br />
              <br />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              style={{ textTransform: 'none', color: '#1890ff' }}
              onClick={(e) => this._closeDialog()}
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }

  private _closeDialog = () => {
    if (this.state.checkPermissionsDialog) {
      this.setState((prevState) => ({
        ...prevState,
        checkPermissionsDialog: false,
      }))
    }
  }
}

export default withAuth0(Invite)
