import './connect.css'

import { each, isUndefined, map } from 'lodash-es'
import { inject, observer } from 'mobx-react'
import * as React from 'react'
import {
  MdCheckCircleOutline as CheckCircle,
  MdContentCopy,
  MdDeleteOutline as DeleteIcon,
  MdInfoOutline as InfoIcon,
  MdLabelOutline as LabelIcon,
  MdOutlineAccountCircle as AccountCircle,
  MdOutlineClear as ClearIcon,
  MdOutlineContentCopy,
  MdOutlineEject as EjectOutlineIcon,
  MdOutlineLanguage as LanguageIcon,
} from 'react-icons/md'
import BotcopyMonospace from 'src/components/BotcopyMonospace'
import trackUserEvent from 'src/components/trackEvents'
import { Roles } from 'src/models/users'
import {
  cxDataRegions,
  esDataRegions,
  IDataRegion,
} from 'src/utils/dataRegions'

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Card,
  Flex,
  FormControl,
  FormHelperText as ChakraFormHelperText,
  FormLabel,
  Grid as ChakraGrid,
  GridItem,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  Link,
  SimpleGrid,
  Switch,
  Text,
  Textarea,
  Tooltip,
  Wrap,
  WrapItem,
} from '@chakra-ui/react'
import {
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  Snackbar,
  SnackbarContent,
  StyleRulesCallback,
  TextField,
  withStyles,
} from '@material-ui/core'

import { getActiveProjects, getAgents, getCXAgents } from '../../api'
import SaveDetector, {
  ISaveDetectorFormElements,
  noop,
} from '../../components/saveDetector'
import { AppConfig } from '../../config'
import { colors } from '../../hocs/withTheme'
import { RootStore } from '../../models/root-store/root.store'
import {
  BotPlatform,
  IBot,
  IFetchAgentsResult,
  ILanguageObject,
} from '../../models/bots'
import { EventAction, EventName, Events } from '../../utils/gtm'
import { dialogflowLanguages } from '../../utils/languages'
import Logger from '../../utils/logger'
import CreditCardCapture from '../onboarding/CreditCardCapture'
import { CopyToClipboardButton } from '@botcopy/ui-shared'
import { RouteProps, withRouter } from 'src/utils/withRouter'

const widgetBaseURL = AppConfig.get('/WidgetHostname')

const { log, logError } = Logger('Connect')

export const FALLBACK_BOT_PROJECT_ID = 'user-name-e88b2'

const installedOnDomainRegexp = new RegExp(
  /^(https:\/\/\S+\.\S+|http:\/\/localhost)(?::\d+)?(?!\/)$/,
)
const installedOnExceptionURLRegexp = new RegExp(
  /^(https:\/\/\S+\.\S+|http:\/\/localhost)(?::\d+)?(\/\S*)?$/,
)

const divStyle = {
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: 'Open Sans, sans-serif',
    fontSize: '2em',
    color: colors.darkGreyBlue,
    padding: 20,
  },
  loading: {
    margin: '1em 0',
    color: colors.darkGreyBlue,
    textAlign: 'center' as 'center',
  },
  subtitle: {
    margin: '0.5em',
    marginBottom: '2em',
    fontWeight: 550,
    fontSize: '1.25em',
    color: colors.darkGreyBlue,
  },
  input: {
    font: 'Open Sans, sans-serif',
    width: '100%',
    color: colors.offWhite,
  },
  tooltip: {
    color: colors.darkGreyBlue,
    alignText: 'center',
    fontSize: '1em',
    fontWeight: 600,
  },
  // Snackbar
  snackbarGrid: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  snackbar: {
    backgroundColor: colors.green,
    color: colors.offWhite,
  },
  errorSnackbar: {
    backgroundColor: colors.errorRed,
    color: colors.offWhite,
  },
}

const styles: StyleRulesCallback = (theme) => ({
  root: {
    color: colors.offWhite,
    flexGrow: 1,
    margin: '80px auto',
    padding: '50px',
  },
  card: {
    backgroundColor: colors.purple,
    display: 'inline-block',
    margin: 20,
    flex: 1,
    width: 200,
  },
  paper: {
    padding: '32px',
    backgroundColor: colors.lightGreyScale200,
    color: colors.lightGreyScale1200,
    flex: 0.5,
  },
  defaultAgentPaper: {
    padding: theme.spacing.unit * 3.5,
    color: colors.darkGreyBlue,
    margin: '40px 18px 18px',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  accordion: {
    color: colors.darkGreyBlue,
    backgroundColor: colors.lightGreyScale200,
    width: '100%',
    margin: '0 18px',
  },
  accordionDetails: {
    flexDirection: 'column',
    padding: '0 30px 24px',
    borderBottom: `1px solid ${colors.lightGreyBlue}`,
  },
  title: {
    color: 'white',
  },
  stat: {
    color: 'white',
  },
  input: {
    font: 'Open Sans, sans-serif',
    width: '95%',
    color: colors.darkGreyBlue,
  },
  tabWrapper: {
    width: '100%',
  },
  // To style outlined mui text field
  focusVisible: {},
  cssFocused: {},
  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      borderColor: colors.darkGreyBlue,
    },
  },
  notchedOutline: {
    borderColor: colors.darkGreyBlue,
  },
})

const tooltipText = {
  botName: `Naming your bot will change the header label in the chat window.`,
  embedSnippet: `Copy the script below. Then, paste it in the <body> of the page you want the bot to render on.`,
  dashboardLabel: `Add a label to categorize your bot on the dashboard`,
  installedOnExceptions: `Accepted format: https://domain.com/, https://subdomain.domain.com/, https://domain.com/path`,
  installedOnRestricted: `Accepted format: https://domain.com, https://subdomain.domain.com, https://legacy-api.botcopy.org:3030`,
  dialogflowESEnvironment: `Enter a custom Dialogflow ES environment name. Remove it to use the default environment.`,
  dialogflowCXEnvironment: `Enter a Dialogflow CX environment ID. Once an environment is created, copy the name to clipboard and copy/paste the ID found at the end of the url. Example: projects/.../environments/1234-567-abcd. Enter 1234-567-abcd.  Remove it to use the default environment.`,
  gtmSnippet: `Copy the script below. Then, paste it in
    Google Tag Manager.`,
  noAgentAccess:
    // tslint:disable
    'Generally users see this error when they have deleted the Agent the bot is connected to in Dialogflow. Otherwise, you might not have been shared this Agent on Dialogflow.',
  // tslint:enable
}

interface IConnectProps extends RouteProps {
  classes: any
  store?: RootStore
}

interface IConnectState {
  activeProjects: any[]
  agentAccess: boolean
  agents: IFetchAgentsResult[] | undefined
  checkPermissionsDialog: boolean
  confirmDelete: boolean
  credentials?: any
  cxAgents: any[]
  deleteDomainDialog: boolean
  deleteURLExceptionDialog: boolean
  deletedDFAgentDialog: boolean
  disconnectBotDialog: boolean
  domainToDelete: string
  installedOnRestrictedDialog: boolean
  installedOnError: boolean
  installedOnExceptionsError: boolean
  embedSnippedTabActive: { general: boolean; gtm: boolean }
  errorAgent: string
  errorSnackbar: boolean
  filteredCxAgents: any[]
  filteredCxProjects: any[]
  languageSelectionDialog: boolean
  loadingAgents: boolean
  shouldLoadAgents: boolean
  loadingCXProjects: boolean
  maxKeysDialog: boolean
  noAgentsDialog: boolean
  savingAgent: boolean
  searchValue: string
  selectedIndex: number | undefined
  selectCXAgentDialog: boolean
  selectCXProjectDialog: boolean
  snackbarMessage: string
  snackbarLink: string
  snackbarLinkLabel: string
  successSnackbar: boolean
  urlToDelete: string
}

const getHasDefaultOrNoProjectId = (bot: IBot) => {
  return !bot.projectId || bot.projectId === FALLBACK_BOT_PROJECT_ID
}

@inject('store')
@observer
class Connect extends React.Component<IConnectProps, IConnectState> {
  private formElements: ISaveDetectorFormElements = {
    botName: {
      ref: React.createRef(),
      inputRef: React.createRef(),
      onKeyPress: async (e) => {
        if (e.key === 'Enter') {
          return this._changeName(e)
        }
        return true
      },
      onChange: noop,
    },
    dashboardLabel: {
      ref: React.createRef(),
      inputRef: React.createRef(),
      onKeyPress: async (e) => {
        if (e.key === 'Enter') {
          return this._changeDashboardLabel(e)
        }
        return true
      },
      onChange: noop,
    },
    dialogflowEnvironment: {
      ref: React.createRef(),
      inputRef: React.createRef(),
      onKeyPress: async (e) => {
        if (e.key === 'Enter') {
          return this._changeDialogflowEnvironment(e)
        }
        return true
      },
      onChange: noop,
    },
    installedOn: {
      ref: React.createRef(),
      inputRef: React.createRef(),
      onKeyPress: async (e) => {
        if (e.key === 'Enter') {
          const domain = e.target.value as string
          return this._editInstalledOn(domain, true)
        }
        return true
      },
      onChange: (e) => {
        const isValid = installedOnDomainRegexp.test(e.target.value)

        this.setState({
          installedOnError: !isValid,
        })
      },
    },
    installedOnException: {
      ref: React.createRef(),
      inputRef: React.createRef(),
      onKeyPress: async (e) => {
        if (e.key === 'Enter') {
          const url = e.target.value as string
          return this._editInstalledOnExceptions(url, true)
        }
        return true
      },
      onChange: (e) => {
        const isValid = installedOnExceptionURLRegexp.test(e.target.value)

        this.setState({
          installedOnExceptionsError: !isValid,
        })
      },
    },
  }

  constructor(props: IConnectProps) {
    super(props)
    this._fetchAgents = this._fetchAgents.bind(this)
    this._onFileChange = this._onFileChange.bind(this)
    this._onReaderLoad = this._onReaderLoad.bind(this)
    this._selectProject = this._selectProject.bind(this)
    this._setProjectId = this._setProjectId.bind(this)
    this._setServiceEmail = this._setServiceEmail.bind(this)
    this._renderConfiguration = this._renderConfiguration.bind(this)
    this._editInstalledOn = this._editInstalledOn.bind(this)
    this._editInstalledOnExceptions = this._editInstalledOnExceptions.bind(this)
    this._changeName = this._changeName.bind(this)
    this.state = {
      activeProjects: [],
      agentAccess: false,
      agents: undefined,
      checkPermissionsDialog: false,
      confirmDelete: false,
      credentials: undefined,
      cxAgents: [],
      deleteDomainDialog: false,
      deleteURLExceptionDialog: false,
      deletedDFAgentDialog: false,
      disconnectBotDialog: false,
      domainToDelete: '',
      installedOnRestrictedDialog: false,
      installedOnError: false,
      installedOnExceptionsError: false,
      embedSnippedTabActive: { general: true, gtm: false },
      errorAgent: '',
      errorSnackbar: false,
      filteredCxAgents: [],
      filteredCxProjects: [],
      languageSelectionDialog: false,
      loadingAgents: false,
      shouldLoadAgents: true,
      loadingCXProjects: false,
      maxKeysDialog: false,
      noAgentsDialog: false,
      savingAgent: false,
      searchValue: '',
      selectedIndex: 0,
      selectCXAgentDialog: false,
      selectCXProjectDialog: false,
      snackbarMessage: '',
      snackbarLink: '',
      snackbarLinkLabel: '',
      successSnackbar: false,
      urlToDelete: '',
    }
  }

  public async componentDidMount() {
    const store = this.props.store

    // if (org && org.onboarding.step === 11) {
    //   const dataLayer = {
    //     event: Events.onboarding.viewConnect.type,
    //     eventName: Events.onboarding.viewConnect.eventName,
    //     eventCode: Events.onboarding.viewConnect.eventCode,
    //   }
    //   trackUserEvent(EventName.PortalView, dataLayer)
    //   this.props.store?.session.setNavDrawerOpen(true)
    // }

    if (
      !this.state.agents &&
      !this.state.loadingAgents &&
      store?.bots.currentBot
    ) {
      this._fetchAgents(store?.bots.currentBot)
    }
  }

  public componentWillUnmount() {
    this.props.store?.bots.setCurrentBotId(undefined)
  }

  public async componentDidUpdate(prevProps: IConnectProps) {
    const { store } = this.props
    if (
      !this.state.agents &&
      !this.state.loadingAgents &&
      store?.bots.currentBot
    ) {
      this._fetchAgents(store?.bots.currentBot)
    }
  }

  public render() {
    const {
      store,
      params: { botId },
    } = this.props

    const currentBot = store?.bots.currentBot
    if (this.props.store?.bots.currentBotId !== botId) {
      store?.bots.setCurrentBotId(botId)
      return null
    }

    if (currentBot) {
      return this._renderConfiguration(currentBot)
    }
    return (
      <div style={{ padding: 50, color: colors.darkGreyBlue }}>Loading...</div>
    ) // TODO show a better loading screen
  }

  private _renderConfiguration(bot: IBot) {
    const { classes, store } = this.props
    const me = store?.users.me
    const org = store?.organizations.current
    const { name, installedOnRestricted } = bot

    const embedSnippet = `<script type="text/javascript"
    id="botcopy-embedder-d7lcfheammjct"
    class="botcopy-embedder-d7lcfheammjct" 
    data-botId="${bot._id}"
>
    var s = document.createElement('script'); 
    s.type = 'text/javascript'; s.async = true; 
    s.src = '${widgetBaseURL}/js/injection.js'; 
    document.getElementById('botcopy-embedder-d7lcfheammjct').appendChild(s);
</script>`
    const gtmSnippet = `<div
id="botcopy-embedder-d7lcfheammjct"
class="botcopy-embedder-d7lcfheammjct" 
data-botId="${bot._id}"
>
  <script type="text/javascript">
    var s = document.createElement('script'); 
    s.type = 'text/javascript'; s.async = true; 
    s.src = '${widgetBaseURL}/js/injection.js'; 
    document.getElementById('botcopy-embedder-d7lcfheammjct').appendChild(s);
  </script>
</div>`

    const hasDefaultOrNoProjectId =
      !bot.projectId || bot.projectId === FALLBACK_BOT_PROJECT_ID

    if (store?.bots.currentBot && me && org) {
      return (
        <div className="connect-page-outer-grid">
          {org.notifications.overageFreeGrace >= 5 &&
          org.balancesEngagementsTotal < org.plan.conversationLimit ? (
            <div className="onhold-banner">
              <Box display={'flex'} flexDirection="column">
                <h1 className="onhold-banner-title">
                  Your free trial with Botcopy is up!
                </h1>
                <h3 className="onhold-banner-subtitle">
                  Upgrade now to one of our cost saving plans.
                </h3>
              </Box>
              <Box display={'flex'} flexDirection="column">
                <a
                  className="onhold-plan-button"
                  href={`${process.env.PUBLIC_URL}/account?showPlans=true`}
                >
                  Upgrade Plan
                </a>
              </Box>
            </div>
          ) : null}
          <CreditCardCapture classes={{}} store={store} />
          <Grid
            container={true}
            direction="column"
            justify="center"
            alignContent="center"
            style={{
              padding: '20px',
              marginLeft: 'auto',
              marginRight: 'auto',
              maxWidth: '1105px',
              minHeight: '200',
            }}
          >
            <Text textStyle="h5" fontSize="28px" my="18px">
              Connect
            </Text>
            {/* No project selected */}
            {hasDefaultOrNoProjectId &&
              (this.state.loadingAgents ? (
                <Card className={classes.defaultAgentPaper}>
                  <div style={divStyle.loading}>
                    <CircularProgress color={'inherit'} />
                    <br />
                    <br />
                    Loading your agents...
                    <br />
                    If you have a lot of agents, this might take a minute.
                  </div>
                </Card>
              ) : (
                <Card bg={colors.lightGreyScale200} p={8}>
                  <div className="connect-paper-title">
                    <Flex justify="space-between" alignItems="center">
                      <Text textStyle="subtitle1">Dialogflow -&gt; Bot</Text>
                      <span title="Delete Bot">
                        <DeleteIcon
                          style={{ cursor: 'pointer' }}
                          size="24px"
                          onClick={this._confirmDelete}
                        />
                      </span>
                    </Flex>
                  </div>
                  <Text textStyle="body2" className="paper-subtitle" my={4}>
                    Select a region and an agent to generate your bot's snippet
                    and set up integrations.
                  </Text>
                  {/* Select Agent */}
                  {this._renderDataRegionSelect(bot)}
                  <br />
                  {this._renderSelectAgentFormControl(bot)}
                </Card>
              ))}

            {/* Step 1 and 2 Container Grid */}

            {hasDefaultOrNoProjectId ? null : (
              <Wrap spacing={6} w="100%">
                <WrapItem flex={1}>
                  <Card
                    className="connect-card"
                    bg={colors.lightGreyScale200}
                    p={8}
                    flex={1}
                    border="1px solid"
                    borderColor={colors.lightGreyScale800}
                  >
                    {!hasDefaultOrNoProjectId && (
                      <Box>
                        <Grid
                          container={true}
                          justify="space-between"
                          alignItems="center"
                        >
                          <Text textStyle="h5" fontSize="21px">
                            Agent Details
                          </Text>
                          <div>
                            <Grid
                              container={true}
                              justify="space-between"
                              alignItems="center"
                            >
                              <span title="Toggle Bot Online or Offline">
                                <FormControl>
                                  <HStack>
                                    <Switch
                                      isChecked={bot.active}
                                      onChange={this._toggleActive}
                                    />
                                    <FormLabel textStyle="caption">
                                      {bot.active ? 'Online' : 'Offline'}
                                    </FormLabel>
                                  </HStack>
                                </FormControl>
                              </span>
                              <Flex title="Eject Dialogflow Agent" mx={4}>
                                <EjectOutlineIcon
                                  size="24px"
                                  cursor="pointer"
                                  onClick={this._confirmDisconnect}
                                />
                              </Flex>
                              <span title="Delete Bot">
                                <DeleteIcon
                                  cursor="pointer"
                                  size="24px"
                                  onClick={this._confirmDelete}
                                />
                              </span>
                            </Grid>
                          </div>
                        </Grid>
                        <Box my={4}>
                          <BotcopyMonospace
                            text={`Region: ${bot.dialogflow.location}`}
                          />
                        </Box>
                        <Grid className="bot-details-grid">
                          {/* Select Agent */}
                          {this._renderSelectAgentFormControl(bot)}
                        </Grid>
                        <Text textStyle="h5" fontSize="21px">
                          Bot Details
                        </Text>
                        <div className="bot-details-fields-grid">
                          <FormControl my={2}>
                            <FormLabel
                              as={Text}
                              textStyle="overline"
                              casing="uppercase"
                            >
                              Bot Name
                            </FormLabel>

                            <InputGroup>
                              <InputLeftElement
                                color={colors.lightGreyScale1100}
                                m="4px 0 0 5px"
                              >
                                <InputAdornment position="start">
                                  <Tooltip
                                    label={tooltipText.botName}
                                    placement={'top'}
                                    padding={5}
                                  >
                                    <span>
                                      <AccountCircle size="24px" />
                                    </span>
                                  </Tooltip>
                                </InputAdornment>
                              </InputLeftElement>
                              <Input
                                placeholder="Jarvis"
                                variant="outline"
                                defaultValue={name}
                                ref={this.formElements.botName.inputRef}
                                onKeyPress={
                                  this.formElements.botName.onKeyPress
                                }
                                onChange={(e) =>
                                  this.formElements.botName.onChange(e)
                                }
                                minW="300px"
                                size="lg"
                                color={colors.lightGreyScale1200}
                                border="1px solid"
                                borderColor={colors.lightGreyScale800}
                                autoFocus={!name}
                              />
                            </InputGroup>
                          </FormControl>

                          {/* bot info - dashboard label */}

                          <FormControl mt={4} mb={4}>
                            <FormLabel
                              as={Text}
                              textStyle="overline"
                              casing="uppercase"
                            >
                              Dashboard Label (Optional)
                            </FormLabel>

                            <InputGroup>
                              <InputLeftElement
                                color={colors.lightGreyScale1100}
                                m="4px 0 0 5px"
                              >
                                <InputAdornment position="start">
                                  <Tooltip
                                    label={tooltipText.dashboardLabel}
                                    placement={'top'}
                                    padding={5}
                                  >
                                    <span>
                                      <LabelIcon size="24px" />
                                    </span>
                                  </Tooltip>
                                </InputAdornment>
                              </InputLeftElement>
                              <Input
                                placeholder="Sales Bot"
                                variant="outline"
                                defaultValue={bot.dashboardLabel}
                                ref={this.formElements.dashboardLabel.inputRef}
                                onKeyPress={
                                  this.formElements.dashboardLabel.onKeyPress
                                }
                                onChange={(e) =>
                                  this.formElements.dashboardLabel.onChange(e)
                                }
                                minW="300px"
                                size="lg"
                                color={colors.lightGreyScale1200}
                                border="1px solid"
                                borderColor={colors.lightGreyScale800}
                                autoFocus={!name}
                              />
                            </InputGroup>
                          </FormControl>
                          <CopyToClipboardButton
                            label="Copy Bot ID to Clipboard"
                            icon={MdOutlineContentCopy}
                            toastTitle="Copied"
                            toastDescription={(value) => bot._id}
                            newClipboardValue={bot._id}
                          />
                        </div>
                      </Box>
                    )}
                  </Card>
                </WrapItem>
                {hasDefaultOrNoProjectId ? null : (
                  <WrapItem flex={1}>
                    <Card
                      className="connect-card"
                      bg={colors.lightGreyScale200}
                      border="1px solid"
                      borderColor={colors.lightGreyScale800}
                      flex={1}
                      h="100%"
                    >
                      <Grid
                        container={true}
                        style={{
                          marginBottom: '2em',
                        }}
                      >
                        <HStack>
                          <Text textStyle="h5" fontSize="21px">
                            Embed Snippet
                          </Text>
                          <Tooltip
                            label={
                              this.state.embedSnippedTabActive.general
                                ? tooltipText.embedSnippet
                                : tooltipText.gtmSnippet
                            }
                            placement={'top'}
                            padding={5}
                          >
                            <Box color={colors.lightGreyScale1100}>
                              <InfoIcon size="20px" color="inherit" />
                            </Box>
                          </Tooltip>
                        </HStack>
                      </Grid>
                      <Grid
                        id="Items Grid"
                        item={true}
                        direction="column"
                        alignItems="center"
                      >
                        <div className={classes.tabWrapper}>
                          <HStack justify="space-between">
                            <div className="tabs">
                              <Button
                                textStyle="caption"
                                fontSize="11px"
                                className={`generalTab ${
                                  !this.state.embedSnippedTabActive.general
                                    ? 'inactive'
                                    : ''
                                }`}
                                mr="2"
                                onClick={this.snippetTab1Clicked}
                              >
                                General
                              </Button>
                              <Button
                                textStyle="caption"
                                fontSize="11px"
                                className={`gtmTab ${
                                  !this.state.embedSnippedTabActive.gtm
                                    ? 'inactive'
                                    : ''
                                }`}
                                onClick={this.snippetTab2Clicked}
                              >
                                Google Tag Manager
                              </Button>
                            </div>
                            <Box cursor="pointer" onClick={this._copySnippet}>
                              <MdContentCopy size="18px" />
                            </Box>
                          </HStack>
                          {/* Tab Content */}
                          <Textarea
                            id="textarea-snippet"
                            fontFamily="Cascadia Mono, Menlo, Monaco, monospace"
                            className={`text-area-snippet ${
                              org.notifications.overageFreeGrace >= 5 &&
                              org.balancesEngagementsTotal <
                                org.plan.conversationLimit &&
                              !bot.active
                                ? 'blur'
                                : ''
                            }`}
                            minH="350px"
                            size="sm"
                            bg={colors.pureWhite}
                            isReadOnly={true}
                            value={
                              this.state.embedSnippedTabActive.general
                                ? embedSnippet
                                : gtmSnippet
                            }
                          />
                        </div>
                      </Grid>
                    </Card>
                  </WrapItem>
                )}
              </Wrap>
            )}
            {hasDefaultOrNoProjectId ? null : (
              <Grid container={true} className="container-grid-connect">
                <ChakraGrid h="100%" w="100%" templateColumns="repeat(2, 1fr)">
                  <GridItem colSpan={4} rowSpan={1}>
                    <Accordion
                      as={Card}
                      bg={colors.lightGreyScale100}
                      allowToggle={true}
                      maxW="1214px"
                      border="1px solid"
                      borderColor={colors.lightGreyScale800}
                    >
                      <AccordionItem>
                        <h2>
                          <AccordionButton
                            borderRadius={6}
                            border="none"
                            p={8}
                            h="auto"
                          >
                            <Box as="span" flex="1" textAlign="left">
                              <HStack>
                                <Text
                                  textStyle="h5"
                                  fontSize="21px"
                                  whiteSpace="nowrap"
                                >
                                  Domain Allowlist
                                </Text>
                                <FormControl>
                                  <HStack>
                                    <Switch
                                      isDisabled={me.roles.includes(
                                        Roles.MARKETER,
                                      )}
                                      isChecked={installedOnRestricted}
                                      onChange={
                                        this._toggleInstalledOnRestrictedDialog
                                      }
                                      ml={2}
                                    />
                                    <FormLabel>Enable Allowlist</FormLabel>
                                  </HStack>
                                </FormControl>
                              </HStack>
                            </Box>
                            <AccordionIcon />
                          </AccordionButton>
                        </h2>

                        <AccordionPanel p={8} pt={0}>
                          <Grid item={true} xs={12}>
                            <Grid container={true} direction="column">
                              <Text textStyle="subtitle2" my={4}>
                                Specify the trusted domains you allow your bot
                                to appear on
                              </Text>

                              <Grid container={true} direction="column">
                                {/* Domain Allowlist Field  */}
                                <FormControl mb={6}>
                                  <FormLabel
                                    as={Text}
                                    textStyle="overline"
                                    casing="uppercase"
                                  >
                                    Domain
                                  </FormLabel>
                                  <Input
                                    placeholder="https://website.com"
                                    isInvalid={this.state.installedOnError}
                                    variant="outline"
                                    ref={this.formElements.installedOn.inputRef}
                                    onKeyPress={
                                      this.formElements.installedOn.onKeyPress
                                    }
                                    onChange={
                                      this.formElements.installedOn.onChange
                                    }
                                    w="inherit"
                                    color={colors.lightGreyScale1200}
                                    border="1px solid"
                                    borderColor={colors.lightGreyScale800}
                                  />

                                  <ChakraFormHelperText mt={2}>
                                    {tooltipText.installedOnRestricted}
                                  </ChakraFormHelperText>
                                </FormControl>

                                {/*Domain Allowlist */}
                                <Text textStyle="overline">
                                  Allowed Domains:
                                </Text>
                                <Grid
                                  container={true}
                                  className="allowed-domain-bubble-grid"
                                >
                                  {map(
                                    bot.installedOn,
                                    (installedOn: string, i: number) => {
                                      return (
                                        <Button
                                          key={i}
                                          textStyle="caption"
                                          className="allowed-domain-bubble"
                                          onClick={async () => {
                                            this.setState({
                                              deleteDomainDialog: true,
                                              domainToDelete: installedOn,
                                            })
                                          }}
                                        >
                                          {installedOn}
                                          <ClearIcon />
                                        </Button>
                                      )
                                    },
                                  )}
                                </Grid>
                              </Grid>
                              {/* Exceptions to allowlist  */}
                              <br />

                              <Text textStyle="subtitle2" my={4}>
                                Exceptions - Block access to specific URLs
                              </Text>
                              <Grid container={true} direction="column">
                                <FormControl mb={6}>
                                  <FormLabel
                                    as={Text}
                                    textStyle="overline"
                                    casing="uppercase"
                                  >
                                    URL
                                  </FormLabel>
                                  <Input
                                    placeholder="https://website.com/path"
                                    isInvalid={this.state.installedOnError}
                                    variant="outline"
                                    ref={
                                      this.formElements.installedOnException
                                        .inputRef
                                    }
                                    onKeyPress={
                                      this.formElements.installedOnException
                                        .onKeyPress
                                    }
                                    onChange={
                                      this.formElements.installedOnException
                                        .onChange
                                    }
                                    w="inherit"
                                    color={colors.lightGreyScale1200}
                                    border="1px solid"
                                    borderColor={colors.lightGreyScale800}
                                  />

                                  <ChakraFormHelperText mt={2}>
                                    {tooltipText.installedOnExceptions}
                                  </ChakraFormHelperText>
                                </FormControl>

                                {/*URL Blocklist */}
                                <Text textStyle="overline">Blocked URLs:</Text>
                                <Grid
                                  container={true}
                                  className="allowed-domain-bubble-grid"
                                >
                                  {map(
                                    bot.installedOnExceptions,
                                    (
                                      installedOnException: string,
                                      i: number,
                                    ) => {
                                      return (
                                        <Button
                                          key={i}
                                          textStyle="caption"
                                          className="exception-domain-bubble"
                                          onClick={async () => {
                                            this.setState({
                                              deleteURLExceptionDialog: true,
                                              urlToDelete: installedOnException,
                                            })
                                          }}
                                        >
                                          {installedOnException}
                                          <ClearIcon />
                                        </Button>
                                      )
                                    },
                                  )}
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </AccordionPanel>
                      </AccordionItem>
                    </Accordion>
                  </GridItem>
                  <GridItem colSpan={4} rowSpan={1}>
                    <Accordion
                      as={Card}
                      bg={colors.lightGreyScale100}
                      allowToggle={true}
                      maxW="1214px"
                      border="1px solid"
                      borderColor={colors.lightGreyScale800}
                    >
                      <AccordionItem>
                        <h2>
                          <AccordionButton
                            borderRadius={6}
                            border="none"
                            p={8}
                            h="auto"
                          >
                            <Box as="span" flex="1" textAlign="left">
                              <Text textStyle="h5" fontSize="21px">
                                Language Selection
                              </Text>
                            </Box>
                            <AccordionIcon />
                          </AccordionButton>
                        </h2>

                        <AccordionPanel p={8} pt={0}>
                          <Grid item={true} xs={12}>
                            {bot.languageSelection.active ? (
                              <Grid container={true} direction="column">
                                <Text textStyle="body2" mb={4}>
                                  Language selection is enabled. Your users will
                                  be prompted to choose the language your bot
                                  responds in.
                                </Text>
                                <Flex>
                                  <Button
                                    className="connect-edit-button"
                                    onClick={(e) =>
                                      this.setState({
                                        languageSelectionDialog: true,
                                      })
                                    }
                                    disabled={me.roles.includes(Roles.MARKETER)}
                                    mr={2}
                                  >
                                    Edit Languages
                                  </Button>
                                  <Button
                                    variant="light"
                                    onClick={this._disableLanguageSelection}
                                    disabled={me.roles.includes(Roles.MARKETER)}
                                  >
                                    Disable Language Selection
                                  </Button>
                                </Flex>
                              </Grid>
                            ) : (
                              <div>
                                <Text textStyle="body2" my={4}>
                                  By default, language selection is off. Your
                                  bot detects a user’s preferred browser
                                  language and sends it with a Dialogflow query.
                                  If your Dialogflow agent supports the user’s
                                  browser language, responses will appear in
                                  that language.
                                  <br />
                                  <br />
                                  However, if you enable Language Selection,
                                  your end-users will see that your bot has
                                  other languages available. A dropdown menu in
                                  the chat window makes it easy for end-users to
                                  select their preferred language.
                                </Text>
                                <Button onClick={this._enableLanguageSelection}>
                                  Enable Language Selection
                                </Button>
                              </div>
                            )}
                          </Grid>
                        </AccordionPanel>
                      </AccordionItem>
                    </Accordion>
                  </GridItem>
                </ChakraGrid>
              </Grid>
            )}
          </Grid>
          {/* DIALOGS AND SNACKBARS */}
          {/* DELETE BOT */}
          <Dialog
            open={this.state.confirmDelete}
            onClose={this._closeDialog}
            aria-labelledby="alert-dialog-title"
            maxWidth="lg"
          >
            <DialogTitle id="alert-dialog-title">
              Are you sure you want to permanently delete {name}?
            </DialogTitle>
            <DialogContent>
              <Text textStyle="body2">
                This action cannot be undone. All data associated with this bot
                will be lost.
              </Text>
            </DialogContent>
            <DialogActions>
              <Button variant="light" onClick={this._closeDialog}>
                Cancel
              </Button>
              <Button onClick={this._deleteBot}>Yes</Button>
            </DialogActions>
          </Dialog>
          {/* DISCONNECT AGENT */}
          <Dialog
            open={this.state.disconnectBotDialog}
            onClose={this._closeDialog}
            aria-labelledby="alert-dialog-title"
          >
            <DialogTitle id="alert-dialog-title">
              <Text textStyle="subtitle1">
                Disconnect your bot from Dialogflow
              </Text>
            </DialogTitle>
            <DialogContent>
              <Text textStyle="body2">
                This action will disconnect {name} from the agent associated
                with {store.bots.currentBot.projectId}, requiring you to
                reconnect it to an agent again.
                <br />
                <br />
                If you want to temporarily <strong>deactivate</strong> the bot,
                press cancel and use the switch.
              </Text>
            </DialogContent>
            <DialogActions>
              <Button variant="light" onClick={this._closeDialog}>
                Cancel
              </Button>
              <Button onClick={() => this._disconnectBot()}>Confirm</Button>
            </DialogActions>
          </Dialog>

          {/* Toggle Domain Allowlist Dialog */}
          <Dialog
            open={this.state.installedOnRestrictedDialog}
            onClose={this._closeDialog}
            aria-labelledby="alert-dialog-title"
          >
            <DialogTitle id="alert-dialog-title">
              {installedOnRestricted
                ? 'Disable Domain Allowlist'
                : 'Enable Domain Allowlist'}
            </DialogTitle>
            <DialogContent>
              <span className="domain-text">
                {installedOnRestricted
                  ? 'Warning: This will allow your bot to use any domain.'
                  : 'Warning: This is potentially a destructive action. Any domain not in the allowlist will be blocked from loading this bot.'}
              </span>
            </DialogContent>
            <DialogActions>
              <Button variant="light" onClick={this._closeDialog}>
                Cancel
              </Button>
              <Button onClick={() => this._toggleInstalledOnRestricted()}>
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
          {/* Delete Domain from Allowlist  */}
          <Dialog
            open={this.state.deleteDomainDialog}
            onClose={this._closeDialog}
            aria-labelledby="alert-dialog-title"
          >
            <DialogTitle id="alert-dialog-title">Delete Domain</DialogTitle>
            <DialogContent>
              <span className="dialog-text">
                Are you sure you want to delete the following domain:
              </span>
              <div className="domain-text">{this.state.domainToDelete}</div>
              <br />
              <span>
                Warning: This is a destructive action. Deleting a domain will
                stop your bot from loading on that domain.
              </span>
            </DialogContent>
            <DialogActions>
              <Button variant="light" onClick={this._closeDialog}>
                Cancel
              </Button>
              <Button
                onClick={async () => {
                  this._editInstalledOn(this.state.domainToDelete, false)
                }}
              >
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
          {/* Delete URL from Allowlist Exceptions */}
          <Dialog
            open={this.state.deleteURLExceptionDialog}
            onClose={this._closeDialog}
            aria-labelledby="alert-dialog-title"
          >
            <DialogTitle id="alert-dialog-title">
              Delete URL Exception
            </DialogTitle>
            <DialogContent>
              <span className="dialog-text">
                Are you sure you want to delete the following URL:
              </span>
              <div className="domain-text">{this.state.urlToDelete}</div>
              <br />
              <span>
                Warning: This is a destructive action. Deleting a URL will allow
                your bot to load on that URL.
              </span>
            </DialogContent>
            <DialogActions>
              <Button variant="light" onClick={this._closeDialog}>
                Cancel
              </Button>
              <Button
                onClick={async () => {
                  this._editInstalledOnExceptions(this.state.urlToDelete, false)
                }}
              >
                Confirm
              </Button>
            </DialogActions>
          </Dialog>

          {/* CHECK PERMISSIONS */}
          <Dialog
            open={this.state.checkPermissionsDialog}
            onClose={this._closeDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{
              style: {
                backgroundColor: colors.darkGreyBlue,
              },
            }}
          >
            <DialogTitle id="alert-dialog-title">
              <div
                style={{
                  fontWeight: 600,
                  color: colors.offWhite,
                }}
              >
                Missing Role
              </div>
            </DialogTitle>

            <DialogContent className="permissions-dialog-content">
              {
                // tslint:disable
              }
              In order to connect to: <strong>{this.state.errorAgent}</strong>
              <br />
              <br />
              <strong>{me.email}</strong> must have an{' '}
              <strong>Owner role</strong> in the project's IAM policy in the{' '}
              <a
                href="https://console.cloud.google.com/home/dashboard"
                target="_blank"
              >
                Google Cloud Console
              </a>
              .
              <br />
              <br />
              This is required so we can create a service account on the
              project. If the problem persists, reach out to our support team.
              {
                // tslint:enable
              }
            </DialogContent>
            <DialogActions>
              <Button
                variant="light"
                onClick={() => {
                  window.open('https://calendly.com/aseegers/15min-1', '_blank')
                }}
              >
                Schedule a call
              </Button>
              <Button onClick={this._closeDialog}>Close</Button>
            </DialogActions>
          </Dialog>
          {/* MAX KEYS DIALOG */}
          <Dialog
            open={this.state.maxKeysDialog}
            onClose={this._closeDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{
              style: {
                backgroundColor: colors.darkGreyBlue,
              },
            }}
          >
            <DialogTitle id="alert-dialog-title">
              <div
                style={{
                  fontWeight: 600,
                  color: colors.offWhite,
                }}
              >
                Please delete some keys for {this.state.errorAgent}.
              </div>
            </DialogTitle>

            <DialogContent>
              <DialogContentText
                id="alert-dialog-description"
                style={{
                  fontWeight: 550,
                  color: colors.offWhite,
                }}
              >
                Google Cloud Project limits the number of keys on a service
                account to 10. Looks like you're at that limit! Please delete
                some keys to connect this bot.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this._closeDialog}>Close</Button>
            </DialogActions>
          </Dialog>
          {/* MAX KEYS DIALOG */}
          <Dialog
            open={this.state.noAgentsDialog}
            onClose={this._closeDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <Grid
              style={{
                display: 'flex',
              }}
            >
              <DialogTitle id="alert-dialog-title">
                <Text textStyle="subtitle1">No Agents Found!</Text>
              </DialogTitle>
            </Grid>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <Text color={colors.lightGreyScale1200} textStyle="body2">
                  We couldn't find any Dialogflow agents tied to {me.email} in
                  the {bot.dialogflow.location} data region. Please follow{' '}
                  <Link
                    href="https://dialogflow.cloud.google.com"
                    isExternal={true}
                    p={0}
                  >
                    this link
                  </Link>{' '}
                  and create an agent to continue using Botcopy.
                </Text>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this._closeDialog}>Close</Button>
            </DialogActions>
          </Dialog>
          {/* DELETED DIALOGFLOW AGENT DIALOG */}
          <Dialog
            open={this.state.deletedDFAgentDialog}
            onClose={this._closeDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{
              style: {
                backgroundColor: colors.darkGreyBlue,
              },
            }}
          >
            <Grid
              style={{
                display: 'flex',
              }}
            >
              <DialogTitle id="alert-dialog-title">
                <div
                  style={{
                    fontWeight: 600,
                    color: colors.offWhite,
                  }}
                >
                  Uh Oh!
                </div>
              </DialogTitle>
            </Grid>
            <DialogContent>
              <DialogContentText
                id="alert-dialog-description"
                style={{
                  fontWeight: 550,
                  color: colors.offWhite,
                }}
              >
                It appears you might have deleted your Dialogflow Agent:{' '}
                {store.bots.currentBot.projectId}. Please select a new agent
                from the drop down.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this._closeDialog}>Close</Button>
            </DialogActions>
          </Dialog>
          {/* LANGUAGE SELECTION DIALOG */}
          <Dialog
            open={this.state.languageSelectionDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="xl"
          >
            <HStack justify="space-between" p={8}>
              <Text textStyle="subtitle1">Select Languages</Text>
              <ClearIcon
                style={{ cursor: 'pointer' }}
                size="24px"
                onClick={(e) =>
                  this.setState({ languageSelectionDialog: false })
                }
              />
            </HStack>

            <DialogContent
              style={{
                padding: '0 32px',
                borderTop: `1px solid ${colors.lightGreyScale800}`,
              }}
            >
              <DialogContentText id="alert-dialog-description">
                <Text
                  textStyle="body2"
                  color={colors.lightGreyScale1200}
                  mt={8}
                  mb={6}
                >
                  Choose only the languages you've configured on your Dialogflow
                  agent. These languages will appear as options for your user in
                  the drop down menu.
                </Text>
              </DialogContentText>

              <FormControl>
                <FormGroup
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    overflowY: 'auto',
                    width: '100%',
                    height: '350px',
                  }}
                >
                  <SimpleGrid columns={[1, 2, null, 3]} spacing={4}>
                    {map(
                      dialogflowLanguages,
                      (languageObject: ILanguageObject, index: number) => (
                        <Box
                          key={index}
                          border={`1px solid ${colors.lightGreyScale800}`}
                          borderRadius="4px"
                          minW="300px"
                          // width="300px"
                          py={6}
                          px={4}
                        >
                          <Flex justify="space-between">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  disableRipple={true}
                                  checked={
                                    bot.languageSelection.languages.filter(
                                      (e) => e.code === languageObject.code,
                                    ).length > 0
                                  }
                                  onChange={(e: any) =>
                                    this._handleLanguageSelection(
                                      e,
                                      languageObject,
                                    )
                                  }
                                />
                              }
                              label={
                                <>
                                  <Text textStyle="overline">
                                    {`${languageObject.name}`}
                                  </Text>
                                  <Text textStyle="caption">
                                    {`${languageObject.code}`}
                                  </Text>
                                </>
                              }
                            />
                            {bot.languageSelection.languages.filter(
                              (e) => e.code === languageObject.code,
                            ).length > 0 ? (
                              <FormControlLabel
                                hidden={
                                  bot.languageSelection.languages.filter(
                                    (e) => e.default,
                                  ).length > 0 &&
                                  !bot.languageSelection.languages.filter(
                                    (e) => e.code === languageObject.code,
                                  )[0].default
                                }
                                control={
                                  <Switch
                                    checked={
                                      bot.languageSelection.languages.filter(
                                        (e) => e.code === languageObject.code,
                                      )[0].default
                                    }
                                    onChange={(e: any) =>
                                      this._handleDefaultLanguage(
                                        e,
                                        languageObject,
                                      )
                                    }
                                  />
                                }
                                label={
                                  <Text textStyle="overline" ml={2}>
                                    Default
                                  </Text>
                                }
                              />
                            ) : null}
                          </Flex>
                        </Box>
                      ),
                    )}
                  </SimpleGrid>
                </FormGroup>
              </FormControl>
            </DialogContent>
            <DialogActions style={{ padding: '32px' }}>
              <Button
                variant="light"
                onClick={(e) =>
                  this.setState({ languageSelectionDialog: false })
                }
              >
                Cancel
              </Button>
              <Button onClick={this._submitLanguageSelection}>Submit</Button>
            </DialogActions>
          </Dialog>
          {/* Active Projects Dialog */}
          <Dialog
            open={this.state.selectCXProjectDialog}
            onClose={this._closeDialog}
            maxWidth="sm"
          >
            <div className="cx-dialog-title">
              {this.state.loadingCXProjects ? (
                ''
              ) : (
                <div>
                  Your Projects
                  <div className="cx-dialog-subtitle">
                    Select the project that hosts your Dialogflow CX agent
                  </div>
                  <Input
                    mt={5}
                    type="text"
                    value={this.state.searchValue}
                    onChange={this.handleCxProjectSearch}
                    placeholder="Search projects..."
                  />
                </div>
              )}
            </div>

            <DialogContent>
              {this.state.loadingCXProjects ? (
                <div className="loading-paper">
                  <div className="loading">Checking your project roles</div>
                  <div className="loading-description">
                    <strong>{me.email}</strong> must have an{' '}
                    <strong>Owner role</strong> on the Google Cloud Project that
                    hosts the CX Agent.
                  </div>
                  <br />
                  <br />
                </div>
              ) : (
                map(
                  this.state.searchValue
                    ? this.state.filteredCxProjects
                    : this.state.activeProjects,
                  (activeProject: any, index: number) => {
                    return (
                      <div key={index} className="project-tile">
                        <div className="project-text-grid">
                          <div className="project-id">
                            {activeProject.project.projectId}
                          </div>
                          {activeProject.access ? null : (
                            <div className="owner-required-subtext">
                              An owner role is required to connect.
                            </div>
                          )}
                        </div>
                        <Button
                          disabled={!activeProject.access}
                          onClick={() =>
                            this.getCXAgents(
                              activeProject.project.projectId,
                              bot._id,
                            )
                          }
                          style={
                            activeProject.access
                              ? {}
                              : {
                                  opacity: 0.5,
                                }
                          }
                        >
                          Select
                        </Button>
                      </div>
                    )
                  },
                )
              )}
            </DialogContent>
          </Dialog>
          {/* CX Agents Dialog */}
          <Dialog
            open={this.state.selectCXAgentDialog}
            onClose={this._closeDialog}
            disableBackdropClick={true}
            disableEscapeKeyDown={true}
          >
            <div className="cx-dialog-title">
              {this.state.loadingCXProjects ? (
                ''
              ) : (
                <div>
                  Your Agents
                  <div className="cx-dialog-subtitle">
                    Select a CX agent to connect
                  </div>
                  <Tooltip
                    label="The region you selected will need to match the region you set when creating your agent in Dialogflow"
                    placement="top"
                    padding={5}
                  >
                    <Text fontSize="14px" fontWeight="bold">
                      Not seeing your bot?
                    </Text>
                  </Tooltip>
                  <Input
                    mt={5}
                    type="text"
                    value={this.state.searchValue}
                    onChange={this.handleCxAgentSearch}
                    placeholder="Search agents..."
                  />
                </div>
              )}
            </div>
            <DialogContent>
              {map(
                this.state.searchValue
                  ? this.state.filteredCxAgents
                  : this.state.cxAgents,
                (agent: any, index: number) => {
                  return (
                    <div key={index} className="project-tile">
                      <div className="project-id">{agent.displayName}</div>
                      <Button onClick={() => this.setCXAgent(agent)}>
                        Select
                      </Button>
                    </div>
                  )
                },
              )}
              {this.state.cxAgents.length === 0 ? (
                <div className="no-cx-agents-found">No agents found...</div>
              ) : null}
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  this.setState({
                    selectCXAgentDialog: false,
                    selectCXProjectDialog: true,
                  })
                }}
                variant="light"
                mr="12px"
              >
                Back to projects
              </Button>
            </DialogActions>
          </Dialog>

          {/* SNACKBARS */}
          <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            open={this.state.successSnackbar}
            autoHideDuration={2000}
            onClose={this._closeSnackbar}
          >
            <SnackbarContent
              style={divStyle.snackbar}
              message={
                <Grid style={divStyle.snackbarGrid}>
                  <CheckCircle size="24px" />
                  <Text ml="8px">{this.state.snackbarMessage}</Text>
                  {this.state.snackbarLink && this.state.snackbarLinkLabel && (
                    <Link
                      href={this.state.snackbarLink}
                      isExternal={true}
                      p={0}
                    >
                      {this.state.snackbarLinkLabel}
                    </Link>
                  )}
                </Grid>
              }
            />
          </Snackbar>
          <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            open={this.state.errorSnackbar}
            autoHideDuration={10000}
            onClose={this._closeSnackbar}
          >
            <SnackbarContent
              style={divStyle.errorSnackbar}
              message={
                <Flex direction={'column'} alignItems={'flex-start'}>
                  <Text ml="8px">{this.state.snackbarMessage}</Text>
                  {this.state.snackbarLink && this.state.snackbarLinkLabel && (
                    <Link
                      ml="8px"
                      display="block"
                      textDecoration={'underline'}
                      color="white"
                      href={this.state.snackbarLink}
                      isExternal={true}
                      p={0}
                    >
                      {this.state.snackbarLinkLabel}
                    </Link>
                  )}
                </Flex>
              }
            />
          </Snackbar>
          <SaveDetector formElements={this.formElements} />
        </div>
      )
    } else {
      return (
        <div style={{ padding: 50, color: colors.darkGreyBlue }}>
          Loading...
        </div>
      )
    }
  }

  private _renderDFEnvironmentInput = (bot: IBot) => {
    // const { classes } = this.props
    const { platform } = bot

    return (
      <FormControl my={3}>
        <FormLabel as={Text} textStyle="overline" casing="uppercase">
          Dialogflow Environment (Optional)
        </FormLabel>
        <InputGroup>
          <InputLeftElement color={colors.lightGreyScale1100} m="4px 0 0 5px">
            <InputAdornment position="start">
              <Tooltip
                label={
                  platform === BotPlatform.DIALOGFLOW_ES
                    ? tooltipText.dialogflowESEnvironment
                    : tooltipText.dialogflowCXEnvironment
                }
                placement={'top'}
                padding={5}
              >
                <a
                  href="https://docs.botcopy.com/#/basics/connect?id=environments"
                  target="_blank"
                >
                  <LanguageIcon size="24px" />
                </a>
              </Tooltip>
            </InputAdornment>
          </InputLeftElement>
          <Input
            placeholder="default"
            defaultValue={bot.dialogflow.environment}
            variant="outline"
            ref={this.formElements.dialogflowEnvironment.inputRef}
            onKeyPress={this.formElements.dialogflowEnvironment.onKeyPress}
            onChange={this.formElements.dialogflowEnvironment.onChange}
            size="lg"
            w="100%"
            color={colors.lightGreyScale1200}
            border="1px solid"
            borderColor={colors.lightGreyScale800}
            // mb={6}
          />
        </InputGroup>
      </FormControl>
    )
  }

  private _renderSelectAgentFormControl = (bot: IBot) => {
    const me = this.props.store?.users.me
    const org = this.props.store?.organizations.current
    if (!org || !me) return
    const { selectedIndex, agents, agentAccess } = this.state
    const hasDefaultOrNoProjectId = getHasDefaultOrNoProjectId(bot)
    const { platform } = bot

    if (platform === BotPlatform.DIALOGFLOW_ES) {
      return (
        <Box w="100%">
          <FormControl my={3}>
            {hasDefaultOrNoProjectId || isUndefined(selectedIndex) ? (
              <FormLabel textStyle="overline" htmlFor="projectId-required">
                Select Dialogflow ProjectId
              </FormLabel>
            ) : (
              <FormLabel textStyle="overline" htmlFor="projectId-required">
                Current Agent
              </FormLabel>
            )}
            <Select
              name="projectId"
              id="projectId-required"
              onChange={this._selectProject}
              fullWidth={true}
              disabled={
                me.roles.includes(Roles.MARKETER) || hasDefaultOrNoProjectId
                  ? false
                  : !agentAccess
              }
              placeholder={bot.projectId}
              value={
                hasDefaultOrNoProjectId
                  ? 'Please select an agent...'
                  : agentAccess
                    ? selectedIndex
                    : bot.projectId
              }
            >
              {map(agents, (result: any, index) => {
                return (
                  <MenuItem
                    key={`df-agent-${index}`}
                    value={index}
                    disabled={!result.access}
                  >
                    {result.access
                      ? result.agent.displayName
                      : `${result.agent.displayName} (missing owner role)`}
                  </MenuItem>
                )
              })}
            </Select>
            {this._renderSelectAgentFormHelper(bot)}
          </FormControl>
          {hasDefaultOrNoProjectId ? null : this._renderDFEnvironmentInput(bot)}
        </Box>
      )
    }

    if (platform === BotPlatform.DIALOGFLOW_CX) {
      return (
        <Grid container={true} justify="flex-end">
          {hasDefaultOrNoProjectId ? null : (
            <Grid container={true}>
              <TextField
                variant="filled"
                defaultValue={bot.cxAgent ? bot.cxAgent.name.split('/')[1] : ''}
                label="Project ID"
                fullWidth={true}
                placeholder="Select an agent"
                disabled={true}
                margin="dense"
                InputLabelProps={{
                  style: {
                    color: colors.darkGreyBlue,
                    fontSize: '1.1rem',
                  },
                  shrink: true,
                }}
              />
              <TextField
                variant="filled"
                defaultValue={bot.cxAgent ? bot.cxAgent.displayName : ''}
                label="Display Name"
                fullWidth={true}
                placeholder="Select an agent"
                disabled={true}
                margin="dense"
                InputLabelProps={{
                  style: {
                    color: colors.darkGreyBlue,
                    fontSize: '1.1rem',
                  },
                  shrink: true,
                }}
              />
              {this._renderDFEnvironmentInput(bot)}
            </Grid>
          )}
          <Button onClick={this.getActiveCXProjects}>
            {bot.cxAgent ? 'Change CX Agent' : 'Select CX Agent'}
          </Button>
        </Grid>
      )
    }

    return
  }

  private _renderSelectAgentFormHelper = (bot: IBot) => {
    const { selectedIndex, agentAccess, loadingAgents, savingAgent } =
      this.state
    const hasDefaultOrNoProjectId = getHasDefaultOrNoProjectId(bot)
    const noAgentAccess = `You need to have an owner role on ${
      bot.projectId ? `the ${bot.projectId}` : 'this'
    } project in Google Cloud Platform IAM and at least a developer role in Botcopy to change the connected agent`

    if (savingAgent) {
      return (
        <FormHelperText
          style={{
            color: colors.orange,
            height: 18,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <CircularProgress color={'inherit'} size={10} />
          &nbsp; Saving...
        </FormHelperText>
      )
    }
    if (hasDefaultOrNoProjectId) {
      return <FormHelperText style={{ color: 'red' }}>*Required</FormHelperText>
    }
    if (!loadingAgents && agentAccess) {
      return isUndefined(selectedIndex) ? (
        <FormHelperText
          style={{
            color: colors.orange,
            height: 18,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          Please select a new Agent
        </FormHelperText>
      ) : (
        <Text color={colors.green} textStyle="overline">
          YOUR BOT IS LINKED TO DIALOGFLOW
        </Text>
      )
    }

    if (!loadingAgents && !agentAccess) {
      return (
        <FormHelperText
          style={{
            display: 'flex',
            flexDirection: 'column' as 'column',
            alignItems: 'center',
          }}
        >
          <Text color={colors.green} textStyle="overline" mb="15px">
            THIS BOT IS LINKED TO DIALOGFLOW
          </Text>
          <a href="https://cloud.google.com/" target="_blank">
            <Tooltip label={noAgentAccess} placement={'top'} padding={5}>
              <div
                style={{
                  color: colors.orange,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                Why can't I click the dropdown?
              </div>
            </Tooltip>
          </a>
        </FormHelperText>
      )
    }
    return (
      <FormHelperText
        style={{
          color: colors.orange,
          height: 18,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <CircularProgress color={'inherit'} size={10} />
        &nbsp; Loading...
      </FormHelperText>
    )
  }

  private snippetTab1Clicked = () => {
    this.setState({
      embedSnippedTabActive: {
        general: true,
        gtm: false,
      },
    })
  }

  private snippetTab2Clicked = () => {
    this.setState({
      embedSnippedTabActive: {
        general: false,
        gtm: true,
      },
    })
  }

  private _copySnippet = () => {
    const org = this.props.store?.organizations.current

    // if free trial is done don't allow to copy snippet and redirect to account page to show plans
    if (
      org &&
      org.notifications.overageFreeGrace >= 5 &&
      org.balancesEngagementsTotal < org.plan.conversationLimit
    ) {
      this._handleSnackbar(
        'error',
        'Please update your billing information. Redirecting to account page...',
      )
      // redirect to account page
      setTimeout(() => {
        this.props.navigate('/account?showPlans=true')
      }, 2000)
      return
    }

    const copyText: any = document.getElementById('textarea-snippet')
    if (copyText) {
      copyText.select()
    }
    document.execCommand('copy')
    this._handleSnackbar('success', 'Copied snippet to clipboard.')
  }

  private _confirmDelete = () => {
    const me = this.props.store?.users.me

    if (me && !me.roles.includes(Roles.MARKETER)) {
      this.setState({ confirmDelete: true })
    }
  }

  private _confirmDisconnect = () => {
    const me = this.props.store?.users.me

    if (me && !me.roles.includes(Roles.MARKETER)) {
      this.setState({ disconnectBotDialog: true })
    }
  }

  private _closeDialog = () => {
    this.setState({
      confirmDelete: false,
      checkPermissionsDialog: false,
      disconnectBotDialog: false,
      installedOnRestrictedDialog: false,
      deletedDFAgentDialog: false,
      domainToDelete: '',
      urlToDelete: '',
      deleteDomainDialog: false,
      deleteURLExceptionDialog: false,
      maxKeysDialog: false,
      noAgentsDialog: false,
      selectCXProjectDialog: false,
      selectCXAgentDialog: false,
    })
  }

  private _handleSnackbar = async (type: string, snackbarMessage: string) => {
    await this.setState({ snackbarMessage })
    switch (type) {
      case 'success':
        this.setState({ successSnackbar: true })
        break

      case 'error':
        this.setState({ errorSnackbar: true })
        break
    }
  }

  private _deleteBot = async () => {
    const bots = this.props.store?.bots
    const currentBot = this.props.store?.bots.currentBot
    if (currentBot) {
      await bots.deleteBot(currentBot._id)
    }

    this.setState({ confirmDelete: false })
    this.props.navigate('/')
  }

  private _closeSnackbar = () => {
    if (this.state.successSnackbar) {
      this.setState({ successSnackbar: false })
    }
    if (this.state.errorSnackbar) {
      this.setState({ errorSnackbar: false })
    }
    this.setState({
      snackbarMessage: '',
      snackbarLink: '',
      snackbarLinkLabel: '',
    })
  }

  private async _fetchAgents(currentBot: IBot) {
    // don't fetch agents for DialogflowCX bots - the dialog handles it
    if (currentBot.platform === BotPlatform.DIALOGFLOW_CX) {
      return true
    }
    this.setState({ loadingAgents: true })
    const { store } = this.props
    const me = store?.users.me
    const projectId = currentBot.projectId || ''
    const isMarketer = me!.roles.includes(Roles.MARKETER)
    const agents: IFetchAgentsResult[] = await getAgents(
      1,
      200,
      '_id:-1',
      currentBot.dialogflow.location,
    )
    log('_fetchAgents AGENTS >>>>>', agents)
    if (!agents.length) {
      return this.setState({
        loadingAgents: false,
        agents,
        noAgentsDialog: true,
      })
    }

    // sort by access boolean
    agents.sort((a, b) => Number(a.access) - Number(b.access)).reverse()

    let selectedIndex: any
    each(agents, (result, index) => {
      log(
        'each agent',
        result.agent.parent.toLowerCase().replace('projects/', ''),
        projectId,
      )
      // if name of agent is equal to project id and not a marketer
      if (
        result.agent.parent
          .toLowerCase()
          .replace('projects/', '')
          .split('/')[0] === projectId &&
        !isMarketer
      ) {
        selectedIndex = index
        // if the project id is matched with an agent in the agent list, then give agent access
        this.setState({ agentAccess: true })
        return true
      }
      return true
    })
    log('_fetchAgents selectedIndex', selectedIndex)
    log('projectid>>>', projectId)
    this.setState((prevState) => ({
      ...prevState,
      agents,
      selectedIndex,
      loadingAgents: false,
    }))
  }

  private async _selectProject(event: any) {
    const newSelectedIndex = event.target.value
    const org = this.props.store?.organizations.current
    if (!org) return
    const { agents, selectedIndex: oldSelectedIndex, agentAccess } = this.state
    log('select project', newSelectedIndex, event.target.name)
    this.setState({ selectedIndex: newSelectedIndex, savingAgent: true })
    const selectedAgent = agents?.[newSelectedIndex]
    if (selectedAgent && selectedAgent.access) {
      log('selected agent', selectedAgent.agent)
      const currentBot = this.props.store?.bots.currentBot

      if (currentBot) {
        try {
          await currentBot.patchAgent(selectedAgent.agent)
          await currentBot.setOldBot()
          this.setState({ agentAccess: true })
          const dataLayer = {
            event: Events.botCreation.connect.setESProject.success.type,
            eventName:
              Events.botCreation.connect.setESProject.success.eventName,
            eventCode:
              Events.botCreation.connect.setESProject.success.eventCode,
          }
          trackUserEvent(EventName.PortalAction, dataLayer)
        } catch (e: any) {
          const dataLayer = {
            event: Events.botCreation.connect.setESProject.failure.type,
            eventName:
              Events.botCreation.connect.setESProject.failure.eventName,
            eventCode:
              Events.botCreation.connect.setESProject.failure.eventCode,
          }
          trackUserEvent(EventName.PortalAction, dataLayer)
          if (!e.response) {
            logError('_selectProject: Unknown error, no response', e)
            this.setState({
              maxKeysDialog: true,
              errorAgent: e.message,
              savingAgent: false,
              selectedIndex: oldSelectedIndex,
            })
            return
          }
          logError('_selectProject', e.response.data.message)
          // most below are deprecated now that we use our own service account and check permissions
          if (
            !agentAccess ||
            e.response.data.message.includes(
              'No credentials after ensuring service account',
            )
          ) {
            this.setState({
              checkPermissionsDialog: true,
              errorAgent: selectedAgent.agent.parent,
              selectedIndex: oldSelectedIndex,
            })
          }
          if (e.response.data.message.includes('Check Permissions')) {
            this.setState({
              checkPermissionsDialog: true,
              errorAgent: e.response.data.message.split(':')[0],
              selectedIndex: oldSelectedIndex,
            })
          } else if (e.response.data.message.includes('Max Keys')) {
            this.setState({
              maxKeysDialog: true,
              errorAgent: e.response.data.message.split(':')[0],
              selectedIndex: oldSelectedIndex,
            })
          }
        }
      }
    }
    this.setState({ savingAgent: false })
  }

  private _onFileChange(event: any) {
    event.persist()
    const reader = new FileReader()
    reader.onload = this._onReaderLoad
    reader.readAsText(event.target.files[0])
  }

  private _onReaderLoad(event: any) {
    log(event.target.result)
    try {
      const obj = JSON.parse(event.target.result)
      log('onReaderLoad parse json', obj)
      this.setState({ credentials: obj })
    } catch (error) {
      log('error', error)
    }
  }

  private _setServiceEmail = async (event: any) => {
    const value = event.target.value as string
    const currentBot = this.props.store?.bots.currentBot
    if (currentBot) {
      try {
        await currentBot.setServiceEmail(value)
      } catch (e) {
        logError(e)
        this._handleSnackbar('error', 'Please try again.')
        return false
      }
    }
    return true
  }

  private _setProjectId(event: any) {
    const value = event.target.value as string
    const currentBot = this.props.store?.bots.currentBot
    if (currentBot) {
      currentBot.setProjectId(value)
    }
  }

  private _changeName = async (event: any) => {
    const value = event.target.value as string
    const currentBot = this.props.store?.bots.currentBot
    if (currentBot && currentBot.name !== value) {
      try {
        await currentBot.changeName(value)
        this._handleSnackbar('success', `Successfully changed your bot's name.`)
      } catch (e) {
        logError(e)
        this._handleSnackbar('error', 'Please try again.')
        return false
      }
    }
    return true
  }

  private _changeDashboardLabel = async (event: any) => {
    const value = event.target.value
    const currentBot = this.props.store?.bots.currentBot
    if (currentBot && currentBot.dashboardLabel !== value) {
      try {
        await currentBot.changeDashboardLabel(value)
        this._handleSnackbar(
          'success',
          `Successfully changed your bot's label.`,
        )
      } catch (e) {
        logError(e)
        this._handleSnackbar('error', 'Please try again.')
        return false
      }
    }
    return true
  }

  private _changeDialogflowEnvironment = async (event: any) => {
    const value = event.target.value
    const currentBot = this.props.store?.bots.currentBot
    if (currentBot && currentBot.dialogflow.environment !== value) {
      try {
        currentBot.dialogflow.changeEnvironment(value)
        await currentBot.patchDialogflow()
        this._handleSnackbar(
          'success',
          `Successfully changed your bot's environment.`,
        )
        const dataLayer = {
          event: Events.botCreation.connect.environment.success.type,
          eventName: Events.botCreation.connect.environment.success.eventName,
          eventCode: Events.botCreation.connect.environment.success.eventCode,
        }
        trackUserEvent(EventName.PortalAction, dataLayer)
      } catch (e) {
        logError(e)
        this._handleSnackbar('error', 'Please try again.')
        const dataLayer = {
          event: Events.botCreation.connect.environment.failure.type,
          eventName: Events.botCreation.connect.environment.failure.eventName,
          eventCode: Events.botCreation.connect.environment.failure.eventCode,
        }
        trackUserEvent(EventName.PortalAction, dataLayer)
        return false
      }
    }
    return true
  }

  private _handleLanguageSelection = async (
    event: any,
    changedLanguage: ILanguageObject,
  ) => {
    const currentBot = this.props.store?.bots.currentBot
    if (!currentBot) return
    const { languageSelection } = currentBot
    const dataLayer = {
      event: Events.botCreation.connect.language.add.success.type,
      eventName: Events.botCreation.connect.language.add.success.eventName,
      eventCode: Events.botCreation.connect.language.add.success.eventCode,
    }
    trackUserEvent(EventName.PortalAction, dataLayer)
    // if selecting a new language, push it and make it default if there are no other languages
    // else remove it
    if (event.target.checked) {
      languageSelection.pushNewLanguage({
        ...changedLanguage,
        default: !languageSelection.languages.length,
      })
    } else {
      languageSelection.removeLanguage(changedLanguage)
    }
  }

  private _handleDefaultLanguage = async (
    event: any,
    changedLanguage: ILanguageObject,
  ) => {
    const currentBot = this.props.store?.bots.currentBot
    if (!currentBot) return
    const { languageSelection } = currentBot
    // isDefault = false when deselecting, true when selecting
    const isDefault = event.target.checked
    languageSelection.changeDefault(changedLanguage, isDefault)
  }

  private _submitLanguageSelection = async () => {
    const currentBot = this.props.store?.bots.currentBot
    if (!currentBot) return
    const { languageSelection } = currentBot

    // check for at least one language
    if (!languageSelection.languages.length) {
      return this._handleSnackbar(
        'error',
        'Please select at least one language.',
      )
    }

    // check for a default language
    if (!languageSelection.languages.filter((e: any) => e.default).length) {
      return this._handleSnackbar('error', 'Please select a default language.')
    }

    try {
      // toggle languageSelection.active and patch
      languageSelection.toggle(true)
      await currentBot.patchLanguageSelection()
      // Send events to mixpanel and GTM
      const dataLayer = {
        event: Events.botCreation.connect.language.add.success.type,
        eventName: Events.botCreation.connect.language.add.success.eventName,
        eventCode: Events.botCreation.connect.language.add.success.eventCode,
        languages: Events.botCreation.connect.language.add.success.languages,
      }
      trackUserEvent(EventName.PortalAction, dataLayer)
    } catch (e) {
      const dataLayer = {
        event: Events.botCreation.connect.language.add.failure.type,
        eventName: Events.botCreation.connect.language.add.failure.eventName,
        eventCode: Events.botCreation.connect.language.add.failure.eventCode,
        languages: Events.botCreation.connect.language.add.failure.languages,
      }
      trackUserEvent(EventName.PortalAction, dataLayer)
      return this._handleSnackbar(
        'error',
        'There was an error saving your languages. Please try again.',
      )
    }
    this.setState({ languageSelectionDialog: false })
  }

  /**
   * Opens the language selection dialog
   */
  private _enableLanguageSelection = async () => {
    const currentBot = this.props.store?.bots.currentBot
    if (!currentBot) return
    this.setState({
      languageSelectionDialog: true,
    })
    const dataLayer = {
      event: Events.botCreation.connect.language.add.view.type,
      eventName: Events.botCreation.connect.language.add.view.eventName,
      eventCode: Events.botCreation.connect.language.add.view.eventCode,
    }
    trackUserEvent(EventName.PortalAction, dataLayer)
  }

  private _disableLanguageSelection = async () => {
    const currentBot = this.props.store?.bots.currentBot
    if (!currentBot) return
    try {
      // clear languages, turn active = false, and patch
      currentBot.languageSelection.clearLanguages()
      currentBot.languageSelection.toggle(false)
      await currentBot.patchLanguageSelection()

      const dataLayer = {
        event: Events.botCreation.connect.language.remove.success.type,
        eventName: Events.botCreation.connect.language.remove.success.eventName,
        eventCode: Events.botCreation.connect.language.remove.success.eventCode,
        languages: Events.botCreation.connect.language.add.success.languages,
      }
      trackUserEvent(EventName.PortalAction, dataLayer)
    } catch (e) {
      this._handleSnackbar(
        'error',
        'There was an error disabling language selection. Please try again.',
      )
      const dataLayer = {
        event: Events.botCreation.connect.language.remove.failure.type,
        eventName: Events.botCreation.connect.language.remove.failure.eventName,
        eventCode: Events.botCreation.connect.language.remove.failure.eventCode,
        languages: Events.botCreation.connect.language.add.failure.languages,
      }
      trackUserEvent(EventName.PortalAction, dataLayer)
    }
  }

  private _editInstalledOn = async (domain: string, save: boolean) => {
    const currentBot = this.props.store?.bots.currentBot

    if (!currentBot) {
      return
    }

    if (!domain || domain.trim().length === 0) {
      return
    }

    // Check if domain already exists in allow list
    const previousDomains = currentBot.installedOn ?? []
    if (save) {
      const { hostname, protocol, port } = new URL(domain)
      domain = `${protocol}//${hostname}${port ? `:${port}` : ''}`
    }
    const domainExists = previousDomains.includes(domain)
    let newDomains: string[]

    try {
      if (save) {
        const isValid = installedOnDomainRegexp.test(domain)

        if (!isValid) {
          // Show error message if the domain is already in the allow list
          this._handleSnackbar('error', 'This domain is invalid.')
          return
        }
        if (domainExists) {
          // Show error message if the domain is already in the allow list
          this._handleSnackbar(
            'error',
            'This domain is already in your allow list.',
          )
          return
        }
        // Add domain to allow list
        newDomains = [...previousDomains, domain]
        await currentBot.patchInstalledOnRestricted(newDomains)
        this._handleSnackbar(
          'success',
          `Successfully added a domain to your allow list.`,
        )
        const dataLayer = {
          event: Events.botCreation.connect.installedOn.success.type,
          eventName: Events.botCreation.connect.installedOn.success.eventName,
          eventCode: Events.botCreation.connect.installedOn.success.eventCode,
          action: EventAction.Add,
        }
        trackUserEvent(EventName.PortalAction, dataLayer)
        return true
      }

      if (!save) {
        newDomains = previousDomains.filter((d: any) => d !== domain)
        await currentBot.patchInstalledOnRestricted(newDomains)
        this._closeDialog()
        this._handleSnackbar(
          'success',
          `Successfully removed a domain from your allow list.`,
        )
        const dataLayer = {
          event: Events.botCreation.connect.installedOn.success.type,
          eventName: Events.botCreation.connect.installedOn.success.eventName,
          eventCode: Events.botCreation.connect.installedOn.success.eventCode,
          action: EventAction.Remove,
        }
        trackUserEvent(EventName.PortalAction, dataLayer)
      }
    } catch (error) {
      this._handleSnackbar('error', 'Please try again.')
      const dataLayer = {
        event: Events.botCreation.connect.installedOn.failure.type,
        eventName: Events.botCreation.connect.installedOn.failure.eventName,
        eventCode: Events.botCreation.connect.installedOn.failure.eventCode,
        action: save ? EventAction.Add : EventAction.Remove,
      }
      trackUserEvent(EventName.PortalAction, dataLayer)
      return false
    }

    return true
  }

  private _editInstalledOnExceptions = async (url: string, save: boolean) => {
    const currentBot = this.props.store?.bots.currentBot

    if (!currentBot) {
      return
    }

    if (!url || url.trim().length === 0) {
      return
    }

    // Check if url already exists in exception list
    const previousURLs = currentBot.installedOnExceptions ?? []
    if (save) {
      const { hostname, protocol, pathname, port } = new URL(url)
      url = `${protocol}//${hostname}${port ? `:${port}` : ``}${pathname}`
      // console.log({ url })
    }
    const urlExists = previousURLs.includes(url)
    let newURLs: string[]

    try {
      if (save) {
        const isValid = installedOnExceptionURLRegexp.test(url)

        if (!isValid) {
          // Show error message if the domain is already in the allow list
          this._handleSnackbar('error', 'This url is invalid.')
          return
        }
        if (
          !currentBot.installedOn.some((domain: string) => url.includes(domain))
        ) {
          // Show error message if the URL is already in the allow list
          this._handleSnackbar(
            'error',
            'You can only make exceptions for domains that are part of your allowlist above.',
          )
          return
        }
        if (urlExists) {
          // Show error message if the URL is already in the allow list
          this._handleSnackbar(
            'error',
            'This URL is already in your allow list exceptions.',
          )
          return
        }
        // Add URL to exception list
        newURLs = [...previousURLs, url]
        await currentBot.patchInstalledOnExceptions(newURLs)
        this._handleSnackbar(
          'success',
          `Successfully added a URL to your allow list exceptions.`,
        )
        const dataLayer = {
          event: Events.botCreation.connect.installedOnExceptions.success.type,
          eventName:
            Events.botCreation.connect.installedOnExceptions.success.eventName,
          eventCode:
            Events.botCreation.connect.installedOnExceptions.success.eventCode,
          action: EventAction.Add,
        }
        trackUserEvent(EventName.PortalAction, dataLayer)
        return true
      }

      if (!save) {
        newURLs = previousURLs.filter((u: any) => u !== url)
        await currentBot.patchInstalledOnExceptions(newURLs)
        this._closeDialog()
        this._handleSnackbar(
          'success',
          `Successfully removed a URL from your allow list exceptions.`,
        )
        const dataLayer = {
          event: Events.botCreation.connect.installedOnExceptions.success.type,
          eventName:
            Events.botCreation.connect.installedOnExceptions.success.eventName,
          eventCode:
            Events.botCreation.connect.installedOnExceptions.success.eventCode,
          action: EventAction.Remove,
        }
        trackUserEvent(EventName.PortalAction, dataLayer)
      }
    } catch (error) {
      this._handleSnackbar('error', 'Please try again.')
      const dataLayer = {
        event: Events.botCreation.connect.installedOnExceptions.failure.type,
        eventName:
          Events.botCreation.connect.installedOnExceptions.failure.eventName,
        eventCode:
          Events.botCreation.connect.installedOnExceptions.failure.eventCode,
        action: save ? EventAction.Add : EventAction.Remove,
      }
      trackUserEvent(EventName.PortalAction, dataLayer)
      return false
    }
    return true
  }

  private _toggleActive = async () => {
    const currentBot = this.props.store?.bots.currentBot
    const org = this.props.store?.organizations.current
    if (!org || !currentBot) {
      return false
    }
    try {
      if (org.plan.onHold) {
        this._handleSnackbar(
          'error',
          'Your account is on hold. Please pay any outstanding balances on the account page, or sign up for a paid subscription.',
        )
      } else {
        const isActive = !currentBot.active
        await currentBot.toggleActive(isActive)
        const message = `Your bot is now ${isActive ? 'active' : 'inactive'}.`
        this._handleSnackbar('success', message)
      }
    } catch (error) {
      logError(error)
      this._handleSnackbar('error', 'Please try again.')
      return false
    }
    return true
  }

  private _toggleInstalledOnRestrictedDialog = () => {
    const { installedOnRestrictedDialog } = this.state
    this.setState({ installedOnRestrictedDialog: !installedOnRestrictedDialog })
  }

  private _toggleInstalledOnRestricted = async () => {
    const currentBot = this.props.store?.bots.currentBot
    const org = this.props.store?.organizations.current
    if (org && currentBot) {
      try {
        this._closeDialog()
        await currentBot.toggleInstalledOnRestricted(
          !currentBot.installedOnRestricted,
        )
        if (currentBot.installedOnRestricted) {
          this._handleSnackbar(
            'success',
            'Successfully enabled your allowlist.',
          )
        } else {
          this._handleSnackbar(
            'success',
            'Successfully disabled your allowlist.',
          )
        }
      } catch (e) {
        logError(e)
        this._handleSnackbar('error', 'Please try again.')
        return false
      }
    }
    return true
  }

  private getActiveCXProjects = async () => {
    await this.setState({
      selectCXProjectDialog: true,
      loadingCXProjects: true,
    })
    try {
      const activeProjects = []
      let pageToken: string | undefined
      do {
        const { projects, nextPageToken } = await getActiveProjects(pageToken)
        pageToken = nextPageToken

        activeProjects.push(...projects)
      } while (pageToken)

      activeProjects
        .sort((a: any, b: any) => Number(a.access) - Number(b.access))
        .reverse()
      await this.setState({
        activeProjects,
        loadingCXProjects: false,
      })
      const dataLayer = {
        event: Events.botCreation.connect.getCXProjects.success.type,
        eventName: Events.botCreation.connect.getCXProjects.success.eventName,
      }
      trackUserEvent(EventName.PortalAction, dataLayer)
    } catch (e) {
      logError('error getActiveCXProjects', e)
      await this.setState({
        selectCXProjectDialog: false,
        loadingCXProjects: false,
        errorSnackbar: true,
        snackbarMessage:
          'Sorry, there was an error fetching your CX projects. Please try again',
      })
    }
  }

  private getCXAgents = async (projectId: string, botId: string) => {
    try {
      const result = await getCXAgents({ projectId, botId })
      await this.setState({
        selectCXProjectDialog: false,
        cxAgents: result,
        selectCXAgentDialog: true,
      })
      const dataLayer = {
        event: Events.botCreation.connect.getCXAgents.success.type,
        eventName: Events.botCreation.connect.getCXAgents.success.eventName,
        eventCode: Events.botCreation.connect.getCXAgents.success.eventCode,
      }
      trackUserEvent(EventName.PortalAction, dataLayer)
    } catch (e: any) {
      this.setState({
        errorSnackbar: true,
        snackbarMessage:
          e?.response?.data?.message || 'Error while setting the Bot Agent',
      })
      const dataLayer = {
        event: Events.botCreation.connect.getCXAgents.failure.type,
        eventName: Events.botCreation.connect.getCXAgents.failure.eventName,
        eventCode: Events.botCreation.connect.getCXAgents.failure.eventCode,
      }
      trackUserEvent(EventName.PortalAction, dataLayer)
      logError('error getCXAgents', e)
      await this.setState({
        selectCXProjectDialog: false,
        selectCXAgentDialog: false,
        errorSnackbar: true,
        snackbarMessage:
          'Sorry, there was an error fetching your CX agents. Please try again',
      })
    }
  }

  private setCXAgent = async (cxAgent: any) => {
    const currentBot = this.props.store?.bots.currentBot
    const org = this.props.store?.organizations.current
    if (currentBot && org) {
      try {
        await currentBot.patchCXAgent(cxAgent)
        await currentBot.setOldBot()
        this.setState({
          agentAccess: true,
          selectCXAgentDialog: false,
        })
        const dataLayer = {
          event: Events.botCreation.connect.setCXAgent.success.type,
          eventName: Events.botCreation.connect.setCXAgent.success.eventName,
          eventCode: Events.botCreation.connect.setCXAgent.success.eventCode,
        }
        trackUserEvent(EventName.PortalAction, dataLayer)
      } catch (e: any) {
        this.setState({
          errorSnackbar: true,
          snackbarLinkLabel:
            'Enable Service Account Key Creation on a GCP Organization',
          snackbarLink:
            'https://www.loom.com/share/2488a1cfb7984dc88ac1a749cb36ed87?sid=a5ce1b5b-91ce-4326-a835-ad2ea3b8dea1',
          snackbarMessage: e?.response?.data?.message.includes(
            'Key creation is not allowed',
          )
            ? 'Key creation is not allowed on your GCP Organization make sure that you enable it following this video tutorial'
            : e?.response?.data?.message || 'Error while setting the Bot Agent',
        })
        logError('error setting CX agent', e)
        const dataLayer = {
          event: Events.botCreation.connect.setCXAgent.failure.type,
          eventName: Events.botCreation.connect.setCXAgent.failure.eventName,
          eventCode: Events.botCreation.connect.setCXAgent.failure.eventCode,
        }
        trackUserEvent(EventName.PortalAction, dataLayer)
      }
    }
  }

  private searchCXAgents = async (value: string) => {
    const { cxAgents } = this.state
    const filteredCxAgents =
      cxAgents?.filter((item: any) => {
        return item.displayName?.toLowerCase().includes(value.toLowerCase())
      }) || []
    this.setState({
      searchValue: value,
      filteredCxAgents,
    })
  }

  private handleCxAgentSearch = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = event.target.value

    this.searchCXAgents(value)
  }

  private searchCxProjects = async (value: string) => {
    const { activeProjects } = this.state
    const filteredCxProjects =
      activeProjects.filter((item: any) => {
        return item.project.name?.toLowerCase().includes(value.toLowerCase())
      }) || []
    this.setState({
      searchValue: value,
      filteredCxProjects,
    })
  }

  private handleCxProjectSearch = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = event.target.value

    this.searchCxProjects(value)
  }

  private _disconnectBot = async () => {
    const currentBot = this.props.store?.bots.currentBot
    if (currentBot) {
      try {
        await currentBot.disconnectBot()
        this.setState({ disconnectBotDialog: false, selectedIndex: undefined })
        const dataLayer = {
          event: Events.botCreation.connect.disconnectBot.success.type,
          eventName: Events.botCreation.connect.disconnectBot.success.eventName,
          eventCode: Events.botCreation.connect.disconnectBot.success.eventCode,
        }
        trackUserEvent(EventName.PortalAction, dataLayer)
        window.location.reload()
      } catch (e) {
        const dataLayer = {
          event: Events.botCreation.connect.disconnectBot.failure.type,
          eventName: Events.botCreation.connect.disconnectBot.failure.eventName,
          eventCode: Events.botCreation.connect.disconnectBot.failure.eventCode,
        }
        trackUserEvent(EventName.PortalAction, dataLayer)
        await this.setState({
          errorSnackbar: true,
          snackbarMessage:
            'Sorry, there was an error disconnecting your agent. Please try again',
        })
      }
    }
  }

  private _renderDataRegionSelect = (bot: IBot) => {
    return bot.platform === BotPlatform.DIALOGFLOW_ES ? (
      <div>
        <FormControl>
          <FormLabel textStyle="overline" htmlFor="data region">
            Data Region
          </FormLabel>
          <Select
            value={bot.dialogflow.location}
            onChange={(e) => {
              this._changeDataRegion(e.target.value, bot)
            }}
            fullWidth={true}
          >
            {map(esDataRegions, (region: IDataRegion, index: number) => {
              return (
                <MenuItem value={region.value} key={index}>
                  {region.title}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
      </div>
    ) : (
      <div>
        <FormControl>
          <FormLabel textStyle="overline" htmlFor="data region">
            Data Region
          </FormLabel>
          <Select
            value={bot.dialogflow.location}
            onChange={(e) => {
              this._changeDataRegion(e.target.value, bot)
            }}
            fullWidth={true}
          >
            {map(cxDataRegions, (region: IDataRegion, index: number) => {
              return (
                <MenuItem value={region.value} key={index}>
                  {region.title}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
      </div>
    )
  }

  private _changeDataRegion = async (location: string, bot: IBot) => {
    try {
      bot.dialogflow.changeLocation(location)
      await bot.patchDialogflow()
      this._handleSnackbar('success', 'Changed data region')
      if (!this.state.loadingAgents && this.props.store?.bots.currentBot) {
        await this._fetchAgents(this.props.store?.bots.currentBot)
      }
    } catch (e) {
      log(e)
      this._handleSnackbar('error', 'Please try again')
    }
  }
}

export default withRouter(withStyles(styles)(Connect))
